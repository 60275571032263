import {
  HealthcareOrganizationFieldGuid,
  SortDirection,
  SortInfo,
} from "../../types";
import { ColumnName } from "./Features/CustomTable/CustomTable.consts";
import { Option } from "../../components";

export const createSortInfo = (
  sortDirection: SortDirection,
  sortedColumnName: string | undefined,
): SortInfo | undefined => {
  if (typeof sortedColumnName === "undefined") return undefined;

  let fieldGuid = HealthcareOrganizationFieldGuid.Name;
  switch (sortedColumnName) {
    case ColumnName.OrganizationName:
      fieldGuid = HealthcareOrganizationFieldGuid.ShortName;
      break;
    case ColumnName.Status:
      fieldGuid = HealthcareOrganizationFieldGuid.Status;
      break;
    case ColumnName.OrganizationType:
      fieldGuid = HealthcareOrganizationFieldGuid.Type;
      break;
    case ColumnName.Actualization:
      fieldGuid = HealthcareOrganizationFieldGuid.Actualization;
      break;
  }
  return {
    sortDirection,
    fieldGuid,
  };
};

export const mapOptionListToLabelMap = (optionList: Option[]): string[] => {
  return optionList.map((opt) => opt.label);
};
