import {
  COATO_FIELD_ERROR_TEXT,
  EMAIL_ADDRESS_FIELD_ERROR_TEXT,
  FAX_FIELD_ERROR_TEXT,
  INTERNET_ADDRESS_FIELD_ERROR_TEXT,
  MAX_TEXT_FIELD_LENGTH,
  MAX_TEXT_FIELD_LENGTH_EXCEEDED_ERROR_TEXT,
  NECESSARY_FIELD_ERROR_TEXT,
  NUMBER_REGEX,
  OKPO_FIELD_ERROR_TEXT,
  ORGANIZATION_TYPE_ERROR_TEXT,
  OWNERSHIP_FORM_ERROR_TEXT,
  PHONE_FIELD_ERROR_TEXT,
  POSTCODE_FIELD_ERROR_TEXT,
  UNP_FIELD_ERROR_TEXT,
} from "../consts";
import { Option } from "../components";
import dayjs from "dayjs";

export const validateString = (value: string): boolean => {
  return value.length > 0 && value.length <= 500;
};

export const getStringFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (value.length === 0) {
      return NECESSARY_FIELD_ERROR_TEXT;
    }

    if (value.length > MAX_TEXT_FIELD_LENGTH) {
      return MAX_TEXT_FIELD_LENGTH_EXCEEDED_ERROR_TEXT;
    }
  });
};

export const getFieldErrorText = (
  isSaveButtonPressed: boolean,
  func: Function,
): string | undefined => {
  if (!isSaveButtonPressed) return undefined;

  return func();
};

// ОКПО
export const validateOKPOField = (value: string): boolean => {
  return value.length === 12 && NUMBER_REGEX.test(value);
};

export const getOKPOFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateOKPOField(value)) {
      return OKPO_FIELD_ERROR_TEXT;
    }
  });
};

export const validateSelectField = (value: Option | undefined): boolean => {
  return typeof value !== "undefined";
};

export const getSelectFieldErrorText = (
  value: Option | undefined,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateSelectField(value)) {
      return NECESSARY_FIELD_ERROR_TEXT;
    }
  });
};

export const validateDateField = (
  date: Date | undefined,
  isSaveButtonPressed: boolean,
  isNoInTheFuture?: boolean,
): boolean => {
  if (!isSaveButtonPressed) return true;

  if (date === null) return false;
  if (isNoInTheFuture) {
    return dayjs(new Date()).diff(date) >= 0;
  } else {
    return true;
  }
};

export const validatePostcodeField = (value: string): boolean => {
  return value.length === 6 && NUMBER_REGEX.test(value);
};

export const getPostcodeFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validatePostcodeField(value)) {
      return POSTCODE_FIELD_ERROR_TEXT;
    }
  });
};

export const validateCOATOCodeField = (value: string): boolean => {
  return value.length === 10 && NUMBER_REGEX.test(value);
};

export const getCOATOCodeFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateCOATOCodeField(value)) {
      return COATO_FIELD_ERROR_TEXT;
    }
  });
};

export const validateInternetAddressField = (value: string): boolean => {
  return value.length > 0 && !value.includes(" ") && value.includes(".");
};

export const getInternetAddressFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateInternetAddressField(value)) {
      return INTERNET_ADDRESS_FIELD_ERROR_TEXT;
    }
  });
};

export const validateEmailAddressField = (value: string): boolean => {
  if (!validateInternetAddressField(value) || !value.includes("@"))
    return false;

  const atFirstIndex = value.indexOf("@");
  const atLastIndex = value.lastIndexOf("@");
  if (atFirstIndex !== atLastIndex) {
    return false;
  }

  if (value.lastIndexOf(".") < atFirstIndex) {
    return false;
  }

  if (value.lastIndexOf(".") === value.length - 1) {
    return false;
  }

  return true;
};

export const getEmailAddressFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateEmailAddressField(value)) {
      return EMAIL_ADDRESS_FIELD_ERROR_TEXT;
    }
  });
};

export const validatePhoneField = (value: string): boolean => {
  return NUMBER_REGEX.test(value);
};

export const getPhoneFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validatePhoneField(value)) {
      return PHONE_FIELD_ERROR_TEXT;
    }
  });
};

export const validateFaxField = (value: string | undefined): boolean => {
  if (typeof value === "undefined" || value === "") return true;

  return NUMBER_REGEX.test(value);
};

export const getFaxFieldErrorText = (
  value: string | undefined,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateFaxField(value)) {
      return FAX_FIELD_ERROR_TEXT;
    }
  });
};

// УНП
export const validateUNPField = (value: string): boolean => {
  return value.length === 9 && NUMBER_REGEX.test(value);
};

export const getUNPFieldErrorText = (
  value: string,
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateUNPField(value)) {
      return UNP_FIELD_ERROR_TEXT;
    }
  });
};

export const validateOwnershipFormField = (value: Option[]): boolean => {
  return value.length === 1;
};

export const getOwnershipFormErrorText = (
  value: Option[],
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateOwnershipFormField(value)) {
      return OWNERSHIP_FORM_ERROR_TEXT;
    }
  });
};

export const validateOrganizationTypeField = (value: Option[]): boolean => {
  return value.length === 1;
};

export const getOrganizationTypeErrorText = (
  value: Option[],
  isSaveButtonPressed: boolean,
): string | undefined => {
  return getFieldErrorText(isSaveButtonPressed, () => {
    if (!validateOrganizationTypeField(value)) {
      return ORGANIZATION_TYPE_ERROR_TEXT;
    }
  });
};
