import { SortInfo } from "../types";
import { Filter } from "./get-dictionaries.types";

export const getSortObj = (sortInfo?: SortInfo) => {
  if (!sortInfo) return "";

  return {
    sort_field: sortInfo.fieldGuid,
    sort_direct: sortInfo.sortDirection,
  };
};

export const generateMultiFilter = (inpFilters: Filter[]): Filter[] => {
  const multiFilter: Filter[] = [];
  const filters = inpFilters.filter((arr) => arr.length > 0);

  let currentFilter: Filter = [];
  const generate = (index: number) => {
    if (index === filters.length) {
      multiFilter.push(currentFilter);
    } else {
      for (let i = 0; i < filters[index].length; i++) {
        currentFilter.push(filters[index][i]);
        generate(index + 1);
        currentFilter = [...currentFilter.slice(0, currentFilter.length - 1)];
      }
    }
  };

  generate(0);

  if (multiFilter.toString().length === 0) {
    return [];
  } else {
    return multiFilter;
  }
};

export const createCondition = (fieldName: string, nameList?: string[]) => {
  return (nameList ?? []).map((name) => ({
    field: fieldName,
    value: ` = '${name}'`,
  }));
};
