import { StatusType } from "../components";

export const getStatusNumberByType = (statusType: StatusType): number => {
  switch (statusType) {
    case StatusType.Active:
      return 0;
    case StatusType.Suspended:
      return 1;
    case StatusType.Closed:
      return 2;
    default:
      return 0;
  }
};

export const getStatusTypeByNumber = (status: number): StatusType => {
  switch (status) {
    case 0:
      return StatusType.Active;
    case 1:
      return StatusType.Suspended;
    case 2:
      return StatusType.Closed;
    default:
      return StatusType.Active;
  }
};

export const convertOptionalDateToSafetyDate = (
  value: string | null,
): Date | undefined => {
  return value ? new Date(value) : undefined;
};

export const convertOptionalStringToSafetyString = (
  value: string | null,
): string => {
  return value ?? "—";
};

export const convertArrayToSentence = (
  arr: (string | null | undefined)[],
): string => {
  return arr.filter(Boolean).join(", ");
};

export const join = (base: string, path: string) => {
  return base.charAt(base.length - 1) === "/" ? base + path : base + "/" + path;
};
