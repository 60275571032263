import styles from "./StructuralUnits.module.scss";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { SimpleTreeView } from "@mui/x-tree-view";
import classNames from "classnames";
import { Box, IconButton, Popper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHierarchy } from "./useHierarchy";
import Typography from "@mui/material/Typography";
import {
  ButtonSize,
  ButtonStyle,
  ButtonType,
  Loader,
  Button,
  SnackbarState,
  Snackbar,
} from "../../../../components";
import { DataNotLoadedStub, NothingFoundStub } from "../../../../features";
import {
  StructuralUnitsModal,
  useStructuralModal,
  ConfirmationDialog,
} from "./features";
import { useMemo, useState } from "react";
import { useRemoveStructuralUnit } from "./useRemoveStructuralUnit";
import { UserRole } from "../../../../consts";
import { collectIdsFromHierarcy } from "./StructuralUnits.helpers";
import { useParams } from "react-router-dom";

export const StructuralUnits = () => {
  const {
    isError,
    isLoading,
    treeViewItems,
    expandedItems,
    hierarchyItems,
    toggleExpand,
    isModalOpen,
    handleModalClose,
    handleModalOpen,
    handleUpdated,
    findItemById,
    setIsModalOpen,
    itemId,
    user,
  } = useHierarchy();
  const {
    dialogState,
    handleDialogClose,
    removeItem,
    handleDeleteByDialog,
    isRemoving,
    snackBar,
  } = useRemoveStructuralUnit({
    onRemoveHandler: handleUpdated,
  });

  const { organizationId } = useParams() as {
    organizationId: Readonly<string>;
  };

  const hierarcyItemsIds = useMemo(
    () => collectIdsFromHierarcy(treeViewItems),
    [treeViewItems],
  );

  const modalProps = useStructuralModal({
    item: isModalOpen,
    onUpdateHandler: handleUpdated,
    hierarcyIdList: [...hierarcyItemsIds, organizationId],
    initPartOfId: itemId,
    onClose: handleModalClose,
    onOpen: setIsModalOpen,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [activeItem, setActiveItem] = useState<string | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setActiveItem(id);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleRemoveClick = () => {
    const data = activeItem ? findItemById(activeItem, hierarchyItems) : null;
    if (data) {
      removeItem(data);
    }
  };

  const renderOptions = (
    items: TreeViewBaseItem[],
    level: number = 0,
  ): JSX.Element[] => {
    return items.flatMap((item) => {
      const hasChild = item.children && item.children.length > 0;
      const isExpanded = expandedItems.has(item.id);
      return [
        <div className={styles.itemContainer} key={item.id}>
          <div
            style={{ marginLeft: level < 3 ? level * 10 : level * 20 }}
            className={classNames(styles.itemWrapper, {
              [styles.itemWrapper_subLevel]: level > 0,
            })}
          >
            {level > 0 && (
              <span
                className={styles.line}
                style={{
                  width: level * 20 * 0.55,
                }}
              ></span>
            )}
            <div
              className={classNames(styles.item, {
                [styles.item_root]: level === 0,
              })}
              onClick={() => {
                if (anchorEl) {
                  setActiveItem(null);
                  setAnchorEl(null);
                } else {
                  handleModalOpen(item);
                }
              }}
            >
              <div className={styles.left}>
                {hasChild && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(item.id);
                    }}
                  >
                    {isExpanded ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandMoreIcon className={styles.lessIcon} />
                    )}
                  </IconButton>
                )}
                {item.label}
              </div>
              <div>
                {(user.role === UserRole.Admin ||
                  user.role === UserRole.Operator) && (
                  <IconButton
                    aria-describedby={id}
                    className={styles.addButton}
                    size="small"
                    onClick={(e) => handleClick(e, item.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </div>

              {anchorEl && (
                <Popper id={id} open={open} anchorEl={anchorEl}>
                  <Box
                    sx={{
                      border: 0,
                      mr: 12,
                      p: 1,
                      bgcolor: "background.paper",
                      borderRadius: 1,
                      boxShadow: "0 1px 2px 0 rgba(0, 0 , 0, 0.04)",
                    }}
                  >
                    <Button
                      onClick={() => {
                        activeItem
                          ? handleModalOpen(activeItem)
                          : handleModalOpen();
                      }}
                      size={ButtonSize.S}
                      style={ButtonStyle.Text}
                      type={ButtonType.Secondary}
                      text={"Добавить вложенную структуру"}
                      className={styles.popperButton}
                    />
                    <Button
                      onClick={handleRemoveClick}
                      size={ButtonSize.S}
                      style={ButtonStyle.Text}
                      type={ButtonType.Secondary}
                      text={"Удалить"}
                      className={styles.popperButton}
                    />
                  </Box>
                </Popper>
              )}
            </div>
          </div>

          {hasChild && isExpanded && (
            <div className={styles.children}>
              {renderOptions(item.children!, level + 1)}
            </div>
          )}
        </div>,
      ];
    });
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.uiWrapper}>
          <Loader height={48} width={48} />
        </div>
      );
    }
    if (isError) {
      return (
        <div className={styles.uiWrapper}>
          <DataNotLoadedStub />
        </div>
      );
    }
    if (!isLoading && !isError && treeViewItems.length === 0) {
      return (
        <div className={styles.uiWrapper}>
          <Typography variant="subtitle1">
            <NothingFoundStub isWithAdvice={false} />
          </Typography>
        </div>
      );
    }

    return <SimpleTreeView>{renderOptions(treeViewItems)}</SimpleTreeView>;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Структурные подразделения
        {(user.role === UserRole.Admin || user.role === UserRole.Operator) && (
          <Button
            onClick={() => {
              organizationId
                ? handleModalOpen(organizationId)
                : handleModalOpen();
            }}
            type={ButtonType.Primary}
            size={ButtonSize.M}
            style={ButtonStyle.Contained}
            text={"Добавить"}
          />
        )}
      </div>
      {renderContent()}
      <StructuralUnitsModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        {...modalProps}
      />
      {isRemoving && (
        <div className={styles.uiWrapper_remove}>
          <Loader width={48} height={48} />
        </div>
      )}
      <ConfirmationDialog
        open={dialogState}
        onClose={handleDialogClose}
        onRemoveConfirmClick={handleDeleteByDialog}
        onRemoveRejectClick={handleDialogClose}
      />
      {snackBar.open && (
        <Snackbar
          text={
            snackBar.state === SnackbarState.Success
              ? "Подразделение успешно удалено"
              : "Подразделение не было удалено. Возникла ошибка при удалении."
          }
          state={snackBar.state}
        />
      )}
    </div>
  );
};
