export enum FieldURI {
  Grid = "1.2.643.2.69.1.1.1.64",
  ParentOrganization = "VSHigherLevelOrganization",
  HealthcareStructure = "VSOrganizationStructure",
  OwnershipType = "VSOwnershipCodesOKFS0022019",
  SubordinationLevel = "ROZ.1",
  MedicalCareType = "VSTypeOfHealthCare",
  MedicalCareLevel = "VSHealthCareLevel",
  MedicalCareConditions = "VSTermsOfService",
  TypeOfStructuralUnit = "ROZ.2",
  OrganizationType = "VSTypeOfOrganization",
}

export const MAXIMUM_PAGE_COUNT = 10000;

export const AUTHORIZATION_STORAGE_KEY_PREFIX = "term_";

export const GRID_URL = `${process.env.REACT_APP_API_URL}/ui/data/grid`;
export const ITEM_URL = `${process.env.REACT_APP_API_URL}/ui/item`;
