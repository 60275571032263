import { AUTHORIZATION_STORAGE_KEY_PREFIX, UserRole } from "./consts";
import { jwtDecode } from "jwt-decode";

export const getToken = (): string => {
  return localStorage.getItem(`${AUTHORIZATION_STORAGE_KEY_PREFIX}token`) ?? "";
};

export const getUserInfo = () => {
  return JSON.parse(
    localStorage.getItem(`${AUTHORIZATION_STORAGE_KEY_PREFIX}userinfo`) ?? "",
  );
};

export const logout = (): void => {
  localStorage.clear();
  window.location.reload();
};

export const isTokenEmpty = (token: string): boolean => {
  return token.length <= 2;
};

export const getUserRole = (): UserRole | undefined => {
  const token = getToken();
  if (isTokenEmpty(token)) return;

  const decoded: any = jwtDecode(token);
  for (let i = 0; i < 4; i++) {
    const roleStr: string = decoded.realm_access?.roles?.[i];
    switch (roleStr) {
      case UserRole.Admin.toString():
        return UserRole.Admin;
      case UserRole.Moderator.toString():
        return UserRole.Moderator;
      case UserRole.Operator.toString():
        return UserRole.Operator;
      case UserRole.Consumer.toString():
        return UserRole.Consumer;
    }
  }
};
