import { Option } from "./TreeSelect.types";

function isStringArray(value: any): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}

function isOptionArray(value: any): value is Option[] {
  return (
    Array.isArray(value) &&
    value.every((item) => {
      return (
        typeof item === "object" &&
        item !== null &&
        "id" in item &&
        "label" in item
      );
    })
  );
}

export { isStringArray, isOptionArray };
