import { FieldURI, GRID_URL, MAXIMUM_PAGE_COUNT } from "../consts";
import { Option } from "../components";
import {
  BranchListListFilter,
  HealthcareOrganizationFieldGuid,
  HealthcareOrganizationListFilter,
  Regime,
  SortDirection,
  Tag,
} from "../types";
import { Row } from "../pages/HealthcareOrganizationsRegister/Features/CustomTable/CustomTable.types";
import { Filter, GetDictionaryResponse } from "./get-dictionaries.types";
import {
  convertOptionalDateToSafetyDate,
  convertOptionalStringToSafetyString,
  fetchWrapper,
  getStatusTypeByNumber,
} from "../utils";
import {
  createCondition,
  generateMultiFilter,
  getSortObj,
} from "./get-dictionaries.utils";
import { Branch } from "../pages/OrganizationCard/OrganizationCard.types";
import { Organization } from "../pages/OrganizationCard/Features/StructuralUnits/StructuralUnits.types";
import { OptionWithParent } from "../components/Select/Select.types";

export const getFieldIdDictionaryByURI = async (
  uri: FieldURI,
): Promise<number | undefined> => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/service/get_dictionary_id?oid=${uri}`,
    "GET",
  );
  return response?.data;
};

export const getFilterOptionList = async (
  idDictionary: number,
  isOrganizationList?: boolean,
  userOrganizationId?: string,
): Promise<Option[]> => {
  const multiFilter = generateMultiFilter([
    isOrganizationList
      ? [
          {
            field: HealthcareOrganizationFieldGuid.HierarchyLevel,
            value: " = '1'",
          },
        ]
      : [],
    isOrganizationList
      ? [
          {
            field: HealthcareOrganizationFieldGuid.ParentOrg,
            value: " is null",
          },
        ]
      : [],
    userOrganizationId
      ? [
          {
            field: HealthcareOrganizationFieldGuid.Code,
            value: ` = '${userOrganizationId}'`,
          },
        ]
      : [],
  ]);

  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.Name,
      ],
      regime: Regime.Data,
      count: MAXIMUM_PAGE_COUNT,
      page: 1,
      filter: [],
      sort_field: HealthcareOrganizationFieldGuid.Name,
      sort_direct: SortDirection.Asc,
      multi_filter: multiFilter,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") return [];

  const json: GetDictionaryResponse = response.data;
  return json.data.map((opt) => ({
    id: opt[1]!,
    label: opt[2]!,
  }));
};

export const getFilterOptionWithParentList = async (
  idDictionary: number,
): Promise<OptionWithParent[]> => {
  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.Name,
        HealthcareOrganizationFieldGuid.Parent,
      ],
      regime: Regime.Data,
      count: MAXIMUM_PAGE_COUNT,
      page: 1,
      filter: [],
      sort_field: HealthcareOrganizationFieldGuid.Name,
      sort_direct: SortDirection.Asc,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") return [];

  const json: GetDictionaryResponse = response.data;
  return json.data.map((opt) => ({
    id: opt[1]!,
    label: opt[2]!,
    parentId: opt[3],
  }));
};

export const getHealthcareOrganizationShortNameByCode = async (
  code: string,
  idDictionary: number,
): Promise<string> => {
  const response = await getHealthcareOrganizationByCode(code, idDictionary);
  return response.list[0].healthcareOrganizationContent.shortName;
};

export const getHealthcareOrganizationByCode = async (
  code: string,
  idDictionary: number,
) => {
  return await getHealthcareOrganizationList(
    {
      code,
      searchString: "",
      page: 0,
      count: 1,
      isShowWithBranches: true,
    },
    idDictionary,
  );
};

export const getHealthcareOrganizationList = async (
  filter: HealthcareOrganizationListFilter,
  idDictionary: number,
): Promise<{
  list: Row[];
  count: number;
}> => {
  const sortObj = getSortObj(filter.sortInfo);
  let conditionAboutBranches: Filter[];

  if (!filter.isShowWithBranches && typeof filter.parentOrg === "undefined") {
    conditionAboutBranches = [
      [
        {
          field: HealthcareOrganizationFieldGuid.HierarchyLevel,
          value: ` = '1'`,
        },
      ],
      [
        {
          field: HealthcareOrganizationFieldGuid.ParentOrg,
          value: ` is null`,
        },
      ],
      filter.userOrganizationId
        ? [
            {
              field: HealthcareOrganizationFieldGuid.Code,
              value: ` = '${filter.userOrganizationId}'`,
            },
          ]
        : [],
    ];
  } else if (
    filter.isShowWithBranches &&
    typeof filter.parentOrg === "undefined"
  ) {
    conditionAboutBranches = [
      [
        {
          field: HealthcareOrganizationFieldGuid.HierarchyLevel,
          value: ` = '1'`,
        },
      ],
      filter.userOrganizationId
        ? [
            {
              field: HealthcareOrganizationFieldGuid.Code,
              value: ` = '${filter.userOrganizationId}'`,
            },
            {
              field: HealthcareOrganizationFieldGuid.ParentOrg,
              value: ` = '${filter.userOrganizationId}'`,
            },
          ]
        : [],
    ];
  } else {
    conditionAboutBranches = [
      [
        {
          field: HealthcareOrganizationFieldGuid.HierarchyLevel,
          value: ` = '1'`,
        },
      ],
      [
        {
          field: HealthcareOrganizationFieldGuid.Code,
          value: ` = '${filter.parentOrg}'`,
        },
        {
          field: HealthcareOrganizationFieldGuid.ParentOrg,
          value: ` = '${filter.parentOrg}'`,
        },
      ],
    ];
  }

  const multiFilter = generateMultiFilter([
    filter.code
      ? [
          {
            field: HealthcareOrganizationFieldGuid.Code,
            value: ` = '${filter.code}'`,
          },
        ]
      : [],
    ...conditionAboutBranches,
    [
      {
        field: HealthcareOrganizationFieldGuid.Name,
        value: ` LIKE '%${filter.searchString}%'`,
      },
      {
        field: HealthcareOrganizationFieldGuid.ShortName,
        value: ` LIKE '%${filter.searchString}%'`,
      },
      {
        field: HealthcareOrganizationFieldGuid.UNP,
        value: ` LIKE '%${filter.searchString}%'`,
      },
    ],
    createCondition(
      HealthcareOrganizationFieldGuid.ManagingOrg,
      filter.parentOrganizationNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.HealthcareStructure,
      filter.healthcareStructureNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.OwnershipForm,
      filter.ownershipTypeNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.SubordinationLevel,
      filter.subordinationLevelNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.Type,
      filter.healthcareOrganizationTypeNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.MedicalCareLevel,
      filter.medicalCareLevelNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.MedicalCareConditions,
      filter.medicalCareConditionsNameList,
    ),
    createCondition(
      HealthcareOrganizationFieldGuid.MedicalCareType,
      filter.medicalCareTypeNameList,
    ),
  ]);

  const body = {
    data: JSON.stringify({
      ...sortObj,
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.Name,
        HealthcareOrganizationFieldGuid.ShortName,
        HealthcareOrganizationFieldGuid.PartOf,
        HealthcareOrganizationFieldGuid.HierarchyLevel,
        HealthcareOrganizationFieldGuid.OwnershipForm,
        HealthcareOrganizationFieldGuid.UNP,
        HealthcareOrganizationFieldGuid.HealthcareStructure,
        HealthcareOrganizationFieldGuid.Type,
        HealthcareOrganizationFieldGuid.OKPO,
        HealthcareOrganizationFieldGuid.ManagingOrg,
        HealthcareOrganizationFieldGuid.SubordinationLevel,
        HealthcareOrganizationFieldGuid.Status,
        HealthcareOrganizationFieldGuid.Actualization,
        HealthcareOrganizationFieldGuid.Phone,
        HealthcareOrganizationFieldGuid.Address_p1,
        HealthcareOrganizationFieldGuid.Address_p2,
        HealthcareOrganizationFieldGuid.StatusDate,
        HealthcareOrganizationFieldGuid.StatusReason,
        HealthcareOrganizationFieldGuid.StatusBasis,
        HealthcareOrganizationFieldGuid.MedicalCareLevel,
        HealthcareOrganizationFieldGuid.RegistrationDate,
        HealthcareOrganizationFieldGuid.RegisterRegistrationDate,
        HealthcareOrganizationFieldGuid.MedicalCareConditions,
        HealthcareOrganizationFieldGuid.MedicalCareType,
        HealthcareOrganizationFieldGuid.Postcode,
        HealthcareOrganizationFieldGuid.COATOCode,
        HealthcareOrganizationFieldGuid.InternetAddress,
        HealthcareOrganizationFieldGuid.EmailAddress,
        HealthcareOrganizationFieldGuid.Fax,
        HealthcareOrganizationFieldGuid.ParentOrg,
      ],
      regime: Regime.Data,
      count: filter.count,
      page: filter.page + 1,
      multi_filter: multiFilter,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") {
    return {
      list: [],
      count: 0,
    };
  }
  const json: GetDictionaryResponse = response.data;

  const branchCodeList: string[] = json.data
    .filter((item) => item[31] !== null)
    .map((item) => item[31]!);
  const organizationShortNameByCodeMap = filter.isShowWithBranches
    ? await getOrganizationShortNameByCodeMap(idDictionary, branchCodeList)
    : new Map<string, string>();

  return {
    list: json.data.map((item) => ({
      idDictionaryItem: item[0]!,
      id: item[1]!,
      isChecked: false,
      healthcareOrganizationContent: {
        name: convertOptionalStringToSafetyString(item[2]),
        shortName: convertOptionalStringToSafetyString(item[3]),
        UNP: convertOptionalStringToSafetyString(item[7]),
        phone: convertOptionalStringToSafetyString(item[15]),
        address: item[16] ? `${item[16]}, ${item[17]}` : "—",
        ownershipForm: convertOptionalStringToSafetyString(item[6]),
        healthcareStructure: convertOptionalStringToSafetyString(item[8]),
        okpo: convertOptionalStringToSafetyString(item[10]),
        parentOrganization: convertOptionalStringToSafetyString(item[11]),
        subordinationLevel: convertOptionalStringToSafetyString(item[12]),
        medicalCareLevel: convertOptionalStringToSafetyString(item[21]),
        registrationDate: convertOptionalDateToSafetyDate(item[22]),
        registerRegistrationDate: convertOptionalDateToSafetyDate(item[23]),
        medicalCareConditions: convertOptionalStringToSafetyString(item[24]),
        medicalCareType: convertOptionalStringToSafetyString(item[25]),
        postcode: convertOptionalStringToSafetyString(item[26]),
        COATOCode: convertOptionalStringToSafetyString(item[27]),
        addressP1: convertOptionalStringToSafetyString(item[16]),
        addressP2: convertOptionalStringToSafetyString(item[17]),
        internetAddress: convertOptionalStringToSafetyString(item[28]),
        emailAddress: convertOptionalStringToSafetyString(item[29]),
        fax: convertOptionalStringToSafetyString(item[30]),
        legalEntityShortName:
          filter.isShowWithBranches && item[31] !== null
            ? (organizationShortNameByCodeMap.get(item[31]!) ?? "")
            : "",
      },
      actualization: convertOptionalDateToSafetyDate(item[14]),
      organizationType: convertOptionalStringToSafetyString(item[9]),
      cardStatusContent: {
        status: getStatusTypeByNumber(+item[13]!),
        statusReason: convertOptionalStringToSafetyString(item[19]),
        statusDate: convertOptionalDateToSafetyDate(item[18]),
        statusBasis: convertOptionalStringToSafetyString(item[20]),
      },
      isOrganization: item[31] === null,
      parentId: item[31]!,
    })),
    count: json.count,
  };
};

export const getBranchesList = async (
  filter: BranchListListFilter,
  idDictionary: number,
): Promise<{
  branchList: Branch[];
  count: number;
}> => {
  const multiFilter = generateMultiFilter([
    [
      {
        field: HealthcareOrganizationFieldGuid.HierarchyLevel,
        value: ` = '1'`,
      },
    ],
    [
      {
        field: HealthcareOrganizationFieldGuid.ParentOrg,
        value: ` = '${filter.organizationId}'`,
      },
    ],
  ]);

  const sort = filter.sortInfo?.sortDirection
    ? {
        sort_field: filter.sortInfo.fieldGuid,
        sort_direct: filter.sortInfo.sortDirection,
      }
    : {
        sort_field: HealthcareOrganizationFieldGuid.ShortName,
        sort_direct: SortDirection.Asc,
      };

  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.ShortName,
        HealthcareOrganizationFieldGuid.Status,
        HealthcareOrganizationFieldGuid.Type,
        HealthcareOrganizationFieldGuid.Actualization,
        HealthcareOrganizationFieldGuid.Phone,
        HealthcareOrganizationFieldGuid.Address_p1,
        HealthcareOrganizationFieldGuid.Address_p2,
        HealthcareOrganizationFieldGuid.StatusReason,
        HealthcareOrganizationFieldGuid.StatusDate,
        HealthcareOrganizationFieldGuid.StatusBasis,
      ],
      regime: Regime.Data,
      count: filter.count,
      page: filter.page + 1,
      multi_filter: multiFilter,
      ...sort,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") {
    return {
      branchList: [],
      count: 0,
    };
  }
  const json: GetDictionaryResponse = response.data;

  return {
    branchList: json.data.map((item) => ({
      id: item[1]!,
      name: convertOptionalStringToSafetyString(item[2]),
      status: getStatusTypeByNumber(+item[3]!),
      organizationType: convertOptionalStringToSafetyString(item[4]),
      actualization: convertOptionalDateToSafetyDate(item[5]),
      phone: convertOptionalStringToSafetyString(item[6]),
      address: item[7] ? `${item[7]}, ${item[8]}` : "—",
      statusReason: convertOptionalStringToSafetyString(item[9]),
      statusDate: convertOptionalDateToSafetyDate(item[10]),
      statusBasis: convertOptionalStringToSafetyString(item[11]),
    })),
    count: json.count,
  };
};

export const getStructuralDivisionListFoStructuralUnits = async (
  organizationId: string,
  idDictionary: number,
): Promise<Organization[]> => {
  const multiFilter = generateMultiFilter([
    [
      {
        field: HealthcareOrganizationFieldGuid.HierarchyLevel,
        value: ` > '1'`,
      },
    ],
    [
      {
        field: HealthcareOrganizationFieldGuid.OrgId,
        value: ` = '${organizationId}'`,
      },
    ],
  ]);

  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.Name,
        HealthcareOrganizationFieldGuid.ShortName,
        HealthcareOrganizationFieldGuid.RegistrationDate,
        HealthcareOrganizationFieldGuid.Actualization,
        HealthcareOrganizationFieldGuid.PartOf,
        HealthcareOrganizationFieldGuid.Type,
        HealthcareOrganizationFieldGuid.Phone,
        HealthcareOrganizationFieldGuid.Address_p1,
        HealthcareOrganizationFieldGuid.HierarchyLevel,
        HealthcareOrganizationFieldGuid.TypeSd,
        HealthcareOrganizationFieldGuid.Address_p2,
      ],
      regime: Regime.Data,
      count: MAXIMUM_PAGE_COUNT,
      page: 0,
      multi_filter: multiFilter,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") {
    return [];
  }

  const json: GetDictionaryResponse = response.data;
  return json.data as Organization[];
};

export const getOptionsForMainOrganizationSelect = async (
  dictionaryId: number,
  isCreating: boolean = false,
) => {
  const multiFilter = generateMultiFilter([
    [
      {
        field: HealthcareOrganizationFieldGuid.HierarchyLevel,
        value: isCreating ? ` > '1'` : ` = '1'`,
      },
    ],
  ]);

  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: dictionaryId,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.Name,
        HealthcareOrganizationFieldGuid.HierarchyLevel,
        HealthcareOrganizationFieldGuid.PartOf,
        HealthcareOrganizationFieldGuid.ParentOrg,
      ],
      regime: Regime.Data,
      count: MAXIMUM_PAGE_COUNT,
      page: 0,
      multi_filter: multiFilter,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") return [];

  const json: GetDictionaryResponse = response.data;
  return json.data as Array<any>;
};

export const getOrganizationShortNameByCodeMap = async (
  idDictionary: number,
  codeList: string[],
): Promise<Map<string, string>> => {
  const multiFilter = generateMultiFilter([
    [
      ...codeList.map((code) => ({
        field: HealthcareOrganizationFieldGuid.Code,
        value: ` = '${code}'`,
      })),
    ],
  ]);

  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: [
        HealthcareOrganizationFieldGuid.Code,
        HealthcareOrganizationFieldGuid.ShortName,
      ],
      regime: Regime.Data,
      count: codeList.length,
      page: 1,
      multi_filter: multiFilter,
    }),
  };
  const response = await fetchWrapper(GRID_URL, "POST", body);
  if (typeof response === "undefined") return new Map();

  const json: GetDictionaryResponse = response.data;

  const map = new Map<string, string>();

  json.data.forEach((item) => {
    map.set(item[1]!, item[2]!);
  });

  return map;
};

export const getUserOrganizationIdByEmail = async (
  email: string,
): Promise<string | undefined> => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/ui/mo_code_private_reader?email=${email}`,
    "GET",
  );
  if (typeof response === "undefined") return undefined;

  return response.data;
};
