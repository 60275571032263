import React from "react";
import { FormControlLabel, Radio as MUIRadio } from "@mui/material";
import { Props } from "./Radio.types";

export const Radio = (props: Props) => {
  const { label, value } = props;

  return (
    <FormControlLabel
      value={value}
      control={
        <MUIRadio
          sx={{
            "&, &.Mui-checked": {
              color: "#26A59A",
            },
          }}
        />
      }
      label={label}
    />
  );
};
