import { useState } from "react";
import { PreparedStructuralUnit } from "./StructuralUnits.types";
import { EConfirmDialogStatus } from "./features/ConfirmationDialog/ConfirmationDialog.types";
import { useAppSelector } from "../../../../store/hooks";
import { useSnackBar } from "./features";
import { deleteStructuralUnitRequest } from "../../../../api/delete-dictionaries";

type Props = {
  onRemoveHandler?: (() => void) | (() => Promise<void>);
};

export const useRemoveStructuralUnit = ({ onRemoveHandler }: Props) => {
  const [targetItem, setTargetItem] = useState<null | PreparedStructuralUnit>(
    null,
  );
  const dictionaryId = useAppSelector(
    (state) => state.dictionaries.gridIdDictionary,
  );

  const [isRemoving, setIsRemoving] = useState(false);

  const {
    isSnackBarOpen,
    snackBarState,
    openErrorSnackBar,
    openSuccessSnackBarr,
  } = useSnackBar();

  const [dialogState, setDialogState] = useState<EConfirmDialogStatus>(
    EConfirmDialogStatus.Close,
  );

  const deleteStructuralUnit = async (unit: PreparedStructuralUnit) => {
    if (!dictionaryId) return;
    try {
      setIsRemoving(true);
      const result = await deleteStructuralUnitRequest(
        {
          idDictionaryItem: unit.ignore,
        },
        dictionaryId,
      );
      if (!result.result) {
        openErrorSnackBar();
      } else {
        openSuccessSnackBarr();
        onRemoveHandler?.();
      }
    } catch (error) {
      openErrorSnackBar();
    } finally {
      setIsRemoving(false);
      setTargetItem(null);
    }
  };

  const handleDeleteByDialog = () => {
    if (targetItem) {
      deleteStructuralUnit(targetItem).finally(() =>
        setDialogState(EConfirmDialogStatus.Close),
      );
    }
  };

  const removeItem = (unit: PreparedStructuralUnit) => {
    if (unit.children.length > 0) {
      setDialogState(EConfirmDialogStatus.RenoveSp);
      setTargetItem(unit);
      return deleteStructuralUnit;
    } else {
      deleteStructuralUnit(unit);
    }
  };

  const handleDialogClose = () => {
    setDialogState(EConfirmDialogStatus.Close);
    setTargetItem(null);
  };

  return {
    targetItem,
    handleDialogClose,
    removeItem,
    isRemoving,
    dialogState,
    handleDeleteByDialog,
    snackBar: {
      open: isSnackBarOpen,
      state: snackBarState,
    },
  };
};
