import { TreeSelectOption } from "../../../../../components/TreeSelect/TreeSelect.types";
import { MainOrganization } from "./StructuralUnitsModal.types";
import { Option } from "../../../../../components";
import { TreeViewBaseItem } from "@mui/x-tree-view";

type PreparedData = ReturnType<typeof convetToObject> & {
  children: ReturnType<typeof convetToObject>[];
};

const convetToOption = (item: MainOrganization): TreeViewBaseItem => {
  return {
    id: item[1],
    label: item[2],
    children: [],
  };
};

const convetToObject = (item: MainOrganization) => {
  return {
    id: item[1],
    label: item[2],
    hierarcyLevel: item[3],
    partOf: item[4],
    children: [],
  };
};

const convetToObjectArray = (items: MainOrganization[]) =>
  items.map((item) => convetToObject(item));

const filterObjectsMainOrganization = (
  items: MainOrganization[],
  ids: string[],
) => {
  return items.filter((item) => ids.includes(item[1]));
};

export function buildHierarchyFotOptions(data: PreparedData[]): PreparedData[] {
  const map: Record<string, PreparedData> = {};
  data?.forEach((item) => {
    map[item.label] = { ...item, children: [] };
  });

  const hierarchy: PreparedData[] = [];
  data?.forEach((item) => {
    map[item.label] = {
      id: item.id,
      label: item.label,
      partOf: item.partOf,
      hierarcyLevel: item.hierarcyLevel,
      children: [],
    };
  });

  data?.forEach((item) => {
    if (item.partOf && map[item.partOf]) {
      map[item.partOf].children.push(map[item.label]);
    } else {
      hierarchy.push(map[item.label]);
    }
  });

  Object.keys(map).forEach((key) => {
    if (map[key].partOf === null && !hierarchy.includes(map[key])) {
      hierarchy.push(map[key]);
    }
  });

  return hierarchy;
}

export const transformToTreeViewBaseItems = (
  units: PreparedData[],
): TreeSelectOption[] => {
  return units.map((unit) => ({
    id: unit.id,
    label: unit.label,
    children: unit.children ? transformToTreeViewBaseItems(unit.children) : [],
  }));
};

const calcOptionsTreeView = (data: MainOrganization[], ids: string[]) =>
  transformToTreeViewBaseItems(
    buildHierarchyFotOptions(
      convetToObjectArray(filterObjectsMainOrganization(data, ids)),
    ),
  );

const changeOptionHandler = (
  val: string | undefined,
  options: TreeSelectOption[],
): TreeSelectOption[] => {
  if (!val) {
    return [];
  }

  if (typeof val === "string") {
    const target = findOptionByValue(options, val);
    return target ? [target] : [];
  }

  return val;
};

const findOptionByValue = (
  options: TreeSelectOption[],
  val: string,
): TreeSelectOption | undefined => {
  for (const option of options) {
    if (
      option?.id === val ||
      option?.label?.toLocaleLowerCase?.() === val?.toLocaleLowerCase?.()
    ) {
      return option;
    }
    if (option.children) {
      const found = findOptionByValue(option.children, val);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

function isObjectsEqual(
  obj1: Record<string, any> | null,
  obj2: Record<string, any> | null,
) {
  if (!obj1 || !obj2) return false;
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !isObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

const validateSelectValue = (
  val: string | string[] | Option[],
  flag: boolean,
) => {
  if (!flag) {
    return true;
  } else {
    if (!val) return false;
    if (Array.isArray(val)) {
      return val.length > 0 ? true : false;
    }
    return true;
  }
};

const isSelectInvalid = (val: string[] | Option[] | string) => {
  if (!val) {
    return true;
  }

  if (Array.isArray(val)) {
    return val.length > 0 ? false : true;
  }
  if (typeof val === "string" && val.length > 0) {
    return false;
  }
  return true;
};

function validatePhoneNumber<T extends boolean>(
  phoneNumber: string,
  shouldValidate: boolean,
  returnAsMessage: T,
): T extends true ? string | undefined : boolean {
  if (!shouldValidate) {
    return (returnAsMessage ? undefined : true) as T extends true
      ? string | undefined
      : boolean;
  }

  const regexOnlyDigits = /^\d+$/;

  if (!regexOnlyDigits.test(phoneNumber)) {
    if (returnAsMessage) {
      return "Номер телефона должен содержать только цифры." as T extends true
        ? string | undefined
        : boolean;
    } else {
      return false as T extends true ? string | undefined : boolean;
    }
  }

  return (returnAsMessage ? undefined : true) as T extends true
    ? string | undefined
    : boolean;
}

const isEqual = (a: any, b: any): boolean => {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === "object" && a !== null && b !== null) {
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) {
        return false;
      }
      for (let i = 0; i < a.length; i++) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    } else if (!Array.isArray(a) && !Array.isArray(b)) {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) {
        return false;
      }
      for (let key of aKeys) {
        if (!b.hasOwnProperty(key) || !isEqual(a[key], b[key])) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export {
  isObjectsEqual,
  changeOptionHandler,
  validateSelectValue,
  validatePhoneNumber,
  isSelectInvalid,
  calcOptionsTreeView,
  convetToOption,
  findOptionByValue,
  isEqual,
};
