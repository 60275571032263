import { DeleteResponse } from "../pages/OrganizationCard/Features/StructuralUnits/features";
import { HealthcareOrganizationFieldGuid } from "../types";
import { CreateOrUpdateStructuralUnitsData } from "./integration.types";
import { fetchWrapper } from "../utils";
import { ITEM_URL } from "../consts";

export const deleteStructuralUnitRequest = async (
  arg: Pick<CreateOrUpdateStructuralUnitsData, "idDictionaryItem">,
  dictionaryId: number,
): Promise<DeleteResponse> => {
  const dataString = JSON.stringify({
    id_dictionary: dictionaryId,
    [HealthcareOrganizationFieldGuid.IDSDictionaryItem]: [
      +arg.idDictionaryItem,
    ],
  });

  const body = {
    data: dataString,
  };

  const response = await fetchWrapper(ITEM_URL, "DELETE", body);

  return await response?.data;
};
