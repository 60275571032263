import styles from "./OrganizationCard.module.scss";
import { useOrganizationCard } from "./OrganizationCard.hook";
import { Section, sidebarSectionList } from "./OrganizationCard.consts";
import cn from "classnames";
import {
  Branches,
  ChangesHistory,
  Header,
  ChangeOrganizationStatusModal,
  OrganizationPassportBlock,
  StructuralUnits,
} from "./Features";
import { Props } from "./OrganizationCard.types";
import { CardStatusContentCloseOrSuspendedStatus } from "../HealthcareOrganizationsRegister/Features/CustomTable/CustomTable.types";
import { FieldURI } from "../../consts";
import { Breadcrumbs } from "../../components";
import * as React from "react";

export const OrganizationCard = (props: Props) => {
  const {
    currentSection,
    handleSidebarSectionOnClick,
    organization,
    checkSectionIsVisible,
    convertOptionValueToOption,
    handleOnCloseCard,
    isChangeOrganizationStatusModalOpen,
    handleChangeOrganizationStatusModalOnClose,
    handleOnSuspendCard,
    changeOrganizationStatusModalMode,
    handleOnActiveCard,
    breadcrumbsLinkList,
  } = useOrganizationCard(props);
  const { changesHistory } = props;

  return (
    <div className={styles.container}>
      <Breadcrumbs linkList={breadcrumbsLinkList} />
      <Header
        organizationName={organization?.healthcareOrganizationContent.shortName}
        lastModified={organization?.actualization}
        status={organization?.cardStatusContent.status}
        onCloseCard={handleOnCloseCard}
        onSuspendCard={handleOnSuspendCard}
        onActiveCard={handleOnActiveCard}
      />
      <div className={styles.card}>
        <div className={styles.sidebar}>
          {sidebarSectionList.filter(checkSectionIsVisible).map((section) => (
            <div
              key={section.toString()}
              className={cn(styles.sidebar__section_item, {
                [styles.sidebar__section_item__current]:
                  section === currentSection,
                [styles.sidebar__section_item__not_current]:
                  section !== currentSection,
              })}
              onClick={handleSidebarSectionOnClick(section)}
            >
              {section}
            </div>
          ))}
        </div>
        <div className={styles.content}>
          {organization && currentSection === Section.OrganizationPassport && (
            <OrganizationPassportBlock
              idDictionaryItem={organization.idDictionaryItem}
              parentId={organization.parentId}
              isOrganization={organization.isOrganization}
              fullName={organization.healthcareOrganizationContent.name}
              shortName={organization.healthcareOrganizationContent.shortName}
              ownershipType={convertOptionValueToOption(
                organization.healthcareOrganizationContent.ownershipForm,
                FieldURI.OwnershipType,
              )}
              UNP={organization.healthcareOrganizationContent.UNP}
              organizationId={organization.id}
              healthcareStructure={convertOptionValueToOption(
                organization.healthcareOrganizationContent.healthcareStructure,
                FieldURI.HealthcareStructure,
              )}
              OKPO={organization.healthcareOrganizationContent.okpo}
              organizationType={convertOptionValueToOption(
                organization.organizationType,
                FieldURI.OrganizationType,
              )}
              parentOrganization={convertOptionValueToOption(
                organization.healthcareOrganizationContent.parentOrganization,
                FieldURI.ParentOrganization,
              )}
              subordination={convertOptionValueToOption(
                organization.healthcareOrganizationContent.subordinationLevel,
                FieldURI.SubordinationLevel,
              )}
              medicalCareLevel={convertOptionValueToOption(
                organization.healthcareOrganizationContent.medicalCareLevel,
                FieldURI.MedicalCareLevel,
              )}
              registrationDate={
                organization.healthcareOrganizationContent.registrationDate
              }
              registerRegistrationDate={
                organization.healthcareOrganizationContent
                  .registerRegistrationDate
              }
              lastUpdateDate={organization.actualization}
              medicalCareConditions={convertOptionValueToOption(
                organization.healthcareOrganizationContent
                  .medicalCareConditions,
                FieldURI.MedicalCareConditions,
              )}
              medicalCareTypes={convertOptionValueToOption(
                organization.healthcareOrganizationContent.medicalCareType,
                FieldURI.MedicalCareType,
              )}
              postcode={organization.healthcareOrganizationContent.postcode}
              COATOCode={organization.healthcareOrganizationContent.COATOCode}
              addressP1={organization.healthcareOrganizationContent.addressP1}
              addressP2={organization.healthcareOrganizationContent.addressP2}
              internetAddress={
                organization.healthcareOrganizationContent.internetAddress
              }
              emailAddress={
                organization.healthcareOrganizationContent.emailAddress
              }
              phone={organization.healthcareOrganizationContent.phone}
              fax={organization.healthcareOrganizationContent.fax}
              status={organization.cardStatusContent.status}
              statusBasis={
                (
                  organization.cardStatusContent as CardStatusContentCloseOrSuspendedStatus
                )?.statusBasis
              }
              statusDate={
                (
                  organization.cardStatusContent as CardStatusContentCloseOrSuspendedStatus
                )?.statusDate
              }
              statusReason={
                (
                  organization.cardStatusContent as CardStatusContentCloseOrSuspendedStatus
                )?.statusReason
              }
            />
          )}
          {currentSection === Section.StructuralUnits && <StructuralUnits />}
          {currentSection === Section.Branches && <Branches />}
          {currentSection === Section.ChangesHistory && (
            <ChangesHistory changesHistory={changesHistory} />
          )}
        </div>
      </div>

      {isChangeOrganizationStatusModalOpen &&
        organization &&
        typeof changeOrganizationStatusModalMode !== "undefined" && (
          <ChangeOrganizationStatusModal
            changeStatusMode={changeOrganizationStatusModalMode}
            organization={organization}
            onClose={handleChangeOrganizationStatusModalOnClose}
          />
        )}
    </div>
  );
};
