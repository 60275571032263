import styles from "./NotingFoundStub.module.scss";
import { Stub } from "../../components";
import { ReactComponent as DeviceTabletSearch } from "../../icons/device_tablet_search.svg";
import { Props } from "./NothingFoundStub.types";

export const NothingFoundStub = (props: Props) => {
  const { isWithAdvice } = props;

  return (
    <Stub
      icon={<DeviceTabletSearch />}
      text={
        <div className={styles.text}>
          <div>По вашему запросу ничего не найдено.</div>
          {isWithAdvice && <div>Попробуйте уточнить или изменить запрос.</div>}
        </div>
      }
    />
  );
};
