import { Ref } from "react";
import * as React from "react";
import cn from "classnames";
import styles from "./Status.module.scss";
import { Props, StatusType } from "./Status.types";

export const Status = React.forwardRef(function Status(
  props: Props,
  ref: Ref<HTMLDivElement>,
) {
  const { statusType } = props;

  return (
    <div
      {...props}
      ref={ref}
      className={cn(styles.container, {
        [styles.active]: statusType === StatusType.Active,
        [styles.closed]: statusType === StatusType.Closed,
        [styles.suspended]: statusType === StatusType.Suspended,
      })}
    >
      {statusType.toString()}
    </div>
  );
});
