import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./ConfirmationDialog.module.scss";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from "../../../../../../components";
import {
  ConfirmationDialogProps,
  EConfirmDialogStatus,
} from "./ConfirmationDialog.types";

const initContent = {
  title: "",
  text: "",
  rejectButtonText: "",
  confirmButtonText: "",
};

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    onClose,
    open,
    onAcceptConfirmClick,
    onAcceptRejectClick,
    onCancelConfirmClick,
    onCancelRejectClick,
    onRemoveRejectClick,
    onRemoveConfirmClick,
  } = props;

  const [content, setContent] = useState(initContent);

  useEffect(() => {
    open !== EConfirmDialogStatus.Close &&
      setContent({
        title:
          (open === EConfirmDialogStatus.Confirm &&
            "Сохранение редактирования") ||
          (open === EConfirmDialogStatus.Reject && "Отмена редактирования") ||
          (open === EConfirmDialogStatus.RenoveSp &&
            "Удалить структурное подразделение?") ||
          (open === EConfirmDialogStatus.RejectCreateSP &&
            "Отмена добавления") ||
          "",
        text:
          (open === EConfirmDialogStatus.Confirm &&
            `Вы действительно хотите изменить головную структуру? Все вложенные структурные подразделения также будут отнесены к новой головной организации.`) ||
          (open === EConfirmDialogStatus.Reject &&
            `Вы действительно хотите отменить редактирование?
Внесенные вами изменения не сохранятся.`) ||
          (open === EConfirmDialogStatus.RenoveSp &&
            `Вы действительно хотите удалить структурное подразделение? Все вложенные структурные подразделения также будут удалены. `) ||
          (open === EConfirmDialogStatus.RejectCreateSP &&
            "Вы действительно хотите отменить добавление вложенной структуры? Внесенные вами изменения не сохранятся.") ||
          "",
        rejectButtonText:
          (open === EConfirmDialogStatus.RenoveSp && "Отменить") || "Нет",
        confirmButtonText:
          (open === EConfirmDialogStatus.RenoveSp && "Да, удалить") ||
          (open === EConfirmDialogStatus.RejectCreateSP && "Да, отменить") ||
          "Да",
      });
  }, [open]);

  const handleAcceptClick = () => {
    open === EConfirmDialogStatus.Confirm &&
      onAcceptConfirmClick &&
      onAcceptConfirmClick();
    (open === EConfirmDialogStatus.Reject ||
      open === EConfirmDialogStatus.RejectCreateSP) &&
      onCancelConfirmClick &&
      onCancelConfirmClick();
    open === EConfirmDialogStatus.RenoveSp &&
      onRemoveConfirmClick &&
      onRemoveConfirmClick();
  };

  const handleRejectClick = () => {
    open === EConfirmDialogStatus.Confirm &&
      onAcceptRejectClick &&
      onAcceptRejectClick();
    (open === EConfirmDialogStatus.Reject ||
      open === EConfirmDialogStatus.RejectCreateSP) &&
      onCancelRejectClick &&
      onCancelRejectClick();
    open === EConfirmDialogStatus.RenoveSp &&
      onRemoveRejectClick &&
      onRemoveRejectClick();
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          p: 4,
        }}
        open={open !== EConfirmDialogStatus.Close}
        onClose={onClose}
      >
        <DialogTitle>{content.title}</DialogTitle>
        <DialogContent
          sx={{
            mt: 2,
          }}
        >
          <DialogContentText>{content.text}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            pl: 4,
            pr: 4,
            pb: 4,
          }}
        >
          <Button
            type={ButtonType.Secondary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={handleRejectClick}
            text={content.rejectButtonText}
            className={styles.button}
          />
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={handleAcceptClick}
            text={content.confirmButtonText}
            className={styles.button}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
