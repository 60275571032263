import { configureStore } from "@reduxjs/toolkit";
import openedOrganizationReducer from "./slices/opened-organization-slice";
import dictionariesReducer from "./slices/dictionaries-slice";
import userReducer from "./slices/user";

export const store = configureStore({
  reducer: {
    openedOrganization: openedOrganizationReducer,
    dictionaries: dictionariesReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
