import React from "react";
import styles from "../TooltipContent.module.scss";
import { Props } from "./OrganizationNameTooltipContent.types";

export const OrganizationNameTooltipContent = (props: Props) => {
  const { address, UNP, phone } = props;

  return (
    <div className={styles.container}>
      <div className={styles.field_list}>
        {UNP && (
          <>
            <div>УНП:</div>
            <div>{UNP}</div>
          </>
        )}
        <div>Юридический адрес:</div>
        <div>{address}</div>
        <div>Номер телефона:</div>
        <div>{phone}</div>
      </div>
    </div>
  );
};
