import { getUserInfo, getUserRole } from "../App.utils";
import {
  setFullName,
  setRole,
  setTheirOrganizationId,
} from "../store/slices/user";
import { useAppDispatch } from "../store/hooks";
import { UserRole } from "../consts";
import { getUserOrganizationIdByEmail } from "../api";

export const useInitUser = () => {
  const dispatch = useAppDispatch();

  const initUser = async () => {
    const role = getUserRole();
    dispatch(setRole(role));

    try {
      const userinfo = getUserInfo();
      dispatch(setFullName(userinfo.fullName));
      if (role === UserRole.Operator) {
        const organizationId = await getUserOrganizationIdByEmail(
          userinfo.email,
        );
        if (typeof organizationId !== "undefined") {
          dispatch(setTheirOrganizationId(organizationId));
        }
      } else {
        dispatch(setTheirOrganizationId(""));
      }
    } catch (ex) {}
  };

  return { initUser };
};
