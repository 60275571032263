import { Section } from "./OrganizationCard.consts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setOrganizationPassportBlockMode } from "../../store/slices";
import {
  ChangeOrganizationStatusModalMode,
  Props,
} from "./OrganizationCard.types";
import { useParams } from "react-router-dom";
import {
  getHealthcareOrganizationByCode,
  getHealthcareOrganizationShortNameByCode,
} from "../../api";
import { Row } from "../HealthcareOrganizationsRegister/Features/CustomTable/CustomTable.types";
import { OrganizationPassportBlockMode } from "./Features/OrganizationPassportBlock/OrganizationPassportBlock.types";
import { Option, StatusType } from "../../components";
import { useURLNavigate } from "../../hooks";
import { ADD_API_ERROR_EVENT_NAME, FieldURI, UserRole } from "../../consts";
import { Link } from "../../components/Breadcrumbs/Breadcrumbs.types";

export const useOrganizationCard = (props: Props) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const openedOrganization = useAppSelector(
    (state) => state.openedOrganization,
  );
  const dictionaries = useAppSelector((state) => state.dictionaries);
  const [currentSection, setCurrentSection] = useState<Section>(
    Section.OrganizationPassport,
  );
  const [organization, setOrganization] = useState<Row | undefined>(undefined);
  const { goToHealthcareOrganizationListPage, goToOrganizationCardPage } =
    useURLNavigate();
  const [
    isChangeOrganizationStatusModalOpen,
    setIsChangeOrganizationStatusModalOpen,
  ] = useState<boolean>(false);
  const [
    changeOrganizationStatusModalMode,
    setChangeOrganizationStatusModalMode,
  ] = useState<ChangeOrganizationStatusModalMode | undefined>(undefined);
  const [breadcrumbsLinkList, setBreadcrumbsLinkList] = useState<Link[]>([]);
  const [headquarters, setHeadquarters] = useState<string>("");
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      if (
        !organization ||
        !dictionaries.gridIdDictionary ||
        openedOrganization.organizationPassportBlockMode ===
          OrganizationPassportBlockMode.Creating
      )
        return;

      setHeadquarters(
        await getHealthcareOrganizationShortNameByCode(
          organization.parentId,
          dictionaries.gridIdDictionary,
        ),
      );
    })();
  }, [organization, openedOrganization, dictionaries.gridIdDictionary]);

  useEffect(() => {
    if (!organization) return;

    const currentOrganizationLink = {
      name: organization.healthcareOrganizationContent.shortName,
      onClick: () => {
        goToOrganizationCardPage(organization.id);
        window.location.reload();
      },
    };

    if (organization.isOrganization) {
      setBreadcrumbsLinkList([currentOrganizationLink]);
    } else {
      if (!headquarters) return;

      setBreadcrumbsLinkList([
        {
          name: headquarters,
          onClick: () => {
            goToOrganizationCardPage(organization.parentId);
            window.location.reload();
          },
        },
        currentOrganizationLink,
      ]);
    }
  }, [organization, headquarters]);

  useEffect(() => {
    if (
      openedOrganization.organizationPassportBlockMode ===
        OrganizationPassportBlockMode.Creating &&
      openedOrganization.id.length === 0
    ) {
      goToHealthcareOrganizationListPage();
    }
  }, [openedOrganization]);

  const fetchOrganization = async () => {
    if (params.organizationId && dictionaries.gridIdDictionary) {
      try {
        const response = await getHealthcareOrganizationByCode(
          params.organizationId,
          dictionaries.gridIdDictionary,
        );
        if (response.list.length === 0) {
          const event = new CustomEvent(ADD_API_ERROR_EVENT_NAME, {
            detail: {
              code: 404,
            },
          });
          document.dispatchEvent(event);
        } else {
          setOrganization(response.list[0]);
        }
      } catch (ex) {
        alert("Ошибка получения данных");
      }
    }
  };

  useEffect(() => {
    switch (props.initialMode) {
      case OrganizationPassportBlockMode.Viewing:
        fetchOrganization();
        break;
      case OrganizationPassportBlockMode.Creating:
        setOrganization({
          idDictionaryItem: "",
          id: openedOrganization.id,
          isOrganization: openedOrganization.isOrganization,
          healthcareOrganizationContent: {
            UNP: openedOrganization.UNP,
            name: openedOrganization.fullName,
            shortName: openedOrganization.shortName,
            phone: openedOrganization.phone,
            emailAddress: openedOrganization.emailAddress,
            internetAddress: openedOrganization.internetAddress,
            addressP1: openedOrganization.addressP1,
            addressP2: openedOrganization.addressP2,
            COATOCode: openedOrganization.COATO,
            postcode: openedOrganization.postCode,
            parentOrganization: "",
            medicalCareType: "",
            medicalCareConditions: "",
            registerRegistrationDate: undefined,
            registrationDate: openedOrganization.registrationDate,
            medicalCareLevel: "",
            okpo: "",
            healthcareStructure: "",
            fax: openedOrganization.fax,
            subordinationLevel: "",
            ownershipForm: "",
            address: "",
            legalEntityShortName: "",
          },
          organizationType: "",
          cardStatusContent: {
            status: StatusType.Active,
          },
          actualization: undefined,
          isChecked: false,
          parentId: "",
        });
        break;
    }
  }, [dictionaries.gridIdDictionary]);

  const handleSidebarSectionOnClick = (section: Section) => () => {
    switch (openedOrganization.organizationPassportBlockMode) {
      case OrganizationPassportBlockMode.Viewing:
        setCurrentSection(section);
        break;
      default:
        alert(
          "Дополнительные блоки сведений будут доступны после сохранения данных паспорта организации",
        );
    }
  };

  useEffect(() => {
    dispatch(setOrganizationPassportBlockMode(props.initialMode));
  }, []);

  const checkSectionIsVisible = (section: Section): boolean => {
    if (section === Section.ChangesHistory && user.role === UserRole.Consumer) {
      return false;
    }

    if (
      openedOrganization.organizationPassportBlockMode ===
      OrganizationPassportBlockMode.Viewing
    ) {
      if (!organization) return false;

      if (section === Section.Branches) {
        return organization.isOrganization;
      }

      return true;
    } else if (
      openedOrganization.organizationPassportBlockMode ===
      OrganizationPassportBlockMode.Creating
    ) {
      if (section === Section.Branches) {
        return openedOrganization.isOrganization;
      }
      return true;
    } else {
      return true;
    }
  };

  const findOptionByLabel = (
    optionList: Option[],
    label: string,
  ): Option | undefined => {
    const currentOption = optionList.find((opt) => opt.label === label);

    if (currentOption) {
      return {
        ...currentOption,
      };
    } else {
      return {
        id: "0",
        label: label,
      };
    }
  };

  const getDictionaryItemByFieldURI = (fieldURI: FieldURI) => {
    switch (fieldURI) {
      case FieldURI.HealthcareStructure:
        return dictionaries.healthcareStructureOptionList;
      case FieldURI.ParentOrganization:
        return dictionaries.parentOrganizationList;
      case FieldURI.SubordinationLevel:
        return dictionaries.subordinationLevelOptionList;
      case FieldURI.MedicalCareLevel:
        return dictionaries.medicalCareLevelOptionList;
      case FieldURI.MedicalCareConditions:
        return dictionaries.medicalCareConditionsOptionList;
      case FieldURI.MedicalCareType:
        return dictionaries.medicalCareTypeOptionList;
      case FieldURI.OwnershipType:
        return dictionaries.ownershipTypeOptionList;
      case FieldURI.OrganizationType:
        return dictionaries.organizationTypeOptionList;
      default:
        return [];
    }
  };

  const convertOptionValueToOption = (
    label: string,
    fieldURI: FieldURI,
  ): Option | undefined => {
    if (label.includes("—") || label.length === 0) {
      return undefined;
    } else {
      return findOptionByLabel(getDictionaryItemByFieldURI(fieldURI), label);
    }
  };

  const handleOnCloseCard = async () => {
    setIsChangeOrganizationStatusModalOpen(true);
    setChangeOrganizationStatusModalMode(
      ChangeOrganizationStatusModalMode.ActiveToClosed,
    );
  };

  const handleChangeOrganizationStatusModalOnClose = () => {
    setIsChangeOrganizationStatusModalOpen(false);
  };

  const handleOnSuspendCard = () => {
    setIsChangeOrganizationStatusModalOpen(true);
    setChangeOrganizationStatusModalMode(
      ChangeOrganizationStatusModalMode.ActiveToSuspended,
    );
  };

  const handleOnActiveCard = () => {
    setIsChangeOrganizationStatusModalOpen(true);
    setChangeOrganizationStatusModalMode(
      ChangeOrganizationStatusModalMode.ToActive,
    );
  };

  return {
    currentSection,
    handleSidebarSectionOnClick,
    organization,
    checkSectionIsVisible,
    convertOptionValueToOption,
    handleOnCloseCard,
    isChangeOrganizationStatusModalOpen,
    handleChangeOrganizationStatusModalOnClose,
    handleOnSuspendCard,
    changeOrganizationStatusModalMode,
    handleOnActiveCard,
    breadcrumbsLinkList,
  };
};
