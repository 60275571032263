import styles from "./Filters.module.scss";
import { Actions, AllFiltersModal } from "./Features";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  SearchInput,
  Select,
} from "../../../../components";
import { useHealthcareOrganizationsRegister } from "../../HealthcareOrganizationsRegister.hook";
import { useFilter } from "./Features/Filter.hook";

export const Filters = (
  props: ReturnType<typeof useHealthcareOrganizationsRegister>,
) => {
  const { dictionaries, user } = useFilter();

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.search_by_name}>
          <SearchInput
            placeholder="Поиск по наименованию или УНП"
            value={props.searchByNameQuery}
            setValue={props.setSearchByNameQuery}
            onEnter={props.handleSearchByNameInputOnEnter}
            disabled={typeof user.role === "undefined"}
          />
        </div>

        <Select
          withSelectAll={true}
          className={styles.select}
          isMultiple={true}
          label="Вышестоящая организация"
          optionList={dictionaries.parentOrganizationList}
          selectedOptionList={props.parentOrganizationFilter}
          setSelectedOptionList={props.handleParentOrganizationFilterOnChange}
          disabled={typeof user.role === "undefined"}
        />

        <Select
          withSelectAll={true}
          className={styles.select}
          isMultiple={true}
          label="Структура здравоохранения"
          optionList={dictionaries.healthcareStructureOptionList}
          selectedOptionList={props.healthcareStructureFilter}
          setSelectedOptionList={props.handleHealthcareStructureFilterOnChange}
          disabled={typeof user.role === "undefined"}
        />

        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Outlined}
          size={ButtonSize.M}
          onClick={props.handleAllFiltersButtonOnClick}
          text="Все фильтры"
          isRightCircle={props.areModalFiltersApplied}
          disabled={typeof user.role === "undefined"}
        />

        <Button
          type={ButtonType.Primary}
          style={ButtonStyle.Outlined}
          size={ButtonSize.M}
          onClick={props.handleStartSearch}
          text="Найти"
          disabled={typeof user.role === "undefined"}
        />

        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Text}
          size={ButtonSize.M}
          onClick={props.handleResetAll}
          text="Сбросить все"
          disabled={typeof user.role === "undefined"}
        />
      </div>

      <Actions
        onPrintClick={props.handlePrintOnClick}
        onExportClick={props.handleExportOnClick}
      />

      {props.isAllFilersModalOpen && (
        <AllFiltersModal
          setIsOpened={props.setIsAllFilersModalOpen}
          onSave={props.handleAllFiltersModalOnSave}
          ownershipTypeFilterDefault={props.ownershipTypeFilter}
          ownershipTypeFilterOptionList={dictionaries.ownershipTypeOptionList}
          healthcareStructureFilterDefault={props.healthcareStructureFilter}
          healthcareStructureFilterOptionList={
            dictionaries.healthcareStructureOptionList
          }
          healthcareOrganizationTypeFilterDefault={
            props.healthcareOrganizationTypeFilter
          }
          healthcareOrganizationTypeFilterOptionList={
            dictionaries.organizationTypeOptionList
          }
          medicalCareLevelFilterDefault={props.medicalCareLevelFilter}
          medicalCareLevelFilterOptionList={
            dictionaries.medicalCareLevelOptionList
          }
          medicalCareConditionsFilterDefault={props.medicalCareConditionsFilter}
          medicalCareConditionsFilterOptionList={
            dictionaries.medicalCareConditionsOptionList
          }
          medicalCareTypeFilterDefault={props.medicalCareTypeFilter}
          medicalCareTypeFilterOptionList={
            dictionaries.medicalCareTypeOptionList
          }
          subordinationLevelFilterDefault={props.subordinationLevelFilter}
          subordinationLevelFilterOptionList={
            dictionaries.subordinationLevelOptionList
          }
          parentOrganizationFilterDefault={props.parentOrganizationFilter}
          parentOrganizationFilterOptionList={
            dictionaries.parentOrganizationList
          }
          isShowWithBranches={props.isShowWithBranchesFilter}
          headquartersFilterDefault={props.headquartersFilter}
          headquartersFilterOptionList={dictionaries.allOrganizationsOptionList}
        />
      )}
    </div>
  );
};
