import { useAppSelector } from "../../../../../../store/hooks";

export const useHeader = () => {
  const openedOrganization = useAppSelector(
    (state) => state.openedOrganization,
  );
  const user = useAppSelector((state) => state.user);

  return {
    mode: openedOrganization.organizationPassportBlockMode,
    user,
  };
};
