import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationPassportBlockMode } from "../../pages/OrganizationCard/Features/OrganizationPassportBlock/OrganizationPassportBlock.types";

export type StateType = {
  organizationPassportBlockMode: OrganizationPassportBlockMode | undefined;
  isOrganization: boolean;
  id: string;
  UNP: string;
  fullName: string;
  shortName: string;
  registrationDate: Date | undefined;
  postCode: string;
  COATO: string;
  addressP1: string;
  addressP2: string;
  internetAddress: string;
  emailAddress: string;
  phone: string;
  fax: string;
};

const initialState: StateType = {
  organizationPassportBlockMode: OrganizationPassportBlockMode.Viewing,
  isOrganization: true,
  id: "",
  UNP: "",
  fullName: "",
  shortName: "",
  registrationDate: undefined,
  postCode: "",
  COATO: "",
  addressP1: "",
  addressP2: "",
  internetAddress: "",
  emailAddress: "",
  phone: "",
  fax: "",
};

export const openedOrganizationSlice = createSlice({
  name: "openedOrganization",
  initialState: initialState,
  reducers: {
    setOrganizationPassportBlockMode: (
      state,
      action: PayloadAction<OrganizationPassportBlockMode | undefined>,
    ) => {
      state.organizationPassportBlockMode = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setOrganizationFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setOrganizationShortName: (state, action: PayloadAction<string>) => {
      state.shortName = action.payload;
    },
    setRegistrationDate: (state, action: PayloadAction<Date | undefined>) => {
      state.registrationDate = action.payload;
    },
    setPostcode: (state, action: PayloadAction<string>) => {
      state.postCode = action.payload;
    },
    setCOATO: (state, action: PayloadAction<string>) => {
      state.COATO = action.payload;
    },
    setAddressP1: (state, action: PayloadAction<string>) => {
      state.addressP1 = action.payload;
    },
    setAddressP2: (state, action: PayloadAction<string>) => {
      state.addressP2 = action.payload;
    },
    setInternetAddress: (state, action: PayloadAction<string>) => {
      state.internetAddress = action.payload;
    },
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setFax: (state, action: PayloadAction<string>) => {
      state.fax = action.payload;
    },
    setOrganizationIsOrganization: (state, action: PayloadAction<boolean>) => {
      state.isOrganization = action.payload;
    },
    setOrganizationUNP: (state, action: PayloadAction<string>) => {
      state.UNP = action.payload;
    },
  },
});

export const {
  setOrganizationPassportBlockMode,
  setOrganizationId,
  setOrganizationFullName,
  setOrganizationIsOrganization,
  setOrganizationUNP,
  setOrganizationShortName,
  setRegistrationDate,
  setPostcode,
  setCOATO,
  setAddressP1,
  setAddressP2,
  setInternetAddress,
  setEmailAddress,
  setPhone,
  setFax,
} = openedOrganizationSlice.actions;

export default openedOrganizationSlice.reducer;
