import React, { ReactNode } from "react";

export enum ButtonType {
  Primary,
  Secondary,
  Success,
  Error,
}

export enum ButtonSize {
  L,
  M,
  S,
}

export enum ButtonStyle {
  Contained,
  Outlined,
  Text,
}

export enum ButtonIconPositionRelativeToText {
  Left,
  Right,
}

export type ButtonProps = {
  htmlType?: "submit" | "reset" | "button";
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactNode;
  text?: ReactNode;
  size: ButtonSize;
  type: ButtonType;
  style: ButtonStyle;
  disabled?: boolean;
  className?: string;
  iconPosition?: ButtonIconPositionRelativeToText;
  isRightCircle?: boolean;
};
