import { useURLNavigate } from "../../hooks";

export const useBreadcrumbs = () => {
  const { goToHealthcareOrganizationListPage } = useURLNavigate();

  const handleBackButtonOnClick = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 25);
  };

  return { goToHealthcareOrganizationListPage, handleBackButtonOnClick };
};
