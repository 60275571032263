import { useEffect, useMemo, useState } from "react";
import { Organization, PreparedStructuralUnit } from "./StructuralUnits.types";
import {
  formatToObject,
  buildHierarchy,
  transformToTreeViewBaseItems,
} from "./StructuralUnits.helpers";
import { TreeViewBaseItem } from "@mui/x-tree-view";
import { useParams } from "react-router-dom";
import {
  getHealthcareOrganizationByCode,
  getStructuralDivisionListFoStructuralUnits,
} from "../../../../api";
import { useAppSelector } from "../../../../store/hooks";

export const useHierarchy = () => {
  const [result, setResult] = useState<Organization[]>([]);
  const [parent, setParent] = useState<any>(null);
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dictionaries = useAppSelector((state) => state.dictionaries);
  const user = useAppSelector((state) => state.user);
  const [itemId, setItemId] = useState<string | undefined>();
  const params = useParams() as {
    organizationId: string;
  };

  const fetchData = async () => {
    if (!dictionaries.gridIdDictionary) return;

    setIsLoading(true);
    setIsError(false);
    try {
      const [data, parent] = (
        await Promise.allSettled([
          getStructuralDivisionListFoStructuralUnits(
            params?.organizationId || "",
            dictionaries.gridIdDictionary,
          ),
          getHealthcareOrganizationByCode(
            params?.organizationId || "",
            dictionaries.gridIdDictionary,
          ),
        ])
      ).map((res) => {
        if (res.status === "rejected") {
          setIsError(true);
        }
        return res.status === "fulfilled" ? res.value : undefined;
      });

      if (
        parent &&
        "list" in parent &&
        Array.isArray(parent.list) &&
        parent.count > 0
      ) {
        setParent(parent.list[0]);
      } else {
        setParent(undefined);
      }
      setResult(data as Organization[]);
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.organizationId, dictionaries.gridIdDictionary]);

  useEffect(() => {
    isUpdating && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating]);

  const handleUpdated = () => setIsUpdating(true);

  //элементы с учетом иерархии
  const hierarchyItems: PreparedStructuralUnit[] = useMemo(() => {
    const dataToObjectArray = result?.map((it) =>
      formatToObject(
        it,
        parent?.healthcareOrganizationContent?.name || undefined,
      ),
    );

    return parent
      ? buildHierarchy(dataToObjectArray, parent)
      : buildHierarchy(dataToObjectArray);
  }, [result, parent]);
  //элементы для рендера
  const treeViewItems = useMemo(
    () => transformToTreeViewBaseItems(hierarchyItems),
    [hierarchyItems],
  );

  //раскрытие всех уровней иерархии
  useEffect(() => {
    const allItemIdsWithChildren = new Set<string>();
    const collectIdsWithChildren = (items: TreeViewBaseItem[]) => {
      items.forEach((item) => {
        if (item.children && item.children.length > 0) {
          allItemIdsWithChildren.add(item.id);
          collectIdsWithChildren(item.children);
        }
      });
    };
    collectIdsWithChildren(treeViewItems);
    setExpandedItems(allItemIdsWithChildren);
  }, [result, treeViewItems]);

  //тогл уровня иерархии
  const toggleExpand = (itemId: string) => {
    setExpandedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  const findCorrespondingItem = (
    treeItem: TreeViewBaseItem,
    hierarchyItems: PreparedStructuralUnit[],
  ): PreparedStructuralUnit | undefined => {
    for (const item of hierarchyItems) {
      if (item.code === treeItem.id) {
        return item;
      }
      if (item.children.length > 0) {
        const found = findCorrespondingItem(treeItem, item.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const [isModalOpen, setIsModalOpen] = useState<
    PreparedStructuralUnit | boolean
  >(false);

  const handleModalOpen = (item?: TreeViewBaseItem | string) => {
    if (item && typeof item !== "string") {
      const targetItem = findCorrespondingItem(item, hierarchyItems);
      if (targetItem) {
        setIsModalOpen(targetItem);
        setItemId(targetItem.code);
      }
    } else {
      item && setItemId(item);
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setItemId(undefined);
  };

  const getTargetItem = (item?: TreeViewBaseItem) => {
    if (item) {
      const targetItem = findCorrespondingItem(item, hierarchyItems);
      return {
        targetItem: targetItem,
        childrens: item.children?.length || 0,
      };
    } else {
      return undefined;
    }
  };

  const findItemById = (
    id: string,
    items: PreparedStructuralUnit[],
  ): PreparedStructuralUnit | undefined => {
    for (const item of items) {
      if (item.code === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findItemById(id, item.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  return {
    hierarchyItems,
    treeViewItems,
    expandedItems,
    toggleExpand,
    isModalOpen,
    handleModalOpen,
    handleModalClose,
    handleUpdated,
    getTargetItem,
    findItemById,
    setIsModalOpen,
    isLoading,
    isError,
    user,
    itemId,
  };
};
