import { useAppSelector } from "../../../../store/hooks";
import { SAVE_ORGANIZATION_EVENT_NAME } from "../../../../consts";
import { useURLNavigate } from "../../../../hooks";

export const useHeader = () => {
  const { goToHealthcareOrganizationListPage } = useURLNavigate();

  const openedOrganization = useAppSelector(
    (state) => state.openedOrganization,
  );
  const user = useAppSelector((state) => state.user);

  const handleCreateButtonOnClick = () => {
    const event = new CustomEvent(SAVE_ORGANIZATION_EVENT_NAME);

    document.dispatchEvent(event);
  };

  const handleCancelOnClick = () => {
    goToHealthcareOrganizationListPage();
  };

  return {
    openedOrganization,
    handleCreateButtonOnClick,
    handleCancelOnClick,
    user,
  };
};
