import { HealthcareOrganizationFieldGuid, Regime, Tag } from "../types";
import { OrganizationPassport } from "../pages/OrganizationCard/OrganizationCard.types";
import { StatusType } from "../components";
import {
  fetchWrapper,
  formatDateToClassicView,
  getStatusNumberByType,
} from "../utils";
import { UpdateResponse } from "../pages/OrganizationCard/Features/StructuralUnits/features";
import { CreateOrUpdateStructuralUnitsData } from "./integration.types";
import { ITEM_URL } from "../consts";

export const createOrUpdateOrganization = async (
  idDictionary: number,
  organization: OrganizationPassport,
  isCreating: boolean,
  idDictionaryItem: string,
  orgId?: string,
) => {
  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: {
        [HealthcareOrganizationFieldGuid.IDDictionaryItem]: isCreating
          ? null
          : idDictionaryItem,
        [HealthcareOrganizationFieldGuid.Code]: organization.organizationId,
        [HealthcareOrganizationFieldGuid.Name]: organization.fullName,
        [HealthcareOrganizationFieldGuid.ShortName]: organization.shortName,
        [HealthcareOrganizationFieldGuid.UNP]: organization.UNP,
        [HealthcareOrganizationFieldGuid.HealthcareStructure]:
          organization.healthcareStructure!.label,
        [HealthcareOrganizationFieldGuid.OKPO]: organization.OKPO,
        [HealthcareOrganizationFieldGuid.ManagingOrg]:
          organization.parentOrganization!.label,
        [HealthcareOrganizationFieldGuid.SubordinationLevel]:
          organization.subordination!.label,
        [HealthcareOrganizationFieldGuid.MedicalCareLevel]:
          organization.medicalCareLevel!.label,
        [HealthcareOrganizationFieldGuid.RegistrationDate]:
          organization.registrationDate!,
        [HealthcareOrganizationFieldGuid.MedicalCareConditions]:
          organization.medicalCareConditions!.label,
        [HealthcareOrganizationFieldGuid.MedicalCareType]:
          organization.medicalCareTypes!.label,
        [HealthcareOrganizationFieldGuid.Postcode]: organization.postcode,
        [HealthcareOrganizationFieldGuid.COATOCode]: organization.COATOCode,
        [HealthcareOrganizationFieldGuid.Address_p1]: organization.addressP1,
        [HealthcareOrganizationFieldGuid.Address_p2]: organization.addressP2,
        [HealthcareOrganizationFieldGuid.InternetAddress]:
          organization.internetAddress,
        [HealthcareOrganizationFieldGuid.EmailAddress]:
          organization.emailAddress,
        [HealthcareOrganizationFieldGuid.Phone]: organization.phone,
        [HealthcareOrganizationFieldGuid.Fax]: organization.fax,
        [HealthcareOrganizationFieldGuid.OrgId]: orgId ? orgId : null,
        [HealthcareOrganizationFieldGuid.ParentOrg]: orgId ? orgId : null,
        [HealthcareOrganizationFieldGuid.OwnershipForm]:
          organization.ownershipType!.label,
        [HealthcareOrganizationFieldGuid.Type]:
          organization.organizationType!.label,
        [HealthcareOrganizationFieldGuid.Status]: getStatusNumberByType(
          organization.status,
        ),
      },
      regime: Regime.Data,
      clame: {},
    }),
  };
  await fetchWrapper(ITEM_URL, isCreating ? "POST" : "PUT", body);
};

export const updateOrganizationStatus = async (
  idDictionary: number,
  organizationId: string,
  status: StatusType,
  idDictionaryItem: string,
  statusDate: Date,
  statusReason: string,
  statusBasis: string,
) => {
  const body = {
    data: JSON.stringify({
      tag: Tag.DictionaryItem,
      id_dictionary: idDictionary,
      attributes: {
        [HealthcareOrganizationFieldGuid.IDDictionaryItem]: idDictionaryItem,
        [HealthcareOrganizationFieldGuid.Code]: organizationId,
        [HealthcareOrganizationFieldGuid.Status]: getStatusNumberByType(status),
        [HealthcareOrganizationFieldGuid.StatusDate]:
          formatDateToClassicView(statusDate),
        [HealthcareOrganizationFieldGuid.StatusReason]: statusReason,
        [HealthcareOrganizationFieldGuid.StatusBasis]: statusBasis,
      },
      regime: Regime.Data,
      clame: {},
    }),
  };
  await fetchWrapper(ITEM_URL, "PUT", body);
};

export const createOrUpdateStructuralUnit = async (
  arg: CreateOrUpdateStructuralUnitsData,
  dictionaryId: number,
  isCreating: boolean = false,
): Promise<UpdateResponse> => {
  const body = {
    data: JSON.stringify({
      id_dictionary: dictionaryId,
      attributes: {
        [HealthcareOrganizationFieldGuid.IDDictionaryItem]:
          arg.idDictionaryItem,
        [HealthcareOrganizationFieldGuid.Code]: arg.code,
        [HealthcareOrganizationFieldGuid.PartOf]: arg.partOf,
        [HealthcareOrganizationFieldGuid.Name]: arg.newName,
        [HealthcareOrganizationFieldGuid.Address_p1]: arg.address,
        [HealthcareOrganizationFieldGuid.Address_p2]: arg.adress_p2,
        [HealthcareOrganizationFieldGuid.Phone]: arg.phone,
        [HealthcareOrganizationFieldGuid.TypeSd]: arg.type,
        [HealthcareOrganizationFieldGuid.RegistrationDate]:
          arg.registrationDate,
      },
      clame: {},
    }),
  };
  const response = await fetchWrapper(
    ITEM_URL,
    isCreating ? "POST" : "PUT",
    body,
  );

  return response?.data;
};
