import styles from "./Stub.module.scss";
import { Props } from "./Stub.types";

export const Stub = (props: Props) => {
  const { icon, text } = props;

  return (
    <div className={styles.container}>
      {icon}
      {text}
    </div>
  );
};
