import styles from "./Header.module.scss";
import { Divider, Tooltip } from "@mui/material";
import { ReactComponent as HelpSquareIcon } from "../../icons/help_square.svg";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "../../components";
import { ReactComponent as LogoIcon } from "../../icons/logo.svg";
import { ReactComponent as NSIIcon } from "../../icons/nsi.svg";
import * as React from "react";
import { useHeader } from "./Header.hook";

export const Header = () => {
  const { handleOnLogout, user } = useHeader();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <LogoIcon />
        <NSIIcon />
        <div className={styles.logo__text}>
          <div>Регистр организаций</div>
          <div>здравоохранения</div>
        </div>
      </div>
      <div className={styles.right}>
        <Tooltip title="Помощь" arrow>
          <div className={styles.right__help}>
            <HelpSquareIcon />
          </div>
        </Tooltip>

        <div className={styles.right__active_user}>
          <div className={styles.right__active_user__photo}>
            <img src="https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg" />
          </div>
          {user.fullName}
        </div>

        <div className={styles.right__divider}>
          <Divider orientation="vertical" />
        </div>

        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Text}
          size={ButtonSize.M}
          onClick={handleOnLogout}
          text="Выйти"
        />
      </div>
    </div>
  );
};
