import React, { useState } from "react";
import { ActionType, Props } from "./Actions.types";
import { useAppSelector } from "../../../../../../store/hooks";

export const useActions = (props: Props) => {
  const { onExportClick, onPrintClick } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useAppSelector((state) => state.user);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = (actionType?: ActionType) => () => {
    setAnchorEl(null);
    if (actionType) {
      switch (actionType) {
        case ActionType.Export:
          onExportClick();
          break;
        case ActionType.Print:
          onPrintClick();
          break;
      }
    }
  };

  return { anchorEl, handleOnClose, handleOnClick, open, user };
};
