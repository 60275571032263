import React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";
import {
  ButtonIconPositionRelativeToText,
  ButtonProps,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from "./Button.types";

export const Button = (props: ButtonProps) => {
  const {
    htmlType = "button",
    className,
    type,
    size,
    disabled,
    style,
    icon,
    text,
    onClick,
    iconPosition,
    isRightCircle,
  } = props;

  const iconContainer = icon && (
    <div className={styles.icon_or_text}>{icon}</div>
  );
  const textContainer = text && (
    <div className={styles.icon_or_text}>{text}</div>
  );

  return (
    <button
      type={htmlType}
      onClick={(e) => {
        !disabled && onClick(e);
      }}
      className={cn(styles.container, className, {
        [styles.disabled]: disabled,
        [styles.disabled__type_not_secondary_styles_contained]:
          disabled &&
          type !== ButtonType.Secondary &&
          style === ButtonStyle.Contained,

        [styles.size_l]: size === ButtonSize.L,
        [styles.size_m]: size === ButtonSize.M,
        [styles.size_s]: size === ButtonSize.S,

        [styles.type_primary__style_contained]:
          type === ButtonType.Primary && style === ButtonStyle.Contained,
        [styles.type_primary__style_outlined]:
          type === ButtonType.Primary && style === ButtonStyle.Outlined,
        [styles.type_primary__style_text]:
          type === ButtonType.Primary && style === ButtonStyle.Text,

        [styles.type_secondary__style_contained]:
          type === ButtonType.Secondary && style === ButtonStyle.Contained,
        [styles.type_secondary__style_outlined]:
          type === ButtonType.Secondary && style === ButtonStyle.Outlined,
        [styles.type_secondary__style_text]:
          type === ButtonType.Secondary && style === ButtonStyle.Text,

        [styles.type_success__style_contained]:
          type === ButtonType.Success && style === ButtonStyle.Contained,
        [styles.type_success__style_outlined]:
          type === ButtonType.Success && style === ButtonStyle.Outlined,
        [styles.type_success__style_text]:
          type === ButtonType.Success && style === ButtonStyle.Text,

        [styles.type_error__style_contained]:
          type === ButtonType.Error && style === ButtonStyle.Contained,
        [styles.type_error__style_outlined]:
          type === ButtonType.Error && style === ButtonStyle.Outlined,
        [styles.type_error__style_text]:
          type === ButtonType.Error && style === ButtonStyle.Text,
      })}
    >
      {iconPosition === ButtonIconPositionRelativeToText.Right ? (
        <>
          {textContainer}
          {iconContainer}
        </>
      ) : (
        <>
          {iconContainer}
          {textContainer}
        </>
      )}
      {isRightCircle && (
        <div className={styles.right_circle_wrapper}>
          <div className={styles.right_circle_wrapper_circle}></div>
        </div>
      )}
    </button>
  );
};
