import React from "react";
import styles from "./SortableTableCell.module.scss";
import { Props } from "./SortableTableCell.types";
import { ReactComponent as ArrowUpIcon } from "../../icons/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../icons/arrow_down.svg";
import TableCell from "@mui/material/TableCell";
import cn from "classnames";
import { SortDirection } from "../../types";

export const SortableTableCell = (props: Props) => {
  const { isSortable, name, sortDirection, onClick, className } = props;

  return (
    <TableCell onClick={onClick} className={className}>
      <div
        className={cn(styles.content, {
          [styles.is_sortable]: isSortable,
        })}
      >
        <div>{name}</div>
        {isSortable && (
          <>
            {sortDirection === SortDirection.Asc ? (
              <ArrowDownIcon />
            ) : (
              <ArrowUpIcon />
            )}
          </>
        )}
      </div>
    </TableCell>
  );
};
