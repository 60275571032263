import * as React from "react";
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";
import { Props } from "./Checkbox.types";

export const Checkbox = (props: Props) => (
  <FormControlLabel
    style={{ height: 16 }}
    control={
      <MUICheckbox
        {...props}
        sx={{
          borderColor: "rgb(235, 235, 235)",
          "&.Mui-checked": {
            color: "rgb(38, 165, 154)",
          },
        }}
      />
    }
    label={props.label}
  />
);
