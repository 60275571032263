import styles from "./StructuralUnitsModal.module.scss";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseButton,
  Snackbar,
  Loader,
  SnackbarState,
} from "../../../../../components";
import { DetailsBlock } from "../../../../../components";
import { ControlType } from "../../../../../types";
import { getStringFieldErrorText } from "../../../../../utils";
import {
  changeOptionHandler,
  validatePhoneNumber,
  isSelectInvalid,
  findOptionByValue,
} from "./StructuralUnitsModal.helpers";
import { useAppSelector } from "../../../../../store/hooks";
import { UserRole } from "../../../../../consts";
import { ConfirmationDialog, StructuralModalProps } from ".";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

export const StructuralUnitsModal = (props: StructuralModalProps) => {
  const user = useAppSelector((state) => state.user);
  const {
    isUpdating,
    isSubbmitting,
    isEditing,
    isOpen,
    unitData,
    onOpen,
    onClose,
    handleInputChange,
    setIsEditing,
    _changeInitData,
    snackBar,
    alertModal,
    options,
    initPartOfId,
  } = props;

  const { mainOrganizationOptions, typeOptions } = options;

  const param = useParams();
  const {
    open,
    onClose: onAlertModalCkose,
    handleConfirmClick,
    handleRejectClick,
    modalActions,
  } = alertModal;

  const {
    handleAcceptConfirmClick,
    handleAcceptRejectClick,
    handleCancelConfirmClick,
    handleCancelRejectClick,
  } = modalActions;

  const initOrganization = useMemo(() => {
    let result;
    if (isOpen === true) {
      if (initPartOfId) {
        result = findOptionByValue(mainOrganizationOptions, initPartOfId);
      } else {
        result = findOptionByValue(
          mainOrganizationOptions,
          param?.organizationId || "",
        );
      }
    } else if (unitData) {
      if ("mainOrganization" in unitData) {
        result = findOptionByValue(
          mainOrganizationOptions,
          unitData?.mainOrganization || "",
        );
      }

      if ("partOf" in unitData) {
        result = findOptionByValue(
          mainOrganizationOptions,
          unitData?.partOf || "",
        );
      }
    }

    return result
      ? {
          initID: [result.id],
          initLabel: [
            {
              id: result.id,
              label: result.label,
            },
          ],
        }
      : undefined;
  }, [
    param?.organizationId,
    unitData?.partOf,
    mainOrganizationOptions,
    isOpen,
  ]);

  const presetInitValue = async () => {
    const response = await initOrganization;

    if (response && unitData === null) {
      handleInputChange(response.initID, "mainOrganization");
      _changeInitData(response.initID, "mainOrganization");

      return true;
    }
    return false;
  };

  const presetCodeAndDate = () => {
    if (!unitData || !unitData.code) {
      const uniqId = uuid();
      _changeInitData(uniqId, "code");
      handleInputChange(uniqId, "code");
    }
    if (!unitData || !("registrationDate" in unitData)) {
      const date = new Date().toJSON();
      _changeInitData(date, "registrationDate");
      handleInputChange(date, "registrationDate");
    }
    if (!unitData || !("actualization" in unitData)) {
      const date = new Date().toJSON();
      _changeInitData(date, "actualization");
      handleInputChange(date, "actualization");
    }
  };

  useEffect(() => {
    presetInitValue();
    presetCodeAndDate();
  }, [initOrganization, unitData]);

  return (
    <>
      <ConfirmationDialog
        open={open}
        onClose={onAlertModalCkose}
        onAcceptConfirmClick={handleAcceptConfirmClick}
        onAcceptRejectClick={handleAcceptRejectClick}
        onCancelConfirmClick={handleCancelConfirmClick}
        onCancelRejectClick={handleCancelRejectClick}
      />
      <Dialog
        onClose={onClose}
        open={!!isOpen}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "720px",
              borderRadius: "12px",
              padding: "24px",
            },
          },
        }}
      >
        <form className={styles.dialog}>
          <div className={styles.dialog_header}>
            <DialogTitle className={styles.dialog_title}>
              {isOpen === true
                ? "Добавление вложенной структуры"
                : "Карточка структурного подразделения"}
            </DialogTitle>
            <CloseButton onClick={onClose} />
          </div>
          <DialogContent className={styles.dialog_content}>
            <DetailsBlock
              isEditing={isEditing}
              fieldList={[
                {
                  name: "Идентификатор подразделения",
                  controlType: ControlType.Input,
                  value: unitData?.code || "",
                  setValue: (value) => handleInputChange(value, "code"),
                  isRequired: isOpen === true ? false : true,
                  isDisabled: true,
                  errorText:
                    isOpen !== true
                      ? getStringFieldErrorText(
                          unitData?.code || "",
                          isSubbmitting,
                        )
                      : undefined,
                },
                {
                  withSelectAll: false,
                  name: "Головная организация",
                  controlType: ControlType.TreeSelect,
                  label: "Головная организация",
                  initValue: initOrganization?.initID,
                  options: mainOrganizationOptions,
                  selectableParents: true,
                  selectedOptionList: initOrganization?.initLabel || [],
                  onChangeHandlerString: (val: any) => {
                    handleInputChange(val, "mainOrganization");
                  },
                  value: unitData?.mainOrganization,
                  isError:
                    isSubbmitting &&
                    isSelectInvalid(unitData?.mainOrganization),
                  isRequired: true,
                },
                {
                  name: "Наименование",
                  controlType: ControlType.Input,
                  value: unitData?.name || "",
                  setValue: (value) => handleInputChange(value, "name"),
                  isRequired: true,
                  errorText: getStringFieldErrorText(
                    unitData?.name || "",
                    isSubbmitting,
                  ),
                },
                {
                  withSelectAll: false,
                  isMultiple: false,
                  name: "Тип структурного подразделения",
                  controlType: ControlType.Select,
                  label: "Тип структурного подразделения",
                  optionList: typeOptions,
                  selectedOptionList: changeOptionHandler(
                    unitData?.typeSd,
                    typeOptions,
                  ),
                  setSelectedOptionList: (val) => {
                    handleInputChange(val, "typeSd");
                  },

                  isError: isSubbmitting && isSelectInvalid(unitData?.typeSd),
                  isRequired: true,
                },
                {
                  name: "Область, район, город/cельский совет/иной населенный пункт",
                  controlType: ControlType.Input,
                  value: unitData?.adress_p1 || "",
                  setValue: (value) => handleInputChange(value, "adress_p1"),
                  isRequired: true,
                  errorText: getStringFieldErrorText(
                    unitData?.adress_p1 || "",
                    isSubbmitting,
                  ),
                },
                {
                  name: "Улица, номер дома, корпус (дополнительный индекс)",
                  controlType: ControlType.Input,
                  value: unitData?.adress_p2 || "",
                  setValue: (value) => handleInputChange(value, "adress_p2"),
                  isRequired: true,
                  errorText: getStringFieldErrorText(
                    unitData?.adress_p2 || "",
                    isSubbmitting,
                  ),
                },
                {
                  name: "Номер телефона",
                  controlType: ControlType.Input,
                  value: unitData?.telephone || "",
                  setValue: (value) => handleInputChange(value, "telephone"),
                  isRequired: true,
                  errorText: validatePhoneNumber(
                    unitData?.telephone || "",
                    isSubbmitting,
                    true,
                  ),
                },
                {
                  name: "Дата регистрации",
                  isDisabled: true,
                  controlType: ControlType.DatePicker,
                  value: unitData?.registrationDate
                    ? new Date(unitData?.registrationDate)
                    : undefined,
                  setValue: (value) =>
                    handleInputChange(value, "registrationDate"),
                },
                {
                  name: "Дата последней актуализации",
                  controlType: ControlType.DatePicker,
                  isDisabled: true,
                  isRequired: true,
                  value: unitData?.actualization
                    ? new Date(unitData?.actualization)
                    : undefined,
                  setValue: (value) =>
                    handleInputChange(value, "actualization"),
                  isDateAndTimePicker: true,
                },
              ]}
            />
          </DialogContent>
          {(user.role === UserRole.Admin ||
            user.role === UserRole.Operator) && (
            <div className={styles.footer}>
              {!isEditing ? (
                <Button
                  onClick={() => {
                    setIsEditing(true);
                    if (
                      isOpen === true &&
                      unitData &&
                      typeof unitData === "object" &&
                      "name" in unitData
                    ) {
                      onOpen?.(unitData);
                    }
                  }}
                  type={ButtonType.Secondary}
                  size={ButtonSize.M}
                  style={ButtonStyle.Contained}
                  text="Редактировать"
                />
              ) : (
                <div className={styles.buttonsWrapper}>
                  <Button
                    type={ButtonType.Secondary}
                    style={ButtonStyle.Contained}
                    size={ButtonSize.L}
                    onClick={handleRejectClick}
                    text="Отменить"
                  />
                  <Button
                    type={ButtonType.Primary}
                    style={ButtonStyle.Contained}
                    size={ButtonSize.L}
                    onClick={handleConfirmClick}
                    text="Сохранить"
                    className={styles.actions__ok}
                  />
                </div>
              )}
            </div>
          )}
        </form>
        {snackBar.open && (
          <Snackbar
            text={
              snackBar.state === SnackbarState.Success
                ? "Изменения успешно сохранены"
                : "Изменения не были внесены. Возникла ошибка при сохранении."
            }
            state={snackBar.state}
            zIndex={2000}
            extraClass={styles.snackBar}
          />
        )}

        {isUpdating && (
          <div className={styles.loaderWrapper}>
            <Loader width={48} height={48} />
          </div>
        )}
      </Dialog>
    </>
  );
};
