import * as React from "react";
import { Props, SnackbarState } from "./Snackbar.types";
import styles from "./Snackbar.module.scss";
import { createPortal } from "react-dom";
import cn from "classnames";

export const Snackbar = (props: Props) => {
  const { text, state, zIndex, extraClass } = props;

  return createPortal(
    <div
      style={{
        zIndex: zIndex,
      }}
      className={cn(
        styles.container,
        {
          [styles.success]: state === SnackbarState.Success,
          [styles.error]: state === SnackbarState.Error,
        },
        extraClass,
      )}
    >
      {text}
    </div>,
    document.getElementById("root") as HTMLElement,
  );
};
