import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../../consts";

export type StateType = {
  role: UserRole | undefined;
  fullName: string;
  theirOrganizationId: string | undefined;
};

const initialState: StateType = {
  role: UserRole.Consumer,
  fullName: "",
  theirOrganizationId: undefined,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setRole: (state, action: PayloadAction<UserRole | undefined>) => {
      state.role = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setTheirOrganizationId: (state, action: PayloadAction<string>) => {
      state.theirOrganizationId = action.payload;
    },
  },
});

export const { setRole, setFullName, setTheirOrganizationId } =
  userSlice.actions;

export default userSlice.reducer;
