import { useNavigate } from "react-router-dom";
import {
  CREATE_ORGANIZATION_PAGE_URL,
  ORGANIZATION_CARD_PAGE_URL,
  MAIN_PAGE_URL,
} from "../consts";

export const useURLNavigate = () => {
  const navigate = useNavigate();

  const goToHealthcareOrganizationListPage = () => {
    navigate(MAIN_PAGE_URL);
  };

  const goToOrganizationCardPage = (organizationId: string) => {
    navigate(ORGANIZATION_CARD_PAGE_URL(organizationId));
  };

  const goToCreateOrganizationPage = () => {
    navigate(CREATE_ORGANIZATION_PAGE_URL);
  };

  return {
    goToOrganizationCardPage,
    goToCreateOrganizationPage,
    goToHealthcareOrganizationListPage,
  };
};
