import { useCallback, useEffect, useState } from "react";
import { PreparedStructuralUnit } from "../StructuralUnits.types";
import { getStringFieldErrorText } from "../../../../../utils";
import { useSnackBar } from "./useSnackBar";
import { UseStructuralModalProps } from "./StructuralModal.types";
import { EConfirmDialogStatus } from "./ConfirmationDialog/ConfirmationDialog.types";
import { useAppSelector } from "../../../../../store/hooks";
import {
  isObjectsEqual,
  isSelectInvalid,
  validatePhoneNumber,
} from "./StructuralUnitsModal.helpers";
import { createOrUpdateStructuralUnit } from "../../../../../api/save-dictionaries";
import { useModalOptions } from "./useModalOptions.hook";
import { TreeSelectOption } from "../../../../../components/TreeSelect/TreeSelect.types";

export const useStructuralModal = ({
  item,
  onUpdateHandler,
  hierarcyIdList,
  initPartOfId,
  onClose,
  onOpen,
}: UseStructuralModalProps) => {
  const [isAlertModalOpen, setIsAlertModalOpen] =
    useState<EConfirmDialogStatus>(EConfirmDialogStatus.Close);
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    isSnackBarOpen,
    snackBarState,
    openErrorSnackBar,
    openSuccessSnackBarr,
  } = useSnackBar();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [initData, setInitData] = useState<PreparedStructuralUnit | null>(
    typeof item !== "boolean" ? item : null,
  );
  const user = useAppSelector((state) => state.user);

  const { typeOptions, mainOrganizationOptions } = useModalOptions({
    isCreating: isCreating,
    hierarcyIdList: hierarcyIdList,
    showAllOrganizations: !user.theirOrganizationId,
  });

  const dictionaryId = useAppSelector(
    (state) => state.dictionaries.gridIdDictionary,
  );

  useEffect(() => {
    setIsSubmitting(false);
    typeof item !== "boolean" ? setInitData(item) : setInitData(null);
    if (typeof item === "boolean" && item === false) {
      setIsEditing(false);
    }
    if (typeof item === "boolean" && item === true) {
      setIsCreating(true);
      setIsEditing(true);
    } else {
      setIsCreating(false);
    }
  }, [item]);

  const [unitData, setUnitData] = useState<Record<
    keyof PreparedStructuralUnit,
    any
  > | null>(initData);
  useEffect(() => {
    setUnitData(initData);
  }, [item, initData]);

  const handleInputChange = useCallback(
    (value: any, key: keyof Omit<PreparedStructuralUnit, "children">) => {
      setUnitData((prev) => {
        if (prev === null) {
          return {
            [key]: value,
          } as PreparedStructuralUnit;
        }

        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [setUnitData],
  );

  const _changeInitData = useCallback(
    (value: any, key: keyof Omit<PreparedStructuralUnit, "children">) => {
      setInitData((prev) => {
        if (prev === null) {
          return {
            [key]: value,
          } as PreparedStructuralUnit;
        }

        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [setInitData],
  );

  const handleResetClick = () => setUnitData(initData);

  const findItemById = (
    items: TreeSelectOption[],
    id: string,
  ): TreeSelectOption | undefined => {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const handleCancelConfirmClick = () => {
    handleResetClick();
    setIsAlertModalOpen(EConfirmDialogStatus.Close);
    setIsSubmitting(false);
    setIsEditing(false);
  };
  const handleCancelRejectClick = () => {
    setIsAlertModalOpen(EConfirmDialogStatus.Close);
    setIsSubmitting(false);
  };

  const validateFields = async () => {
    const result: Array<any> = [];
    result.push(getStringFieldErrorText(unitData?.name || "", true));
    result.push(getStringFieldErrorText(unitData?.adress_p1 || "", true));
    result.push(getStringFieldErrorText(unitData?.adress_p2 || "", true));
    result.push(validatePhoneNumber(unitData?.telephone || "", true, true));
    result.push(isSelectInvalid(unitData?.mainOrganization));
    result.push(isSelectInvalid(unitData?.typeSd));
    const isAllValid = result.every((val) => !!val === false);

    return isAllValid;
  };

  const handleAcceptConfirmClick = async () => {
    setIsSubmitting(true);

    const isValid = await validateFields();

    if (isValid && dictionaryId) {
      setIsUpdating(true);
      const result = await createOrUpdateStructuralUnit(
        {
          idDictionaryItem: unitData?.ignore,
          code: unitData?.code,
          partOf:
            typeof unitData?.mainOrganization === "string"
              ? unitData?.mainOrganization
              : unitData?.mainOrganization[0],
          newName: unitData?.name,
          address: unitData?.adress_p1,
          adress_p2: unitData?.adress_p2,
          phone: unitData?.telephone,
          registrationDate: initData?.registrationDate
            ? initData.registrationDate
            : unitData?.registrationDate
              ? unitData?.registrationDate
              : new Date().toJSON(),
          type:
            unitData?.typeSd === "string"
              ? unitData?.typeSd
              : unitData?.typeSd[0].id,
        },
        dictionaryId,
        isCreating,
      );

      if (result.updated) {
        setIsUpdating(false);
        openSuccessSnackBarr();
        setIsEditing(false);
        setInitData(unitData);
        setIsAlertModalOpen(EConfirmDialogStatus.Close);
        onUpdateHandler && onUpdateHandler();

        // onClose?.() Если надо будет закрыть после добавления
      } else {
        setIsUpdating(false);
        openErrorSnackBar();
        setIsAlertModalOpen(EConfirmDialogStatus.Close);
      }
    }
    setIsAlertModalOpen(EConfirmDialogStatus.Close);
  };

  const handleAcceptRejectClick = () => {
    setIsSubmitting(false);
    setIsAlertModalOpen(EConfirmDialogStatus.Close);
  };

  const handleCanelClick = () => {
    if (
      isObjectsEqual(initData, unitData) ||
      (initData === null && unitData === null)
    ) {
      setIsEditing(false);
    } else {
      setIsAlertModalOpen(
        item === true
          ? EConfirmDialogStatus.RejectCreateSP
          : EConfirmDialogStatus.Reject,
      );
    }
  };

  const handleConfirmClick = () => {
    if (isObjectsEqual(initData, unitData)) {
      setIsSubmitting(true);
    } else if (
      initData?.mainOrganization !== unitData?.mainOrganization &&
      item !== true
    ) {
      setIsAlertModalOpen(EConfirmDialogStatus.Confirm);
    } else {
      handleAcceptConfirmClick();
    }
  };

  return {
    initData,
    isEditing,
    unitData,
    setIsEditing,
    handleInputChange,
    handleResetClick,
    _changeInitData,
    isSubbmitting,
    setIsSubmitting,
    isUpdating,
    isCreating,
    hierarcyIdList,
    initPartOfId,
    onOpen,
    options: {
      typeOptions,
      mainOrganizationOptions,
    },
    snackBar: {
      open: isSnackBarOpen,
      state: snackBarState,
    },
    alertModal: {
      open: isAlertModalOpen,
      handleConfirmClick: handleConfirmClick,
      handleRejectClick: handleCanelClick,
      onClose: () => setIsAlertModalOpen(EConfirmDialogStatus.Close),
      modalActions: {
        handleAcceptConfirmClick,
        handleAcceptRejectClick,
        handleCancelConfirmClick,
        handleCancelRejectClick,
      },
    },
  };
};
