import * as React from "react";
import { Props } from "./ChangesHistory.types";
import styles from "./ChangesHistory.module.scss";
import tableStyles from "../../../../styles/Table.module.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { SortableTableCell } from "../../../../components";
import { SortDirection } from "../../../../types";

export const ChangesHistory = (props: Props) => {
  const { changesHistory } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>История изменений</div>
      <div className={tableStyles.container}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={tableStyles.head}>
              <TableRow>
                <SortableTableCell
                  name="Дата и время"
                  onClick={() => {}}
                  sortDirection={SortDirection.Asc}
                  isSortable={false}
                  className={tableStyles.head__cell}
                />
                <SortableTableCell
                  name="Пользователь"
                  onClick={() => {}}
                  sortDirection={SortDirection.Asc}
                  isSortable={false}
                  className={tableStyles.head__cell}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {changesHistory.map((item) => (
                <TableRow key={`${item.dateTime}_${item.user}`}>
                  <TableCell className={tableStyles.body_cell} width="14%">
                    {item.dateTime}
                  </TableCell>
                  <TableCell className={tableStyles.body_cell}>
                    {item.user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
