const ITEM_HEIGHT = 32;

const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 16 + ITEM_PADDING_TOP,
    },
  },
};
