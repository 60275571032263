import * as React from "react";
import styles from "./Branches.module.scss";
import tableStyles from "../../../../styles/Table.module.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {
  Loader,
  SortableTableCell,
  Status,
  StatusType,
  TablePaginationActions,
} from "../../../../components";
import { useBranches } from "./Branches.hook";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import { ColumnName } from "../../../HealthcareOrganizationsRegister/Features/CustomTable/CustomTable.consts";
import { formatDateToClassicView } from "../../../../utils";
import { DataNotLoadedStub, NothingFoundStub } from "../../../../features";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../consts";
import { OrganizationNameTooltipContent } from "../../../HealthcareOrganizationsRegister/Features/CustomTable/Features/OrganizationNameTooltipContent";
import Tooltip from "@mui/material/Tooltip";
import { StatusTooltipContent } from "../../../HealthcareOrganizationsRegister/Features/CustomTable/Features";

export const Branches = () => {
  const {
    branchList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handSortableTableCellOnClick,
    sortedColumnName,
    sortDirection,
    isLoading,
    isFetchListError,
    branchesListTotalCount,
    handleRowOnClick,
  } = useBranches();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Филиалы</div>
      {isLoading || isFetchListError || branchList.length === 0 ? (
        <div className={styles.stub}>
          {isLoading ? (
            <Loader height={48} width={48} />
          ) : isFetchListError ? (
            <DataNotLoadedStub />
          ) : (
            <NothingFoundStub isWithAdvice={false} />
          )}
        </div>
      ) : (
        <div className={tableStyles.container}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={tableStyles.head}>
                <TableRow>
                  {[
                    ColumnName.OrganizationName,
                    ColumnName.Status,
                    ColumnName.OrganizationType,
                    ColumnName.Actualization,
                  ].map((name) => (
                    <SortableTableCell
                      name={name}
                      onClick={handSortableTableCellOnClick(name.toString())}
                      sortDirection={sortDirection}
                      isSortable={sortedColumnName === name}
                      className={tableStyles.head__cell}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {branchList.map((branch, index) => (
                  <TableRow
                    key={`${branch.name}_${index}`}
                    className={tableStyles.table_row}
                    onClick={handleRowOnClick(branch.id)}
                  >
                    <TableCell className={tableStyles.body_cell}>
                      <Tooltip
                        title={<OrganizationNameTooltipContent {...branch} />}
                        arrow
                      >
                        <div className={styles.organization_name}>
                          {branch.name}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={tableStyles.body_cell}>
                      {branch.status === StatusType.Active ? (
                        <Status statusType={branch.status} />
                      ) : (
                        <Tooltip
                          title={
                            <StatusTooltipContent
                              status={branch.status}
                              statusDate={branch.statusDate}
                              statusBasis={branch.statusBasis}
                              statusReason={branch.statusReason}
                            />
                          }
                          arrow
                        >
                          <Status statusType={branch.status} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={tableStyles.body_cell}>
                      {branch.organizationType}
                    </TableCell>
                    <TableCell className={tableStyles.body_cell}>
                      {branch.actualization
                        ? formatDateToClassicView(branch.actualization)
                        : "—"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <div className={tableStyles.all_rows_counter}>
                    Результатов: {branchList.length}
                  </div>
                  <TablePagination
                    className={tableStyles.pagination}
                    rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
                    colSpan={3}
                    count={branchesListTotalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Показывать по"
                    slotProps={{
                      select: {
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};
