export enum HyperLinkType {
  SimpleLink,
  Mail,
  ClickHandlerInsteadHref,
}

export type Props = {
  link: string;
  linkType: HyperLinkType;
  clickHandler?: () => void;
};
