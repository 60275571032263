export enum EConfirmDialogStatus {
  Reject = "reject",
  Confirm = "confirm",
  Close = "close",
  RenoveSp = "remove",
  RejectCreateSP = "rejectCreate",
}

export type ConfirmationDialogProps = {
  open: EConfirmDialogStatus;
  onClose: () => void;
  onCancelConfirmClick?: () => void;
  onCancelRejectClick?: () => void;
  onAcceptConfirmClick?: (() => void) | (() => Promise<void>);
  onAcceptRejectClick?: () => void;
  onRemoveRejectClick?: (() => void) | (() => Promise<void>);
  onRemoveConfirmClick?: (() => void) | (() => Promise<void>);
};
