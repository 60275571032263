import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import styles from "./CloseButton.module.scss";
import { Props } from "./CloseButton.types";
import cn from "classnames";

export const CloseButton = (props: Props) => {
  const { onClick, className, isOnModal } = props;

  return (
    <div
      className={cn(styles.container, className, {
        [styles.is_on_modal]: isOnModal,
      })}
      onClick={onClick}
    >
      <CloseIcon />
    </div>
  );
};
