import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as MUISelect } from "@mui/material";
import { SearchInput } from "../SearchInput";
import { Props } from "./Select.types";
import styles from "./Select.module.scss";
import { Checkbox } from "../Checkbox";
import { MenuProps } from "./Select.consts";
import { useSelect } from "./Select.hook";

export const Select = (props: Props) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null);
  const {
    label,
    isError,
    className,
    withSelectAll = true,
    menuProps,
    disabled,
  } = props;
  const {
    searchQuery,
    setSearchQuery,
    selectedOptionList,
    handleSelectOnClose,
    handleChange,
    optionListBySearchQuery,
    toggleSelectAll,
  } = useSelect(props);

  return (
    <FormControl size="small" className={className}>
      <InputLabel className={styles.label}>{label}</InputLabel>
      <MUISelect
        disabled={disabled}
        style={{ height: 32 }}
        error={isError}
        onClose={handleSelectOnClose}
        multiple
        ref={checkboxRef}
        // @ts-ignore
        value={selectedOptionList.map((option) => option?.label ?? "")}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        // @ts-ignore
        renderValue={(selected) => selected.join(", ").slice(0, 100)}
        MenuProps={menuProps ? menuProps : MenuProps}
      >
        <SearchInput
          className={styles.search_input}
          placeholder="Поиск"
          value={searchQuery}
          setValue={setSearchQuery}
        />
        {withSelectAll && (
          <MenuItem className={styles.menu_item}>
            <label className={styles.select_all_label}>
              <Checkbox
                checked={
                  selectedOptionList.length === props.optionList.length &&
                  selectedOptionList.length > 0
                }
                indeterminate={
                  selectedOptionList.length > 0 &&
                  selectedOptionList.length < props.optionList.length
                }
                onChange={toggleSelectAll}
              />
              <div className={styles.menu_item__text}>Выбрать все</div>
            </label>
          </MenuItem>
        )}
        {optionListBySearchQuery.length === 0 && (
          <div className={styles.no_results}>Нет доступных вариантов</div>
        )}
        {optionListBySearchQuery.map((option) => (
          <MenuItem
            key={option.id}
            value={option.label}
            className={styles.menu_item}
          >
            <Checkbox
              checked={
                !!selectedOptionList.find((opt) => opt?.id === option?.id)
              }
            />
            <div className={styles.menu_item__text}>{option.label}</div>
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
