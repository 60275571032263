import * as React from "react";
import styles from "./Header.module.scss";
import { OrganizationPassportBlockMode } from "../../OrganizationPassportBlock.types";
import { useHeader } from "./Header.hook";
import { Props } from "./Header.types";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from "../../../../../../components";
import { UserRole } from "../../../../../../consts";

export const Header = (props: Props) => {
  const { handleOnSave, handleOnEdit, handleOnCancel, isEditButtonDisabled } =
    props;
  const { mode, user } = useHeader();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Паспорт организации</div>
      {mode === OrganizationPassportBlockMode.Viewing &&
        (user.role === UserRole.Admin || user.role === UserRole.Operator) && (
          <Button
            type={ButtonType.Secondary}
            style={ButtonStyle.Contained}
            size={ButtonSize.M}
            onClick={handleOnEdit}
            text="Редактировать"
            disabled={isEditButtonDisabled}
          />
        )}
      {mode === OrganizationPassportBlockMode.Editing && (
        <div className={styles.buttons}>
          <Button
            type={ButtonType.Secondary}
            style={ButtonStyle.Contained}
            size={ButtonSize.M}
            onClick={handleOnCancel}
            text="Отменить"
          />
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Contained}
            size={ButtonSize.M}
            onClick={handleOnSave}
            text="Сохранить"
          />
        </div>
      )}
    </div>
  );
};
