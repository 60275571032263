import React from "react";
import styles from "./CopiedText.module.scss";
import { Props } from "./CopiedText.types";
import { ReactComponent as CopyIcon } from "../../icons/copy.svg";
import { useCopiedText } from "./CopiedText.hook";

export const CopiedText = (props: Props) => {
  const { text } = props;
  const { handleCopyIconOnClick } = useCopiedText(props);

  return (
    <div className={styles.container}>
      <div>{text}</div>
      <div onClick={handleCopyIconOnClick} className={styles.copy_icon}>
        <CopyIcon />
      </div>
    </div>
  );
};
