import { Option } from "../../../../components";

export enum StatusReason {
  LegalEntityInitiative = "По собственной инициативе юридического лица",
  GovernmentRequest = "По требованию (предписанию) гос. органов и (или) иных организаций",
  Other = "Иное",
  ForcedSuspension = "Принудительное приостановление деятельности",
  ViolationsElimination = "Устранение нарушений",
}

export const reasonToCloseOptionList: Option[] = [
  {
    id: "0",
    label: StatusReason.LegalEntityInitiative,
  },
  {
    id: "1",
    label: StatusReason.GovernmentRequest,
  },
  {
    id: "2",
    label: StatusReason.Other,
  },
];

export const reasonToSuspendOptionList: Option[] = [
  {
    id: "0",
    label: StatusReason.ForcedSuspension,
  },
  {
    id: "1",
    label: StatusReason.Other,
  },
];

export const reasonToActiveOptionList: Option[] = [
  {
    id: "0",
    label: StatusReason.ViolationsElimination,
  },
  {
    id: "1",
    label: StatusReason.Other,
  },
];
