import { InitialOrganizationByUNP } from "./integration.types";
import { convertArrayToSentence, fetchWrapper } from "../utils";

export const getOrganizationByUNP = async (
  UNP: string,
): Promise<InitialOrganizationByUNP | undefined> => {
  const response = await fetchWrapper(
    `${process.env.REACT_APP_API_URL}/bel/jurname/${UNP}`,
    "GET",
  );
  const json = response?.data;

  if (typeof json === "undefined" || json.is_request_failed) {
    return undefined;
  } else {
    if (json.org === null) {
      return undefined;
    } else {
      return {
        fullName: String(json.is_request_failed ? "" : json.org.vnaim),
        shortName: String(json.is_request_failed ? "" : json.org.vn),
        registrationDate: json.is_request_failed
          ? undefined
          : new Date(json.org.dfrom),
        postcode: String(
          json.is_request_failed ? "" : (json.addresses[0]?.nindex ?? ""),
        ),
        COATO: String(
          json.is_request_failed
            ? ""
            : (json.addresses[0]?.nsi00202?.nksoato ?? ""),
        ),
        addressP1: `${convertArrayToSentence([json.addresses[0]?.vregion, json.addresses[0]?.vdistrict, json.addresses[0]?.nsi00239?.vntnpk])} ${json.addresses[0]?.vnp}`,
        addressP2: convertArrayToSentence([
          json.addresses[0]?.nsi00226?.vntulk,
          json.addresses[0]?.vulitsa,
          json.addresses[0]?.vdom,
          json.addresses[0]?.vkorp,
          json.addresses[0]?.vpom,
        ]),
        internetAddress: String(json.addresses[0]?.vsite ?? ""),
        emailAddress: String(json.addresses[0]?.vemail ?? ""),
        phone: String(json.addresses[0]?.vtels ?? ""),
        fax: String(json.addresses[0]?.vfax ?? ""),
      };
    }
  }
};
