import styles from "./CustomTable.module.scss";
import tableStyles from "../../../../styles/Table.module.scss";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import {
  Checkbox,
  Loader,
  SortableTableCell,
  Status,
  StatusType,
  TablePaginationActions,
} from "../../../../components";
import Tooltip from "@mui/material/Tooltip";
import cn from "classnames";
import { ColumnName } from "./CustomTable.consts";
import { StatusTooltipContent } from "./Features";
import { formatDateToClassicView } from "../../../../utils";
import { OrganizationNameTooltipContent } from "./Features/OrganizationNameTooltipContent";
import { DataNotLoadedStub, NothingFoundStub } from "../../../../features";
import { useHealthcareOrganizationsRegister } from "../../HealthcareOrganizationsRegister.hook";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../consts";

export const CustomTable = (
  props: ReturnType<typeof useHealthcareOrganizationsRegister>,
) => {
  if (props.isLoading) {
    return (
      <div className={styles.stub}>
        <Loader height={64} width={64} />
      </div>
    );
  }

  if (props.isFetchListError) {
    return (
      <div className={styles.stub}>
        <DataNotLoadedStub />
      </div>
    );
  }

  if (
    props.rows.length === 0 ||
    !props.isShowGrid ||
    typeof props.user.role === "undefined"
  ) {
    return (
      <div className={styles.stub}>
        <NothingFoundStub
          isWithAdvice={typeof props.user.role !== "undefined"}
        />
      </div>
    );
  }

  return (
    <div className={cn(tableStyles.container, styles.container)}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={tableStyles.head}>
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              {[
                ColumnName.OrganizationName,
                ColumnName.Status,
                ColumnName.OrganizationType,
                ColumnName.Actualization,
              ].map((name) => (
                <SortableTableCell
                  key={name}
                  name={name}
                  onClick={props.handSortableTableCellOnClick(name.toString())}
                  sortDirection={props.sortDirection}
                  isSortable={props.sortedColumnName === name}
                  className={tableStyles.head__cell}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow
                key={row.id}
                className={tableStyles.table_row}
                onClick={props.handleRowOnClick(row.id)}
              >
                <TableCell className={styles.checkbox}>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </TableCell>
                <TableCell className={tableStyles.body_cell}>
                  <Tooltip
                    title={
                      <OrganizationNameTooltipContent
                        {...row.healthcareOrganizationContent}
                      />
                    }
                    arrow
                  >
                    <div className={styles.organization_name_wrapper}>
                      {row.healthcareOrganizationContent
                        .legalEntityShortName ? (
                        <>
                          <div className={styles.organization_name}>
                            <div
                              className={styles.organization_name__legal_entity}
                            >
                              {
                                row.healthcareOrganizationContent
                                  .legalEntityShortName
                              }
                              <span>&nbsp;&nbsp;</span>
                            </div>
                            <div>
                              /<span>&nbsp;&nbsp;</span>
                              {row.healthcareOrganizationContent.shortName}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={styles.organization_name}>
                          {row.healthcareOrganizationContent.shortName}
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.cardStatusContent.status === StatusType.Active ? (
                    <Status statusType={row.cardStatusContent.status} />
                  ) : (
                    <Tooltip
                      title={
                        <StatusTooltipContent {...row.cardStatusContent} />
                      }
                      arrow
                    >
                      <Status statusType={row.cardStatusContent.status} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell className={tableStyles.body_cell}>
                  {row.organizationType}
                </TableCell>
                <TableCell className={tableStyles.body_cell}>
                  {row.actualization
                    ? formatDateToClassicView(row.actualization)
                    : "—"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <div className={tableStyles.all_rows_counter}>
                Результатов: {props.rows.length}
              </div>
              <TablePagination
                className={tableStyles.pagination}
                rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
                colSpan={3}
                count={props.rowsTotalCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                labelRowsPerPage="Показывать по"
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={props.handleChangePage}
                onRowsPerPageChange={props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
