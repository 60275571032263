export type Method = "GET" | "POST" | "PUT" | "DELETE";

export enum Tag {
  DictionaryItem = "dictionary_item",
}

export enum Regime {
  Data = "data",
}

export type Error = {
  code: 401 | 403 | 404;
};
