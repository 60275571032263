import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBranchesList } from "../../../../api";
import { Branch } from "../../OrganizationCard.types";
import * as React from "react";
import { useSortableTable, useURLNavigate } from "../../../../hooks";
import { createSortInfo } from "../../../HealthcareOrganizationsRegister/HealthcareOrganizationsRegister.utils";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../consts";
import { useAppSelector } from "../../../../store/hooks";

export const useBranches = () => {
  const [branchList, setBranchList] = useState<Branch[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
  );
  const { sortedColumnName, sortDirection, handSortableTableCellOnClick } =
    useSortableTable();
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetchListError, setIsFetchListError] = useState<boolean>(false);
  const [branchesListTotalCount, setBranchesListTotalCount] =
    useState<number>(0);
  const { goToOrganizationCardPage } = useURLNavigate();
  const dictionaries = useAppSelector((state) => state.dictionaries);

  const fetchBranchList = async () => {
    if (params.organizationId && dictionaries.gridIdDictionary) {
      try {
        setIsLoading(true);
        const response = await getBranchesList(
          {
            organizationId: params.organizationId,
            page,
            count: rowsPerPage,
            sortInfo: createSortInfo(sortDirection, sortedColumnName),
          },
          dictionaries.gridIdDictionary,
        );
        setBranchList(response.branchList);
        setBranchesListTotalCount(response.count);
      } catch (ex) {
        setIsFetchListError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchBranchList();
  }, [
    page,
    rowsPerPage,
    sortDirection,
    sortedColumnName,
    dictionaries.gridIdDictionary,
  ]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowOnClick = (rowId: string) => () => {
    goToOrganizationCardPage(rowId);
    window.location.reload();
  };

  return {
    branchList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRowOnClick,
    handSortableTableCellOnClick,
    sortedColumnName,
    sortDirection,
    isLoading,
    isFetchListError,
    branchesListTotalCount,
  };
};
