import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  getFieldIdDictionaryByURI,
  getFilterOptionList,
  getFilterOptionWithParentList,
} from "./api";
import {
  ADD_API_ERROR_EVENT_NAME,
  FieldURI,
  INIT_USER_EVENT_NAME,
} from "./consts";
import {
  setAllOrganizationsOptionList,
  setGridIdDictionary,
  setHealthcareStructureOptionList,
  setMedicalCareConditionsOptionList,
  setMedicalCareLevelOptionList,
  setMedicalCareTypeOptionList,
  setOrganizationTypeOptionList,
  setOwnershipTypeOptionList,
  setParentOrganizationList,
  setStructuralUnitsTypesOptionList,
  setSubordinationLevelOptionList,
} from "./store/slices/dictionaries-slice";
import { useCallback, useEffect, useState } from "react";
import { useInitUser } from "./hooks";
import { getToken, isTokenEmpty, logout } from "./App.utils";
import { Error } from "./types";

export const useApp = () => {
  const dispatch = useAppDispatch();
  const { initUser } = useInitUser();
  const user = useAppSelector((state) => state.user);
  const [apiError, setApiError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    initUser();
  }, []);

  useEffect(() => {
    document.addEventListener(INIT_USER_EVENT_NAME, initUser);

    return () => {
      document.removeEventListener(INIT_USER_EVENT_NAME, initUser);
    };
  }, [initUser]);

  const getDictionaryByURI = async (
    uri: FieldURI,
    isOrganizationList?: boolean,
    userOrganizationId?: string,
  ) => {
    const idDictionary = await getFieldIdDictionaryByURI(uri);
    if (!idDictionary) return [];

    return await getFilterOptionList(
      idDictionary,
      isOrganizationList,
      userOrganizationId,
    );
  };

  const initParentOrganizationDictionary = async () => {
    dispatch(
      setParentOrganizationList(
        await getDictionaryByURI(FieldURI.ParentOrganization),
      ),
    );
  };

  const initHealthcareStructureDictionary = async () => {
    dispatch(
      setHealthcareStructureOptionList(
        await getDictionaryByURI(FieldURI.HealthcareStructure),
      ),
    );
  };

  const initOwnershipTypeDictionary = async () => {
    const idDictionary = await getFieldIdDictionaryByURI(
      FieldURI.OwnershipType,
    );
    if (!idDictionary) return [];

    dispatch(
      setOwnershipTypeOptionList(
        await getFilterOptionWithParentList(idDictionary),
      ),
    );
  };

  const initOrganizationTypeDictionary = async () => {
    const idDictionary = await getFieldIdDictionaryByURI(
      FieldURI.OrganizationType,
    );
    if (!idDictionary) return;

    dispatch(
      setOrganizationTypeOptionList(
        await getFilterOptionWithParentList(idDictionary),
      ),
    );
  };

  const initMedicalCareLevelDictionary = async () => {
    dispatch(
      setMedicalCareLevelOptionList(
        await getDictionaryByURI(FieldURI.MedicalCareLevel),
      ),
    );
  };

  const initMedicalCareTypeDictionary = async () => {
    dispatch(
      setMedicalCareTypeOptionList(
        await getDictionaryByURI(FieldURI.MedicalCareType),
      ),
    );
  };

  const initMedicalCareConditionsDictionary = async () => {
    dispatch(
      setMedicalCareConditionsOptionList(
        await getDictionaryByURI(FieldURI.MedicalCareConditions),
      ),
    );
  };

  const initSubordinationLevelDictionary = async () => {
    dispatch(
      setSubordinationLevelOptionList(
        await getDictionaryByURI(FieldURI.SubordinationLevel),
      ),
    );
  };

  const initAllOrganizationsDictionary = async () => {
    if (typeof user.theirOrganizationId === "undefined") return;

    dispatch(
      setAllOrganizationsOptionList(
        await getDictionaryByURI(FieldURI.Grid, true, user.theirOrganizationId),
      ),
    );
  };

  const initGridIdDictionary = async () => {
    const idDictionary = await getFieldIdDictionaryByURI(FieldURI.Grid);
    if (!idDictionary) return;

    dispatch(setGridIdDictionary(idDictionary));
  };

  const iniStructuralUnitsDictionary = async () => {
    dispatch(
      setStructuralUnitsTypesOptionList(
        await getDictionaryByURI(FieldURI.TypeOfStructuralUnit, false),
      ),
    );
  };

  const initDictionaries = () => {
    initParentOrganizationDictionary();
    initHealthcareStructureDictionary();
    initOwnershipTypeDictionary();
    initOrganizationTypeDictionary();
    initMedicalCareLevelDictionary();
    initMedicalCareTypeDictionary();
    initMedicalCareConditionsDictionary();
    initSubordinationLevelDictionary();
    iniStructuralUnitsDictionary();
    initGridIdDictionary();
  };

  useEffect(() => {
    if (!isTokenEmpty(getToken())) {
      initAllOrganizationsDictionary();
    }
  }, [user.theirOrganizationId]);

  useEffect(() => {
    if (!isTokenEmpty(getToken())) {
      initDictionaries();
    }
  }, []);

  useEffect(() => {
    document.addEventListener(INIT_USER_EVENT_NAME, initDictionaries);

    return () => {
      document.removeEventListener(INIT_USER_EVENT_NAME, initDictionaries);
    };
  }, [initDictionaries]);

  const addApiError = useCallback(
    (e: any) => {
      if (typeof apiError === "undefined") {
        setApiError({
          code: e.detail.code,
        });
      }
    },
    [apiError, setApiError],
  );

  useEffect(() => {
    document.addEventListener(ADD_API_ERROR_EVENT_NAME, addApiError);

    return () => {
      document.removeEventListener(ADD_API_ERROR_EVENT_NAME, addApiError);
    };
  }, [addApiError]);

  useEffect(() => {
    if (typeof apiError === "undefined") return;

    switch (apiError.code) {
      case 401:
        alert("Для выполнения операции необходима авторизация");
        logout();
        break;
      case 403:
        alert("Для выполнения операции недостаточно полномочий");
        break;
      case 404:
        alert("Запрошенные данные отсутствуют");
        break;
    }
    setApiError(undefined);
  }, [apiError?.code]);
};
