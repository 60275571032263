import { TreeViewBaseItem, TreeViewItemId } from "@mui/x-tree-view";
import { Organization, PreparedStructuralUnit } from "./StructuralUnits.types";

export const formatToObject = (
  val: Organization,
  highLevelOrganizationName?: string,
) => {
  return {
    ignore: val[0],
    code: val[1],
    name: val[2],
    shortName: val[3],
    registrationDate: val[4],
    actualization: val[5],
    partOf: val[6],
    type: val[10],
    telephone: val[8],
    adress_p1: val[9],
    helthCareLevel: val[10],
    mainOrganization: highLevelOrganizationName,
    typeSd: val[11],
    adress_p2: val[12],
    children: [] as PreparedStructuralUnit[],
  };
};

export function buildHierarchy(
  data: PreparedStructuralUnit[],
  root?: any,
): PreparedStructuralUnit[] {
  const map: Record<string, PreparedStructuralUnit> = {};
  data?.forEach((item) => {
    map[item.name] = { ...item, children: [] };
  });

  const hierarchy: PreparedStructuralUnit[] = [];
  data?.forEach((item) => {
    map[item.name] = {
      code: item.code,
      name: item.name,
      partOf: item.partOf,
      ignore: item.ignore || "",
      shortName: item.shortName || null,
      registrationDate: item.registrationDate || "",
      actualization: item.actualization || "",
      type: item.type || "",
      telephone: item.telephone || "",
      adress_p1: item.adress_p1 || "",
      adress_p2: item.adress_p2 || "",
      helthCareLevel: item.helthCareLevel || null,
      children: [],
      typeSd: item.typeSd,
      mainOrganization: item.mainOrganization || undefined,
    };
  });

  data?.forEach((item) => {
    if (item.partOf && map[item.partOf] && item.partOf !== item.name) {
      map[item.partOf].children.push(map[item.name]);
    } else {
      hierarchy.push(map[item.name]);
    }
  });

  Object.keys(map).forEach((key) => {
    if (map[key].partOf === null && !hierarchy.includes(map[key])) {
      hierarchy.push(map[key]);
    }
  });
  return hierarchy;
}

export const transformToTreeViewBaseItems = (
  units: PreparedStructuralUnit[],
): TreeViewBaseItem[] => {
  return units.map((unit) => ({
    id: unit.code,
    label: unit.name,
    children: unit.children ? transformToTreeViewBaseItems(unit.children) : [], // Рекурсивно преобразуем детей
  }));
};

export const collectIdsFromHierarcy = <R extends { id: TreeViewItemId }>(
  items: TreeViewBaseItem<R>[],
): TreeViewItemId[] => {
  let ids: TreeViewItemId[] = [];

  items.forEach((item) => {
    ids.push(item.id);
    if (item.children) {
      ids = ids.concat(collectIdsFromHierarcy(item.children));
    }
  });

  return ids;
};
