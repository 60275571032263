import React from "react";
import styles from "./HyperLink.module.scss";
import { HyperLinkType, Props } from "./HyperLink.types";

export const HyperLink = (props: Props) => {
  const { link, linkType, clickHandler } = props;

  if (linkType === HyperLinkType.ClickHandlerInsteadHref) {
    return (
      <div className={styles.container} onClick={clickHandler}>
        {link}
      </div>
    );
  }

  return (
    <a
      className={styles.container}
      href={linkType === HyperLinkType.Mail ? `mailto:${link}` : link}
      target="_blank"
    >
      {link}
    </a>
  );
};
