import styles from "./DetailsBlock.module.scss";
import { Props } from "./DetailsBlock.types";
import { ControlType } from "../../types";
import {
  CopiedText,
  DatePicker,
  HyperLink,
  Input,
  Select,
  TreeSelect,
} from "../index";
import React from "react";
import moment from "moment/moment";
import cn from "classnames";

export const DetailsBlock = (props: Props) => {
  const { title, fieldList, isEditing } = props;

  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.field_list}>
        {fieldList
          .filter(
            (field) =>
              typeof field.isVisible === "undefined" || field.isVisible,
          )
          .map((field) => (
            <div key={field.name} className={styles.field_list__field}>
              <div
                className={cn(styles.field_list__field__name, {
                  [styles.field_list__field__name__is_datepicker]:
                    isEditing && field.controlType === ControlType.DatePicker,
                  [styles.field_list__field__name__is_select]:
                    isEditing &&
                    (field.controlType === ControlType.Select ||
                      field.controlType === ControlType.TreeSelect),
                  [styles.field_list__field__name__is_viewing]: !isEditing,
                })}
              >
                {field.name}
                {isEditing && field.isRequired ? "*" : ""}
              </div>
              <div className={styles.field_list__field__content}>
                {isEditing ? (
                  <>
                    {field.controlType === ControlType.Input && (
                      <Input
                        value={field.value}
                        setValue={field.setValue}
                        className={styles.field_list__field__content__input}
                        disabled={field.isDisabled}
                        errorText={field.errorText}
                        size="m"
                      />
                    )}
                    {field.controlType === ControlType.Select && (
                      <Select
                        withSelectAll={field.withSelectAll}
                        isMultiple={field.isMultiple}
                        label={field.label}
                        optionList={field.optionList}
                        selectedOptionList={field.selectedOptionList}
                        setSelectedOptionList={field.setSelectedOptionList}
                        isError={field.isError}
                      />
                    )}
                    {field.controlType === ControlType.TreeSelect && (
                      <TreeSelect {...field} />
                    )}
                    {field.controlType === ControlType.DatePicker && (
                      <DatePicker
                        value={field.value}
                        setValue={field.setValue}
                        isDateAndTimePicker={field.isDateAndTimePicker}
                        isDisabled={field.isDisabled}
                        isError={field.isError}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {field.controlType === ControlType.Input && (
                      <>
                        {field.isCopiedText ? (
                          <CopiedText text={field.value} />
                        ) : (
                          <>
                            {typeof field.linkType !== "undefined" ? (
                              <HyperLink
                                clickHandler={field.clickHandler}
                                link={field.value}
                                linkType={field.linkType}
                              />
                            ) : field.value ? (
                              field.value
                            ) : (
                              "—"
                            )}
                          </>
                        )}
                      </>
                    )}
                    {field.controlType === ControlType.Select && (
                      <>
                        {typeof field.linkType !== "undefined" ? (
                          <HyperLink
                            clickHandler={field.clickHandler}
                            link={field.selectedOptionList[0]?.label ?? "—"}
                            linkType={field.linkType}
                          />
                        ) : (
                          (field.selectedOptionList[0]?.label ?? "—")
                        )}
                      </>
                    )}
                    {field.controlType === ControlType.TreeSelect && (
                      <>
                        {field.selectedOptionList
                          .map((opt) => opt.label)
                          .join(", ")}
                      </>
                    )}
                    {field.controlType === ControlType.DatePicker && (
                      <>
                        {field.value
                          ? moment(field.value).format(
                              `DD.MM.YYYY${field.isDateAndTimePicker ? " HH:mm" : ""}`,
                            )
                          : "—"}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
