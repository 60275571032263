import { Props } from "./AllFiltersModal.types";
import { useEffect, useMemo, useState } from "react";
import { Option } from "../../../../../../components";

export const useAllFiltersModal = (props: Props) => {
  const [ownershipTypeFilter, setOwnershipTypeFilter] = useState<Option[]>(
    props.ownershipTypeFilterDefault,
  );
  const [healthcareStructureFilter, setHealthcareStructureFilter] = useState<
    Option[]
  >(props.healthcareStructureFilterDefault);
  const [
    healthcareOrganizationTypeFilter,
    setHealthcareOrganizationTypeFilter,
  ] = useState<Option[]>(props.healthcareOrganizationTypeFilterDefault);
  const [medicalCareLevelFilter, setMedicalCareLevelFilter] = useState<
    Option[]
  >(props.medicalCareLevelFilterDefault);
  const [medicalCareConditionsFilter, setMedicalCareConditionsFilter] =
    useState<Option[]>(props.medicalCareConditionsFilterDefault);
  const [medicalCareTypeFilter, setMedicalCareTypeFilter] = useState<Option[]>(
    props.medicalCareTypeFilterDefault,
  );
  const [subordinationLevelFilter, setSubordinationLevelFilter] = useState<
    Option[]
  >(props.subordinationLevelFilterDefault);
  const [parentOrganizationFilter, setParentOrganizationFilter] = useState<
    Option[]
  >(props.parentOrganizationFilterDefault);
  const [isShowWithBranches, setIsShowWithBranches] = useState<boolean>(
    props.isShowWithBranches,
  );
  const [headquartersFilter, setHeadquartersFilter] = useState<Option[]>(
    props.headquartersFilterDefault,
  );

  useEffect(() => {
    if (!isShowWithBranches) {
      setHeadquartersFilter([]);
    }
  }, [isShowWithBranches]);

  const handleOnClose = () => {
    props.setIsOpened(false);
    handleClearAllFilters();
  };

  const handleClearAllFilters = () => {
    setIsShowWithBranches(false);
    setHeadquartersFilter([]);
    setOwnershipTypeFilter([]);
    setHealthcareStructureFilter([]);
    setHealthcareOrganizationTypeFilter([]);
    setMedicalCareLevelFilter([]);
    setMedicalCareConditionsFilter([]);
    setMedicalCareTypeFilter([]);
    setSubordinationLevelFilter([]);
    setParentOrganizationFilter([]);
  };

  const isClearAllFiltersButtonDisabled = useMemo(() => {
    return !(
      isShowWithBranches ||
      headquartersFilter.length ||
      ownershipTypeFilter.length ||
      healthcareStructureFilter.length ||
      healthcareOrganizationTypeFilter.length ||
      medicalCareLevelFilter.length ||
      medicalCareConditionsFilter.length ||
      medicalCareTypeFilter.length ||
      subordinationLevelFilter.length ||
      parentOrganizationFilter.length
    );
  }, [
    isShowWithBranches,
    headquartersFilter,
    ownershipTypeFilter,
    healthcareStructureFilter,
    healthcareOrganizationTypeFilter,
    medicalCareLevelFilter,
    medicalCareConditionsFilter,
    medicalCareTypeFilter,
    subordinationLevelFilter,
    parentOrganizationFilter,
  ]);

  const handleOnSave = () => {
    props.onSave(
      isShowWithBranches,
      headquartersFilter,
      ownershipTypeFilter,
      healthcareStructureFilter,
      healthcareOrganizationTypeFilter,
      medicalCareLevelFilter,
      medicalCareConditionsFilter,
      medicalCareTypeFilter,
      subordinationLevelFilter,
      parentOrganizationFilter,
    );
    handleOnClose();
  };

  return {
    handleOnClose,
    isClearAllFiltersButtonDisabled,
    handleOnSave,
    handleClearAllFilters,
    ownershipTypeFilter,
    setOwnershipTypeFilter,
    healthcareStructureFilter,
    setHealthcareStructureFilter,
    healthcareOrganizationTypeFilter,
    setHealthcareOrganizationTypeFilter,
    medicalCareLevelFilter,
    setMedicalCareLevelFilter,
    medicalCareConditionsFilter,
    setMedicalCareConditionsFilter,
    medicalCareTypeFilter,
    setMedicalCareTypeFilter,
    subordinationLevelFilter,
    setSubordinationLevelFilter,
    parentOrganizationFilter,
    setParentOrganizationFilter,
    isShowWithBranches,
    setIsShowWithBranches,
    headquartersFilter,
    setHeadquartersFilter,
  };
};
