import styles from "./HealthcareOrganizationsRegister.module.scss";
import { Filters, CustomTable, CreateOrganizationModal } from "./Features";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "../../components";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import React from "react";
import { useHealthcareOrganizationsRegister } from "./HealthcareOrganizationsRegister.hook";
import { UserRole } from "../../consts";

export const HealthcareOrganizationsRegister = () => {
  const hook = useHealthcareOrganizationsRegister();

  return (
    <>
      <div className={styles.header}>
        <div className={styles.header__title_and_desctiption_block}>
          <div className={styles.header__title_and_desctiption_block__title}>
            Регистр организаций здравоохранения
          </div>
          <div
            className={styles.header__title_and_desctiption_block__description}
          >
            Используйте фильтры для отображения необходимого списка организаций
            здравоохранения
          </div>
        </div>
        {hook.user.role === UserRole.Admin && (
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={hook.handleCreateOrganizationButtonOnClick}
            icon={<PlusIcon />}
            text="Создать организацию"
          />
        )}
      </div>
      <Filters {...hook} />
      <CustomTable {...hook} />

      {hook.isCreateOrganizationModalOpen && (
        <CreateOrganizationModal
          handleOnClose={hook.handleCreateOrganizationButtonOnClose}
        />
      )}
    </>
  );
};
