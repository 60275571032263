import { SxProps, Theme } from "@mui/material";

export const checkBoxSX: SxProps<Theme> = {
  borderColor: "rgb(235, 235, 235)",
  "&.Mui-checked": {
    color: "rgb(38, 165, 154)",
  },
  "&.MuiCheckbox-indeterminate ": {
    color: "rgb(38, 165, 154)",
  },
};
