import { Props } from "./CopiedText.types";

export const useCopiedText = (props: Props) => {
  const { text } = props;

  const handleCopyIconOnClick = () => {
    navigator.clipboard.writeText(text);
  };

  return { handleCopyIconOnClick };
};
