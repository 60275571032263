import { ChangeEvent } from "react";
import { InputProps } from "@mui/base/Input";
import { Props } from "./Input.types";

export const useInput = (props: InputProps & Props) => {
  const { onEnter } = props;

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.setValue(event.target.value);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter?.();
    }
  };

  return { handleOnChange, handleOnKeyDown };
};
