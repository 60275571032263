import * as React from "react";
import { useEffect, useState } from "react";
import { Option } from "../../components";
import { useSortableTable, useURLNavigate } from "../../hooks";
import { Row } from "./Features/CustomTable/CustomTable.types";
import { getHealthcareOrganizationList } from "../../api";
import {
  createSortInfo,
  mapOptionListToLabelMap,
} from "./HealthcareOrganizationsRegister.utils";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, UserRole } from "../../consts";
import { useAppSelector } from "../../store/hooks";

export const useHealthcareOrganizationsRegister = () => {
  const [isAllFilersModalOpen, setIsAllFilersModalOpen] =
    useState<boolean>(false);
  const [searchByNameQuery, setSearchByNameQuery] = useState<string>("");
  const [areModalFiltersApplied, setAreModalFiltersApplied] =
    useState<boolean>(false);
  const [healthcareStructureFilter, setHealthcareStructureFilter] = useState<
    Option[]
  >([]);
  const [parentOrganizationFilter, setParentOrganizationFilter] = useState<
    Option[]
  >([]);
  const [ownershipTypeFilter, setOwnershipTypeFilter] = useState<Option[]>([]);
  const [
    healthcareOrganizationTypeFilter,
    setHealthcareOrganizationTypeFilter,
  ] = useState<Option[]>([]);
  const [medicalCareLevelFilter, setMedicalCareLevelFilter] = useState<
    Option[]
  >([]);
  const [medicalCareConditionsFilter, setMedicalCareConditionsFilter] =
    useState<Option[]>([]);
  const [medicalCareTypeFilter, setMedicalCareTypeFilter] = useState<Option[]>(
    [],
  );
  const [subordinationLevelFilter, setSubordinationLevelFilter] = useState<
    Option[]
  >([]);
  const [isShowWithBranchesFilter, setIsShowWithBranchesFilter] =
    useState<boolean>(false);
  const [headquartersFilter, setHeadquartersFilter] = useState<Option[]>([]);
  const { goToOrganizationCardPage } = useURLNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
  );
  const { sortedColumnName, sortDirection, handSortableTableCellOnClick } =
    useSortableTable();
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetchListError, setIsFetchListError] = useState<boolean>(false);
  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] =
    useState<boolean>(false);
  const [rowsTotalCount, setRowsTotalCount] = useState<number>(0);
  const dictionaries = useAppSelector((state) => state.dictionaries);
  const user = useAppSelector((state) => state.user);
  const [isShowGrid, setIsShowGrid] = useState<boolean>(false);

  useEffect(() => {
    setIsShowGrid(user.role !== UserRole.Consumer);
  }, [user.role]);

  const handleHealthcareStructureFilterOnChange = (optionList: Option[]) => {
    setHealthcareStructureFilter(optionList);
  };

  const handleParentOrganizationFilterOnChange = (optionList: Option[]) => {
    setParentOrganizationFilter(optionList);
  };

  const handleAllFiltersButtonOnClick = () => {
    setIsAllFilersModalOpen(true);
  };

  const handleResetAll = () => {
    setSearchByNameQuery("");
    setHeadquartersFilter([]);
    setHealthcareStructureFilter([]);
    setParentOrganizationFilter([]);
    setOwnershipTypeFilter([]);
    setHealthcareOrganizationTypeFilter([]);
    setMedicalCareLevelFilter([]);
    setMedicalCareConditionsFilter([]);
    setMedicalCareTypeFilter([]);
    setSubordinationLevelFilter([]);
    setIsShowWithBranchesFilter(false);
  };

  const handlePrintOnClick = () => {};

  const handleExportOnClick = () => {};

  useEffect(() => {
    setAreModalFiltersApplied(
      isShowWithBranchesFilter ||
        headquartersFilter.length > 0 ||
        parentOrganizationFilter.length > 0 ||
        healthcareStructureFilter.length > 0 ||
        ownershipTypeFilter.length > 0 ||
        healthcareOrganizationTypeFilter.length > 0 ||
        medicalCareLevelFilter.length > 0 ||
        medicalCareConditionsFilter.length > 0 ||
        medicalCareTypeFilter.length > 0 ||
        subordinationLevelFilter.length > 0,
    );
  }, [
    isShowWithBranchesFilter,
    headquartersFilter,
    parentOrganizationFilter,
    healthcareStructureFilter,
    ownershipTypeFilter,
    healthcareOrganizationTypeFilter,
    medicalCareLevelFilter,
    medicalCareConditionsFilter,
    medicalCareTypeFilter,
    subordinationLevelFilter,
  ]);

  const handleAllFiltersModalOnSave = (
    isShowWithBranchesFilter: boolean,
    headquartersFilter: Option[],
    ownershipTypeFilter: Option[],
    healthcareStructureFilter: Option[],
    healthcareOrganizationTypeFilter: Option[],
    medicalCareLevelFilter: Option[],
    medicalCareConditionsFilter: Option[],
    medicalCareTypeFilter: Option[],
    subordinationLevelFilter: Option[],
    parentOrganizationFilter: Option[],
  ) => {
    setIsShowWithBranchesFilter(isShowWithBranchesFilter);
    setHeadquartersFilter(headquartersFilter);
    setOwnershipTypeFilter(ownershipTypeFilter);
    setHealthcareStructureFilter(healthcareStructureFilter);
    setHealthcareOrganizationTypeFilter(healthcareOrganizationTypeFilter);
    setMedicalCareLevelFilter(medicalCareLevelFilter);
    setMedicalCareConditionsFilter(medicalCareConditionsFilter);
    setMedicalCareTypeFilter(medicalCareTypeFilter);
    setSubordinationLevelFilter(subordinationLevelFilter);
    setParentOrganizationFilter(parentOrganizationFilter);
  };

  const handleStartSearch = () => {
    fetchList();
  };

  const handleSearchByNameInputOnEnter = () => {
    handleStartSearch();
  };

  const fetchList = async () => {
    if (
      !dictionaries.gridIdDictionary ||
      typeof user.theirOrganizationId === "undefined"
    )
      return;

    if (typeof user.role === "undefined") {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      const response = await getHealthcareOrganizationList(
        {
          page,
          count: rowsPerPage,
          sortInfo: createSortInfo(sortDirection, sortedColumnName),
          searchString: searchByNameQuery,
          parentOrganizationNameList: mapOptionListToLabelMap(
            parentOrganizationFilter,
          ),
          healthcareStructureNameList: mapOptionListToLabelMap(
            healthcareStructureFilter,
          ),
          ownershipTypeNameList: mapOptionListToLabelMap(ownershipTypeFilter),
          subordinationLevelNameList: mapOptionListToLabelMap(
            subordinationLevelFilter,
          ),
          healthcareOrganizationTypeNameList: mapOptionListToLabelMap(
            healthcareOrganizationTypeFilter,
          ),
          medicalCareLevelNameList: mapOptionListToLabelMap(
            medicalCareLevelFilter,
          ),
          medicalCareConditionsNameList: mapOptionListToLabelMap(
            medicalCareConditionsFilter,
          ),
          medicalCareTypeNameList: mapOptionListToLabelMap(
            medicalCareTypeFilter,
          ),
          isShowWithBranches: isShowWithBranchesFilter,
          parentOrg: headquartersFilter[0]?.id,
          userOrganizationId: user.theirOrganizationId,
        },
        dictionaries.gridIdDictionary,
      );
      setRows(response.list);
      setRowsTotalCount(response.count);
    } catch (ex) {
      setIsFetchListError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, [
    page,
    rowsPerPage,
    sortDirection,
    sortedColumnName,
    dictionaries.gridIdDictionary,
    user.theirOrganizationId,
    user.role,
  ]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowOnClick = (rowId: string) => () => {
    goToOrganizationCardPage(rowId);
  };

  const handleCreateOrganizationButtonOnClick = () => {
    setIsCreateOrganizationModalOpen(true);
  };

  const handleCreateOrganizationButtonOnClose = () => {
    setIsCreateOrganizationModalOpen(false);
  };

  return {
    isAllFilersModalOpen,
    setIsAllFilersModalOpen,
    searchByNameQuery,
    setSearchByNameQuery,
    areModalFiltersApplied,
    healthcareStructureFilter,
    handleHealthcareStructureFilterOnChange,
    parentOrganizationFilter,
    handleParentOrganizationFilterOnChange,
    handleResetAll,
    handleAllFiltersButtonOnClick,
    handlePrintOnClick,
    handleExportOnClick,
    handleAllFiltersModalOnSave,
    ownershipTypeFilter,
    healthcareOrganizationTypeFilter,
    medicalCareLevelFilter,
    medicalCareConditionsFilter,
    medicalCareTypeFilter,
    subordinationLevelFilter,
    handleStartSearch,
    handleSearchByNameInputOnEnter,
    isShowWithBranchesFilter,
    headquartersFilter,
    handleRowOnClick,
    rows,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handSortableTableCellOnClick,
    sortDirection,
    sortedColumnName,
    isLoading,
    isFetchListError,
    handleCreateOrganizationButtonOnClick,
    isCreateOrganizationModalOpen,
    handleCreateOrganizationButtonOnClose,
    rowsTotalCount,
    user,
    isShowGrid,
  };
};
