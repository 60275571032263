import { Pagination } from "./pagination";
import { SortDirection } from "./sort";

export type SortInfo = {
  fieldGuid: HealthcareOrganizationFieldGuid;
  sortDirection: SortDirection;
};

export type HealthcareOrganizationListFilter = {
  searchString: string;
  sortInfo?: SortInfo;
  parentOrganizationNameList?: string[];
  healthcareStructureNameList?: string[];
  ownershipTypeNameList?: string[];
  subordinationLevelNameList?: string[];
  healthcareOrganizationTypeNameList?: string[];
  medicalCareLevelNameList?: string[];
  medicalCareConditionsNameList?: string[];
  medicalCareTypeNameList?: string[];
  isShowWithBranches?: boolean;
  parentOrg?: string;
  headquartersName?: string;
  code?: string;
  userOrganizationId?: string;
} & Pagination;

export enum HealthcareOrganizationFieldGuid {
  Code = "code",
  ManagingOrg = "managing_org",
  HealthcareStructure = "structure_healthcare",
  OwnershipForm = "form_ownership",
  SubordinationLevel = "subordination_level",
  PartOf = "partOf",
  Name = "display",
  ShortName = "short_name",
  Status = "status",
  Type = "org_type",
  Actualization = "last_updated",
  MedicalCareLevel = "healthcare_level",
  MedicalCareConditions = "termsOfService",
  MedicalCareType = "typeOfHealthCare",
  OKPO = "okpo",
  RegistrationDate = "reg_date",
  RegisterRegistrationDate = "add_date",
  Postcode = "addr_index",
  COATOCode = "addr_soato",
  InternetAddress = "www_addr",
  EmailAddress = "email",
  Phone = "telephone",
  Fax = "fax",
  HierarchyLevel = "hierarchy_level",
  UNP = "unp",
  StatusDate = "status_date",
  StatusReason = "status_reason",
  StatusBasis = "status_basis",
  ParentOrg = "parent_org",
  OrgId = "orgid",
  Address_p1 = "address_p1",
  Address_p2 = "address_p2",
  TypeSd = "type_sd",
  Parent = "parent",
  IDDictionaryItem = "id_dictionary_item",
  IDSDictionaryItem = "dictionary_item_ids",
}

export type BranchListListFilter = {
  organizationId: string;
  sortInfo?: SortInfo;
} & Pagination;
