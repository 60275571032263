import { useMemo, useState } from "react";
import { Option, Props } from "./Select.types";
import { SelectChangeEvent } from "@mui/material/Select";

export const useSelect = (props: Props) => {
  const { selectedOptionList, setSelectedOptionList, optionList } = props;
  const [searchQuery, setSearchQuery] = useState<string>("");

  const toggleSelectAll = () => {
    if (props.isMultiple) {
      if (selectedOptionList.length === optionList.length) {
        setSelectedOptionList([]);
      } else {
        setSelectedOptionList(
          optionList.map((option) => ({ id: option.id, label: option.label })),
        );
      }
    } else {
      const lastOption = optionList?.[optionList.length - 1];
      if (
        selectedOptionList[selectedOptionList?.length - 1]?.id ===
        lastOption?.id
      ) {
        setSelectedOptionList([]);
      } else {
        setSelectedOptionList(
          lastOption ? [{ id: lastOption.id, label: lastOption.label }] : [],
        );
      }
    }
  };

  const optionListBySearchQuery = useMemo((): Option[] => {
    if (searchQuery.trim().length === 0) {
      return optionList;
    } else {
      return optionList.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
  }, [optionList, searchQuery]);

  const handleChange = (event: SelectChangeEvent) => {
    const optionLabelList: string[] = event.target.value as unknown as string[];
    if (optionLabelList.toString() === "") {
      setSelectedOptionList([]);
    } else {
      const options = optionLabelList
        .map((label) => ({
          id: optionList.find((opt) => opt.label === label)?.id ?? "",
          label,
        }))
        .filter((opt) => opt.id.length > 0);
      if (props.isMultiple) {
        setSelectedOptionList(options);
      } else {
        setSelectedOptionList([options[options.length - 1]]);
      }
    }
  };

  const handleSelectOnClose = () => {
    setSearchQuery("");
  };

  return {
    searchQuery,
    setSearchQuery,
    selectedOptionList,
    handleSelectOnClose,
    handleChange,
    optionListBySearchQuery,
    toggleSelectAll,
  };
};
