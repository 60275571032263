import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import styles from "./CancelEditingConfirmModal.module.scss";
import modalStyles from "../../../../../../styles/Modal.module.scss";
import { Props } from "./CancelEditingConfirmModal.types";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseButton,
} from "../../../../../../components";

export const CancelEditingConfirmModal = (props: Props) => {
  const { handleOnClose, handleOnOk } = props;

  return (
    <Dialog
      onClose={handleOnClose}
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "450px",
          },
        },
      }}
    >
      <DialogTitle className={modalStyles.dialog_title}>
        Отмена редактирования
      </DialogTitle>
      <CloseButton onClick={handleOnClose} isOnModal={true} />
      <DialogContent className={styles.dialog_content}>
        Вы действительно хотите отменить редактирование? <br />
        Внесенные вами изменения не сохранятся.
      </DialogContent>
      <div className={styles.actions}>
        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Contained}
          size={ButtonSize.L}
          onClick={handleOnClose}
          text="Нет"
        />
        <Button
          type={ButtonType.Primary}
          style={ButtonStyle.Contained}
          size={ButtonSize.L}
          onClick={handleOnOk}
          text="Да"
        />
      </div>
    </Dialog>
  );
};
