import React, { useState } from "react";
import { Mode, Organization } from "./CreateOrganizationModal.types";
import { validateUNPField } from "../../../../utils";
import { useURLNavigate } from "../../../../hooks";
import {
  setAddressP1,
  setAddressP2,
  setCOATO,
  setEmailAddress,
  setFax,
  setInternetAddress,
  setOrganizationFullName,
  setOrganizationId,
  setOrganizationIsOrganization,
  setOrganizationShortName,
  setOrganizationUNP,
  setPhone,
  setPostcode,
  setRegistrationDate,
} from "../../../../store/slices";
import { useAppDispatch } from "../../../../store/hooks";
import { v4 as uuidv4 } from "uuid";
import { getOrganizationByUNP } from "../../../../api/integration";

export const useCreateOrganizationModal = () => {
  const [selectedOrganization, setSelectedOrganization] = useState(
    Organization.LegalEntity,
  );
  const [UNP, setUNP] = useState<string>("");
  const [isNextButtonPressed, setIsNextButtonPressed] =
    useState<boolean>(false);
  const [mode, setMode] = useState<Mode>(Mode.Input);
  const { goToCreateOrganizationPage } = useURLNavigate();
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === Organization.LegalEntity) {
      setSelectedOrganization(Organization.LegalEntity);
    } else {
      setSelectedOrganization(Organization.Branch);
    }
  };

  const handleNextButtonOnClick = async () => {
    setIsNextButtonPressed(true);
    if (selectedOrganization === Organization.LegalEntity) {
      if (validateUNPField(UNP)) {
        setMode(Mode.DataChecking);
        try {
          const org = await getOrganizationByUNP(UNP);
          if (typeof org === "undefined") {
            setMode(Mode.UNPNotFound);
          } else {
            dispatch(setOrganizationIsOrganization(true));
            dispatch(setOrganizationId(uuidv4()));
            dispatch(setOrganizationUNP(UNP));
            dispatch(setOrganizationFullName(org.fullName));
            dispatch(setOrganizationShortName(org.shortName));
            dispatch(setRegistrationDate(org.registrationDate));
            dispatch(setPostcode(org.postcode));
            dispatch(setCOATO(org.COATO));
            dispatch(setAddressP1(org.addressP1));
            dispatch(setAddressP2(org.addressP2));
            dispatch(setInternetAddress(org.internetAddress));
            dispatch(setEmailAddress(org.emailAddress));
            dispatch(setPhone(org.phone));
            dispatch(setFax(org.fax));
            goToCreateOrganizationPage();
          }
        } catch (ex) {
          setMode(Mode.FetchError);
        }
      }
    } else {
      dispatch(setOrganizationId(uuidv4()));
      dispatch(setOrganizationFullName(""));
      dispatch(setOrganizationIsOrganization(false));
      goToCreateOrganizationPage();
    }
  };

  return {
    selectedOrganization,
    handleChange,
    UNP,
    setUNP,
    isNextButtonPressed,
    handleNextButtonOnClick,
    mode,
  };
};
