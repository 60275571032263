import { useEffect, useState } from "react";
import { SnackbarState } from "../../../../../components";

export const useSnackBar = () => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarState, setSnackBarState] = useState<SnackbarState>(
    SnackbarState.Success,
  );

  const openSuccessSnackBarr = () => {
    setIsSnackBarOpen(true);
    setSnackBarState(SnackbarState.Success);
  };

  const openErrorSnackBar = () => {
    setIsSnackBarOpen(true);
    setSnackBarState(SnackbarState.Error);
  };

  useEffect(() => {
    const timeout = isSnackBarOpen
      ? setTimeout(() => setIsSnackBarOpen(false), 2500)
      : null;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isSnackBarOpen]);

  return {
    isSnackBarOpen,
    snackBarState,
    openSuccessSnackBarr,
    openErrorSnackBar,
  };
};
