import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker as MUIDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Props } from "./DatePicker.types";
import dayjs from "dayjs";
import styles from "./DatePicker.module.scss";
import cn from "classnames";

export const DatePicker = (props: Props) => {
  const {
    value,
    setValue,
    isDisabled,
    isDateAndTimePicker,
    isError,
    extraClass,
  } = props;

  const datePicker = (
    <MUIDatePicker
      className={cn(
        styles.picker,
        {
          [styles.error]: isError,
        },
        extraClass,
      )}
      format="DD.MM.YYYY"
      value={dayjs(value)}
      onChange={(newValue) =>
        setValue(newValue ? newValue.toDate() : undefined)
      }
      disabled={isDisabled}
    />
  );

  const dateTimePicker = (
    <MUIDateTimePicker
      className={cn(
        styles.picker,
        {
          [styles.error]: isError,
        },
        extraClass,
      )}
      format="DD.MM.YYYY hh:mm"
      value={dayjs(value)}
      onChange={(newValue) =>
        setValue(newValue ? newValue.toDate() : undefined)
      }
      disabled={isDisabled}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={isDateAndTimePicker ? ["DateTimePicker"] : ["DatePicker"]}
      >
        {isDateAndTimePicker ? dateTimePicker : datePicker}
      </DemoContainer>
    </LocalizationProvider>
  );
};
