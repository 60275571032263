import React from "react";
import { v4 as uuid } from "uuid";
import { createBrowserRouter } from "react-router-dom";
import {
  AUTHORIZATION_STORAGE_KEY_PREFIX,
  CREATE_ORGANIZATION_PAGE_URL,
  INIT_USER_EVENT_NAME,
  MAIN_PAGE_URL,
} from "./consts";
import { PageWrapper } from "./components";
import { HealthcareOrganizationsRegister, OrganizationCard } from "./pages";
import { OrganizationPassportBlockMode } from "./pages/OrganizationCard/Features/OrganizationPassportBlock/OrganizationPassportBlock.types";
import { TAuthConfig } from "react-oauth2-code-pkce";
import { join } from "./utils";
import axios from "axios";
import { getToken, getUserRole, logout } from "./App.utils";

export const router = createBrowserRouter([
  {
    path: MAIN_PAGE_URL,
    element: (
      <PageWrapper>
        <HealthcareOrganizationsRegister />
      </PageWrapper>
    ),
  },
  {
    path: `${process.env.REACT_APP_HOME_PAGE}/card/:organizationId`,
    element: (
      <PageWrapper>
        <OrganizationCard
          initialMode={OrganizationPassportBlockMode.Viewing}
          // TODO temp
          changesHistory={[
            {
              dateTime: "12.05.2024 16:45",
              user: "Федорова Александра Ильинична",
            },
            {
              dateTime: "12.05.2024 16:42",
              user: "Лапин Тимофей Олегович",
            },
            {
              dateTime: "12.05.2024 16:36",
              user: "Соколов Лев Александрович",
            },
            {
              dateTime: "12.05.2024 16:31",
              user: "Кудрявцев Артемий Романович",
            },
            {
              dateTime: "12.05.2024 16:25",
              user: "Сорокин Денис Леонидович",
            },
            {
              dateTime: "12.05.2024 16:12",
              user: "Яковлева Злата Робертовна",
            },
          ]}
        />
      </PageWrapper>
    ),
  },
  {
    path: CREATE_ORGANIZATION_PAGE_URL,
    element: (
      <PageWrapper>
        <OrganizationCard
          initialMode={OrganizationPassportBlockMode.Creating}
          changesHistory={[]}
        />
      </PageWrapper>
    ),
  },
]);

export const authConfig: TAuthConfig = {
  clientId: `${process.env.REACT_APP_API_AUTHORIZATION_CLIENT}`,
  authorizationEndpoint: join(
    `${process.env.REACT_APP_API_AUTHORIZATION_SERVER_ENDPOINT}`,
    "auth",
  ),
  logoutEndpoint: join(
    `${process.env.REACT_APP_API_AUTHORIZATION_SERVER_ENDPOINT}`,
    "logout",
  ),
  tokenEndpoint: join(
    `${process.env.REACT_APP_API_AUTHORIZATION_SERVER_ENDPOINT}`,
    "token",
  ),
  redirectUri: `${process.env.REACT_APP_API_AUTHORIZATION_REDIRECT_URL}`,
  scope: `${process.env.REACT_APP_API_AUTHORIZATION_SCOPE}`,
  decodeToken: true,
  autoLogin: false,
  storageKeyPrefix: AUTHORIZATION_STORAGE_KEY_PREFIX,
  extraTokenParameters: { client_secret: uuid() },
  preLogin: () =>
    localStorage.setItem(`${AUTHORIZATION_STORAGE_KEY_PREFIX}userinfo`, "{}"),
  postLogin: async () => {
    const token = getToken();
    try {
      const response = await axios.get(
        join(
          `${process.env.REACT_APP_API_AUTHORIZATION_SERVER_ENDPOINT}`,
          "userinfo",
        ),
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token ?? "")}`,
          },
        },
      );
      localStorage.setItem(
        `${AUTHORIZATION_STORAGE_KEY_PREFIX}userinfo`,
        JSON.stringify(response.data),
      );
      const event = new CustomEvent(INIT_USER_EVENT_NAME);
      if (typeof getUserRole() === "undefined") {
        alert(
          "Роль пользователя не определена. Работа в приложении невозможна",
        );
      }
      document.dispatchEvent(event);
    } catch (err) {
      alert("При получении данных пользователя произошла ошибка.");
      setTimeout(() => {
        logout();
      }, 1500);
    }
  },
};
