import { useAppSelector } from "../../store/hooks";
import { logout } from "../../App.utils";

export const useHeader = () => {
  const user = useAppSelector((state) => state.user);

  const handleOnLogout = () => {
    logout();
  };

  return { handleOnLogout, user };
};
