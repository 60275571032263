export const NECESSARY_FIELD_ERROR_TEXT = "Обязательное поле";
export const MAX_TEXT_FIELD_LENGTH = 500;
export const MAX_TEXT_FIELD_LENGTH_EXCEEDED_ERROR_TEXT =
  "Максимальная длина - 500 символов";
export const NUMBER_REGEX = /^\d+$/;
export const OKPO_FIELD_ERROR_TEXT = "Поле должно состоять ровно из 12 цифр";
export const POSTCODE_FIELD_ERROR_TEXT = "Поле должно состоять ровно из 6 цифр";
export const COATO_FIELD_ERROR_TEXT = "Поле должно состоять ровно из 10 цифр";

export const INTERNET_ADDRESS_FIELD_ERROR_TEXT =
  "Должен быть введен ровно один адрес";
export const EMAIL_ADDRESS_FIELD_ERROR_TEXT =
  "Должен быть введен корректный адрес электронной почты";

export const PHONE_FIELD_ERROR_TEXT = "Для ввода допускаются только цифры";
export const FAX_FIELD_ERROR_TEXT = "Для ввода допускаются только цифры";
export const UNP_FIELD_ERROR_TEXT =
  "Неверный номер УНП (для ввода допускаются ровно 9 цифр)";
export const OWNERSHIP_FORM_ERROR_TEXT =
  "Необходимо выбрать ровно одну запись второго уровня";
export const ORGANIZATION_TYPE_ERROR_TEXT =
  "Необходимо выбрать ровно одну запись второго уровня";
