import React from "react";
import cn from "classnames";
import styles from "./Breadcrumbs.module.scss";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "../Button";
import { ReactComponent as ChevronLeftIcon } from "../../icons/small_chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "../../icons/small_chevron_right.svg";
import { Props } from "./Breadcrumbs.types";
import { useBreadcrumbs } from "./Breadcrumbs.hook";

export const Breadcrumbs = (props: Props) => {
  const { linkList } = props;
  const { handleBackButtonOnClick, goToHealthcareOrganizationListPage } =
    useBreadcrumbs();

  return (
    <div className={styles.container}>
      <Button
        type={ButtonType.Secondary}
        style={ButtonStyle.Contained}
        size={ButtonSize.M}
        onClick={handleBackButtonOnClick}
        text={
          <div className={styles.button}>
            <ChevronLeftIcon />
            <div>Назад</div>
          </div>
        }
      />

      <div className={styles.link_list}>
        <div
          className={cn(styles.link, styles.main_link)}
          onClick={goToHealthcareOrganizationListPage}
        >
          Главная
        </div>
        {linkList.map((link, index) => (
          <div
            key={`${link.name}_${index}`}
            className={cn(styles.link, {
              [styles.link__disabled]: index + 1 === linkList.length,
            })}
            onClick={link.onClick}
          >
            <ChevronRightIcon />
            <div>{link.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
