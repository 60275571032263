import { useEffect, useState } from "react";
import { Option, UseTreeSelect } from "./TreeSelect.types";
import { isOptionArray, isStringArray } from "./TreeSelect.helpers";

export const useTreeSelect = ({
  optionList,
  isMulti = false,
  initValue,
  value,
  selectableParents = false,
  onChangeHandlerOption,
  onChangeHandlerString,
  onChangeHandler,
}: UseTreeSelect) => {
  const [expandedOptions, setExpandedOptions] = useState<string[]>([]);
  const [selectedOptionList, setSelectedOptionList] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredOptions] = useState<Option[]>(optionList);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (typeof value !== "undefined") {
      if (isStringArray(value)) {
        setSelectedOptionList(value);
        const newSelectedOptions = getSelectedOptions(value, optionList);
        setSelectedOptions(newSelectedOptions);
      } else if (isOptionArray(value)) {
        const newSelectedIds = value.map((opt) => opt.id);
        setSelectedOptionList(newSelectedIds);
        setSelectedOptions(value);
      }
    } else {
      setSelectedOptionList([]);
      setSelectedOptions([]);
    }
  }, [value]);

  const getSelectedOptions = (
    selectedIds: string[],
    options: Option[],
  ): Option[] => {
    const selectedOptions: Option[] = [];

    const findOptions = (opts: Option[]) => {
      for (const option of opts) {
        if (selectedIds.includes(option.id)) {
          selectedOptions.push(option);
        }
        if (option.children) {
          findOptions(option.children);
        }
      }
    };

    findOptions(options);
    return selectedOptions;
  };

  useEffect(() => {
    initValue && setSelectedOptionList(initValue);
  }, [initValue]);

  const getSelectedLabels = (
    options: Option[],
    selectedIds: string[],
  ): string[] => {
    return options.flatMap((option) => {
      const labels = [];
      if (selectedIds.includes(option.id)) {
        labels.push(option.label);
      }
      if (option.children && option.children.length > 0) {
        labels.push(...getSelectedLabels(option.children, selectedIds));
      }
      return labels;
    });
  };

  const valueForRender = getSelectedLabels(optionList, selectedOptionList);

  const toggleOption = (optionId: string) => {
    setExpandedOptions((prevExpandedOptions) => {
      if (prevExpandedOptions.includes(optionId)) {
        return prevExpandedOptions.filter((id) => id !== optionId);
      } else {
        return [...prevExpandedOptions, optionId];
      }
    });
  };

  const handleSelect = (option: Option) => {
    const hasChildren = option.children && option.children.length > 0;
    if (hasChildren && selectableParents) {
      toggleSelection(option.id);
    } else if (!hasChildren || selectableParents) {
      toggleSelection(option.id);
    } else {
      if (isMulti) {
        const allChildIds = option.children!.map((child) => child.id);
        toggleSelection(option.id, allChildIds);
      }
    }
  };
  const filterOptions = (options: Option[], search: string): Option[] => {
    if (!search) return options;
    const lowercasedSearch = search.toLowerCase();

    return options
      .map((option) => {
        if (option.label.toLowerCase().includes(lowercasedSearch)) {
          return option;
        }

        if (option.children) {
          const filteredChildren = filterOptions(option.children, search);
          if (filteredChildren.length > 0) {
            return { ...option, children: filteredChildren };
          }
        }

        return null;
      })
      .filter(Boolean) as Option[];
  };

  const toggleSelection = (optionId: string, childIds: string[] = []) => {
    setSelectedOptionList((prevSelectedOptions) => {
      let newSelection;

      if (childIds.length > 0) {
        const allSelected = childIds.every((id) =>
          prevSelectedOptions.includes(id),
        );
        newSelection = allSelected
          ? prevSelectedOptions.filter((id) => !childIds.includes(id))
          : [...prevSelectedOptions, ...childIds];
      } else {
        const isSelected = prevSelectedOptions.includes(optionId);
        if (isMulti) {
          newSelection = isSelected
            ? prevSelectedOptions.filter((id) => id !== optionId)
            : [...prevSelectedOptions, optionId];
        } else {
          newSelection = isSelected ? [] : [optionId];
        }
      }
      const uniqueNewSelection = Array.from(new Set(newSelection));
      const selectedOptions = getSelectedOptions(
        uniqueNewSelection,
        optionList,
      );
      setSelectedOptions(selectedOptions);
      if (onChangeHandlerOption) {
        const selectedOptions = getSelectedOptions(
          uniqueNewSelection,
          optionList,
        );
        onChangeHandlerOption(selectedOptions);
      }

      if (onChangeHandlerString) {
        onChangeHandlerString(uniqueNewSelection);
      }

      if (onChangeHandler) {
        onChangeHandler(uniqueNewSelection);
      }

      return newSelection.length > 0 || isMulti
        ? Array.from(new Set(newSelection))
        : newSelection;
    });
  };

  const toggleSelectAll = () => {
    const allSelectableIds = optionList.reduce<string[]>((acc, option) => {
      const collectSelectableIds = (opt: Option) => {
        if (!opt.children || opt.children.length === 0 || selectableParents) {
          acc.push(opt.id);
        } else if (opt.children) {
          opt.children.forEach(collectSelectableIds);
        }
      };
      collectSelectableIds(option);
      return acc;
    }, []);

    setSelectedOptionList((prevSelectedOptions) => {
      const isAllSelected = allSelectableIds.every((id) =>
        prevSelectedOptions.includes(id),
      );

      const newSelection = isAllSelected ? [] : allSelectableIds;
      const selectedOptions = getSelectedOptions(newSelection, optionList);

      if (onChangeHandlerOption) {
        onChangeHandlerOption(selectedOptions);
      }
      if (onChangeHandler) {
        onChangeHandler(newSelection);
      }
      if (onChangeHandlerString) {
        onChangeHandlerString(newSelection);
      }

      setSelectedOptions(selectedOptions);

      return newSelection;
    });
  };

  const maxCount = optionList.reduce((acc, option) => {
    if (!option.children || option.children.length === 0 || selectableParents) {
      return acc + 1;
    } else {
      return acc + option.children.length;
    }
  }, 0);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return {
    selectedOptionList,
    expandedOptions,
    toggleOption,
    toggleSelection,
    filteredOptions,
    searchTerm,
    setSearchTerm,
    filterOptions,
    handleSelect,
    toggleSelectAll,
    maxCount,
    valueForRender,
    isMenuOpen,
    handleMenuClose,
    handleMenuOpen,
  };
};
