import { Row } from "../../../HealthcareOrganizationsRegister/Features/CustomTable/CustomTable.types";
import { ChangeOrganizationStatusModalMode } from "../../OrganizationCard.types";

export type Props = {
  onClose: () => void;
  organization: Row;
  changeStatusMode: ChangeOrganizationStatusModalMode;
};

export enum Mode {
  CheckingAbilityToClose,
  CanChangeStatus,
  CannotCloseOrganization,
  ChangeStatusConfirmModalOpen,
}
