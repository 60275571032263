import { TreeSelectOption } from "../components/TreeSelect/TreeSelect.types";
import { OptionWithParent } from "../components/Select/Select.types";

export const convertFlatOptionListTo2LevelOptionList = (
  flatOptionList: OptionWithParent[],
): TreeSelectOption[] => {
  const list: TreeSelectOption[] = [];
  flatOptionList.forEach((opt) => {
    if (opt.parentId === null) {
      list.push({
        id: opt.id,
        label: opt.label,
        children: [],
      });
    }
  });

  flatOptionList.forEach((opt) => {
    if (opt.parentId !== null) {
      list.forEach((org, index) => {
        if (org.id === opt.parentId) {
          list[index].children!.push({
            id: opt.id,
            label: opt.label,
          });
        }
      });
    }
  });

  return list;
};
