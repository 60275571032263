import { DEFAULT_SORT_DIRECTION } from "../consts";
import { useState } from "react";
import { SortDirection } from "../types";

export const useSortableTable = () => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    DEFAULT_SORT_DIRECTION,
  );
  const [sortedColumnName, setSortedColumnName] = useState<string | undefined>(
    undefined,
  );

  const handSortableTableCellOnClick = (columnName: string) => () => {
    if (typeof sortedColumnName === "undefined") {
      setSortDirection(DEFAULT_SORT_DIRECTION);
    } else {
      if (sortedColumnName === columnName) {
        setSortDirection((prev) =>
          prev === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
        );
      } else {
        setSortDirection(DEFAULT_SORT_DIRECTION);
      }
    }
    setSortedColumnName(columnName);
  };

  return { sortDirection, sortedColumnName, handSortableTableCellOnClick };
};
