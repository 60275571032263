import * as React from "react";
import { Input } from "../Input";
import { Props } from "../Input/Input.types";
import { InputAdornment } from "../Input/Input.styles";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";

export const SearchInput = (props: Props) => {
  return (
    <Input
      {...props}
      startAdornment={
        <InputAdornment>
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
