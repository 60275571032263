import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { ActionType, Props } from "./Actions.types";
import styles from "./Actions.module.scss";
import {
  Button,
  ButtonIconPositionRelativeToText,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from "../../../../../../components";
import { ReactComponent as SmallChevronUpIcon } from "../../../../../../icons/small_chevron_up.svg";
import { ReactComponent as SmallChevronDownIcon } from "../../../../../../icons/small_chevron_down.svg";
import { useActions } from "./Actions.hook";

export const Actions = (props: Props) => {
  const { anchorEl, handleOnClose, handleOnClick, open, user } =
    useActions(props);

  return (
    <>
      <Button
        type={ButtonType.Secondary}
        style={ButtonStyle.Contained}
        size={ButtonSize.M}
        onClick={handleOnClick}
        icon={open ? <SmallChevronUpIcon /> : <SmallChevronDownIcon />}
        iconPosition={ButtonIconPositionRelativeToText.Right}
        text="Действия"
        disabled={typeof user.role === "undefined"}
      />
      <Menu
        className={styles.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleOnClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { width: anchorEl && anchorEl.offsetWidth },
        }}
      >
        <MenuItem onClick={handleOnClose(ActionType.Print)}>Печать</MenuItem>
        <MenuItem onClick={handleOnClose(ActionType.Export)}>Экспорт</MenuItem>
      </Menu>
    </>
  );
};
