import { Method } from "../types";
import { getToken } from "../App.utils";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ADD_API_ERROR_EVENT_NAME } from "../consts";

export const fetchWrapper = async (
  url: string,
  method: Method,
  body?: {},
): Promise<AxiosResponse<any, any> | undefined> => {
  const token = getToken();
  const authorization = `Bearer ${JSON.parse(token ?? "")}`;

  try {
    return await axios({
      method: method,
      url,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Accept:
          "application/json, text/json, text/x-json, text/javascript, application/xml, text/xml",
        Authorization: authorization,
      },
      data: body ? JSON.stringify(body) : undefined,
    });
  } catch (ex) {
    document.dispatchEvent(
      new CustomEvent(ADD_API_ERROR_EVENT_NAME, {
        detail: {
          code: (ex as AxiosError).status,
        },
      }),
    );
    throw new Error();
  }
};
