import {
  DetailsBlock,
  HyperLinkType,
  Loader,
  Snackbar,
  SnackbarState,
  StatusType,
} from "../../../../components";
import {
  OrganizationPassportBlockMode,
  Props,
} from "./OrganizationPassportBlock.types";
import { CancelEditingConfirmModal, Header } from "./Features";
import { ControlType } from "../../../../types";
import { useOrganizationPassportBlock } from "./OrganizationPassportBlock.hook";
import {
  convertFlatOptionListTo2LevelOptionList,
  getCOATOCodeFieldErrorText,
  getEmailAddressFieldErrorText,
  getFaxFieldErrorText,
  getInternetAddressFieldErrorText,
  getOKPOFieldErrorText,
  getOrganizationTypeErrorText,
  getOwnershipFormErrorText,
  getPhoneFieldErrorText,
  getPostcodeFieldErrorText,
  getSelectFieldErrorText,
  getStringFieldErrorText,
  validateDateField,
} from "../../../../utils";
import * as React from "react";
import { Divider } from "@mui/material";
import { convertNullableOptionToArray } from "./OrganizationPassportBlock.utils";
import styles from "./OrganizationPassportBlock.module.scss";

export const OrganizationPassportBlock = (props: Props) => {
  const { isOrganization, status } = props;
  const {
    organizationPassport,
    setFullName,
    handleOnEdit,
    handleOnCancel,
    handleOnSave,
    isSaveButtonPressed,
    handleCancelEditingConfirmModalOnClose,
    handleCancelEditingConfirmModalOnOk,
    isCancelEditingConfirmModalOpen,
    setShortName,
    setHealthcareStructure,
    setOKPO,
    setSubordination,
    setMedicalCareLevel,
    setRegistrationDate,
    setRegisterRegistrationDate,
    setLastUpdateDate,
    setMedicalCareConditions,
    setMedicalCareTypes,
    setPostcode,
    setCOATOCode,
    setAddressP1,
    setAddressP2,
    setInternetAddress,
    setEmailAddress,
    setPhone,
    setFax,
    dictionaries,
    isInfoAboutOrganizationLoadedSnackbarOpen,
    setParentOrganization,
    isEditing,
    headquarters,
    handleHeadquartersOnClick,
    snackbarText,
    openedOrganization,
    updateHeadquarters,
    isLoading,
    selectedOwnershipTypeList,
    handleOwnershipTypeOptionListOnChange,
    handleOrganizationTypeOptionListOnChange,
    selectedOrganizationTypeList,
  } = useOrganizationPassportBlock(props);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader height={48} width={48} />
      </div>
    );
  }

  return (
    <>
      <Header
        isEditButtonDisabled={status !== StatusType.Active}
        handleOnCancel={handleOnCancel}
        handleOnEdit={handleOnEdit}
        handleOnSave={handleOnSave}
      />
      <DetailsBlock
        isEditing={isEditing}
        title="Общие сведения"
        fieldList={[
          {
            name: "Полное наименование",
            controlType: ControlType.Input,
            value: organizationPassport.fullName,
            setValue: setFullName,
            isRequired: true,
            errorText: getStringFieldErrorText(
              organizationPassport.fullName,
              isSaveButtonPressed,
            ),
          },
          {
            name: "Сокращённое наименование",
            controlType: ControlType.Input,
            value: organizationPassport.shortName,
            setValue: setShortName,
            isRequired: true,
            errorText: getStringFieldErrorText(
              organizationPassport.shortName,
              isSaveButtonPressed,
            ),
          },
          {
            withSelectAll: false,
            isRequired: true,
            name: "Форма собственности",
            controlType: ControlType.TreeSelect,
            label: "Форма собственности",
            options: convertFlatOptionListTo2LevelOptionList(
              dictionaries.ownershipTypeOptionList,
            ),
            value: selectedOwnershipTypeList,
            selectedOptionList: selectedOwnershipTypeList,
            onChangeHandlerOption: handleOwnershipTypeOptionListOnChange,
            isError: !!getOwnershipFormErrorText(
              selectedOwnershipTypeList,
              isSaveButtonPressed,
            ),
          },
          {
            isVisible: isOrganization,
            name: "УНП",
            controlType: ControlType.Input,
            value: organizationPassport.UNP,
            setValue: () => {},
            isRequired: true,
            isDisabled: true,
          },
          {
            isVisible: !isOrganization,
            withSelectAll: false,
            isMultiple: false,
            name: "Головная организация",
            controlType: ControlType.Select,
            label: "Головная организация",
            optionList: dictionaries.allOrganizationsOptionList,
            selectedOptionList: convertNullableOptionToArray(headquarters),
            setSelectedOptionList: updateHeadquarters,
            isError: !!getSelectFieldErrorText(
              headquarters,
              isSaveButtonPressed,
            ),
            isRequired: true,
            isDisabled: true,
            linkType: HyperLinkType.ClickHandlerInsteadHref,
            clickHandler: handleHeadquartersOnClick,
          },
          {
            name: "Идентификатор организации",
            controlType: ControlType.Input,
            value: organizationPassport.organizationId,
            setValue: () => {},
            isCopiedText: true,
            isDisabled: true,
            isRequired: true,
          },
        ]}
      />
      <Divider />
      <DetailsBlock
        isEditing={isEditing}
        title="Классификация"
        fieldList={[
          {
            withSelectAll: false,
            isMultiple: false,
            name: "Структура здравоохранения",
            controlType: ControlType.Select,
            label: "Структура здравоохранения",
            optionList: dictionaries.healthcareStructureOptionList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.healthcareStructure,
            ),
            setSelectedOptionList: setHealthcareStructure,
            isError: !!getSelectFieldErrorText(
              organizationPassport.healthcareStructure,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            withSelectAll: false,
            isRequired: true,
            name: "Тип ОЗ",
            controlType: ControlType.TreeSelect,
            label: "Тип ОЗ",
            options: convertFlatOptionListTo2LevelOptionList(
              dictionaries.organizationTypeOptionList,
            ),
            value: selectedOrganizationTypeList,
            selectedOptionList: selectedOrganizationTypeList,
            onChange: handleOrganizationTypeOptionListOnChange,
            isError: !!getOrganizationTypeErrorText(
              selectedOrganizationTypeList,
              isSaveButtonPressed,
            ),
          },
          {
            name: "ОКПО",
            controlType: ControlType.Input,
            value: organizationPassport.OKPO,
            setValue: setOKPO,
            errorText: getOKPOFieldErrorText(
              organizationPassport.OKPO,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            withSelectAll: false,
            isMultiple: false,
            name: "Вышестоящая (управляющая) организация",
            controlType: ControlType.Select,
            label: "Вышестоящая (управляющая) организация",
            optionList: dictionaries.parentOrganizationList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.parentOrganization,
            ),
            setSelectedOptionList: setParentOrganization,
            isError: !!getSelectFieldErrorText(
              organizationPassport.parentOrganization,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            withSelectAll: false,
            isMultiple: false,
            name: "Подчинённость",
            controlType: ControlType.Select,
            label: "Подчинённость",
            optionList: dictionaries.subordinationLevelOptionList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.subordination,
            ),
            setSelectedOptionList: setSubordination,
            isError: !!getSelectFieldErrorText(
              organizationPassport.subordination,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            withSelectAll: false,
            name: "Уровень оказания медицинской помощи",
            controlType: ControlType.Select,
            isMultiple: false,
            label: "Уровень оказания медицинской помощи",
            optionList: dictionaries.medicalCareLevelOptionList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.medicalCareLevel,
            ),
            setSelectedOptionList: setMedicalCareLevel,
            isError: !!getSelectFieldErrorText(
              organizationPassport.medicalCareLevel,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            name: "Дата регистрации",
            controlType: ControlType.DatePicker,
            value: organizationPassport.registrationDate,
            setValue: setRegistrationDate,
            isRequired: true,
            isError: !validateDateField(
              organizationPassport.registrationDate,
              isSaveButtonPressed,
            ),
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
              OrganizationPassportBlockMode.Creating
                ? false
                : organizationPassport.status === StatusType.Closed ||
                  organizationPassport.status === StatusType.Suspended,
            name: `Дата ${organizationPassport.status === StatusType.Closed ? "закрытия карточки" : "приостановки деятельности"}`,
            controlType: ControlType.DatePicker,
            value: organizationPassport.statusDate
              ? new Date(organizationPassport.statusDate)
              : undefined,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
                OrganizationPassportBlockMode.Viewing &&
              organizationPassport.status === StatusType.Active &&
              !!organizationPassport.statusDate,
            name: `Дата возобновления деятельности`,
            controlType: ControlType.DatePicker,
            value: organizationPassport.statusDate
              ? new Date(organizationPassport.statusDate)
              : undefined,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
              OrganizationPassportBlockMode.Creating
                ? false
                : organizationPassport.status === StatusType.Closed ||
                  organizationPassport.status === StatusType.Suspended,
            name: `Причина ${organizationPassport.status === StatusType.Closed ? "закрытия" : "приостановки деятельности"}`,
            controlType: ControlType.Input,
            value: organizationPassport.statusReason,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
                OrganizationPassportBlockMode.Viewing &&
              organizationPassport.status === StatusType.Active &&
              !!organizationPassport.statusDate,
            name: `Причина повторной активации`,
            controlType: ControlType.Input,
            value: organizationPassport.statusReason,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
              OrganizationPassportBlockMode.Creating
                ? false
                : organizationPassport.status === StatusType.Closed ||
                  organizationPassport.status === StatusType.Suspended,
            name: `Основание для ${organizationPassport.status === StatusType.Closed ? "закрытия" : "приостановки деятельности"}`,
            controlType: ControlType.Input,
            value: organizationPassport.statusBasis,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode ===
                OrganizationPassportBlockMode.Viewing &&
              organizationPassport.status === StatusType.Active &&
              !!organizationPassport.statusDate,
            name: `Основание повторной активации`,
            controlType: ControlType.Input,
            value: organizationPassport.statusBasis,
            setValue: () => {},
            isRequired: false,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode !==
              OrganizationPassportBlockMode.Creating,
            name: "Дата регистрации в регистре",
            controlType: ControlType.DatePicker,
            value: organizationPassport.registerRegistrationDate,
            setValue: setRegisterRegistrationDate,
            isRequired: true,
            isDisabled: true,
          },
          {
            isVisible:
              openedOrganization.organizationPassportBlockMode !==
              OrganizationPassportBlockMode.Creating,
            name: "Дата последней актуализации",
            controlType: ControlType.DatePicker,
            value: organizationPassport.lastUpdateDate,
            setValue: setLastUpdateDate,
            isDateAndTimePicker: true,
            isRequired: true,
            isDisabled: true,
          },
        ]}
      />

      <Divider />
      <DetailsBlock
        isEditing={isEditing}
        title="Специализация"
        fieldList={[
          {
            withSelectAll: false,
            name: "Условия оказания медицинской помощи",
            controlType: ControlType.Select,
            isMultiple: false,
            label: "Условия оказания медицинской помощи",
            optionList: dictionaries.medicalCareConditionsOptionList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.medicalCareConditions,
            ),
            setSelectedOptionList: setMedicalCareConditions,
            isError: !!getSelectFieldErrorText(
              organizationPassport.medicalCareConditions,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
          {
            withSelectAll: false,
            name: "Виды медицинской помощи",
            controlType: ControlType.Select,
            isMultiple: false,
            label: "Виды медицинской помощи",
            optionList: dictionaries.medicalCareTypeOptionList,
            selectedOptionList: convertNullableOptionToArray(
              organizationPassport.medicalCareTypes,
            ),
            setSelectedOptionList: setMedicalCareTypes,
            isError: !!getSelectFieldErrorText(
              organizationPassport.medicalCareTypes,
              isSaveButtonPressed,
            ),
            isRequired: true,
          },
        ]}
      />

      <Divider />
      <DetailsBlock
        isEditing={isEditing}
        title="Адресные данные"
        fieldList={[
          {
            name: "Почтовый индекс",
            controlType: ControlType.Input,
            value: organizationPassport.postcode,
            setValue: setPostcode,
            isRequired: true,
            errorText: getPostcodeFieldErrorText(
              organizationPassport.postcode,
              isSaveButtonPressed,
            ),
          },
          {
            name: "Код СОАТО",
            controlType: ControlType.Input,
            value: organizationPassport.COATOCode,
            setValue: setCOATOCode,
            isRequired: true,
            errorText: getCOATOCodeFieldErrorText(
              organizationPassport.COATOCode,
              isSaveButtonPressed,
            ),
          },
          {
            name: "Область, район, город/cельский совет/иной населенный пункт",
            controlType: ControlType.Input,
            value: organizationPassport.addressP1,
            setValue: setAddressP1,
            isRequired: true,
            errorText: getStringFieldErrorText(
              organizationPassport.addressP1,
              isSaveButtonPressed,
            ),
          },
          {
            name: "Улица, номер дома, корпус (дополнительный индекс)",
            controlType: ControlType.Input,
            value: organizationPassport.addressP2,
            setValue: setAddressP2,
            isRequired: true,
            errorText: getStringFieldErrorText(
              organizationPassport.addressP2,
              isSaveButtonPressed,
            ),
          },
        ]}
      />

      <DetailsBlock
        isEditing={isEditing}
        title="Контактные данные"
        fieldList={[
          {
            name: "Адрес в интернете",
            controlType: ControlType.Input,
            value: organizationPassport.internetAddress,
            setValue: setInternetAddress,
            isRequired: true,
            errorText: getInternetAddressFieldErrorText(
              organizationPassport.internetAddress,
              isSaveButtonPressed,
            ),
            linkType: HyperLinkType.SimpleLink,
          },
          {
            name: "Адрес электронной почты",
            controlType: ControlType.Input,
            value: organizationPassport.emailAddress,
            setValue: setEmailAddress,
            isRequired: true,
            errorText: getEmailAddressFieldErrorText(
              organizationPassport.emailAddress,
              isSaveButtonPressed,
            ),
            linkType: HyperLinkType.Mail,
          },
          {
            name: "Телефон",
            controlType: ControlType.Input,
            value: organizationPassport.phone,
            setValue: setPhone,
            isRequired: true,
            errorText: getPhoneFieldErrorText(
              organizationPassport.phone,
              isSaveButtonPressed,
            ),
          },
          {
            name: "Факс",
            controlType: ControlType.Input,
            value: organizationPassport.fax ?? "",
            setValue: setFax,
            errorText: getFaxFieldErrorText(
              organizationPassport.fax,
              isSaveButtonPressed,
            ),
          },
        ]}
      />

      {isCancelEditingConfirmModalOpen && (
        <CancelEditingConfirmModal
          handleOnClose={handleCancelEditingConfirmModalOnClose}
          handleOnOk={handleCancelEditingConfirmModalOnOk}
        />
      )}
      {isInfoAboutOrganizationLoadedSnackbarOpen && (
        <Snackbar text={snackbarText} state={SnackbarState.Success} />
      )}
    </>
  );
};
