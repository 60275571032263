import {
  OrganizationPassportBlockMode,
  Props,
} from "./OrganizationPassportBlock.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrganizationPassport } from "../../OrganizationCard.types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setOrganizationPassportBlockMode } from "../../../../store/slices";
import {
  validateCOATOCodeField,
  validateDateField,
  validateEmailAddressField,
  validateFaxField,
  validateInternetAddressField,
  validateOKPOField,
  validateOrganizationTypeField,
  validateOwnershipFormField,
  validatePhoneField,
  validatePostcodeField,
  validateSelectField,
  validateString,
} from "../../../../utils";
import {
  SAVE_ORGANIZATION_EVENT_NAME,
  SNACKBAR_APPEARANCE_DURATION_MS,
} from "../../../../consts";
import { Option } from "../../../../components";
import { useURLNavigate } from "../../../../hooks";
import { getHealthcareOrganizationShortNameByCode } from "../../../../api";
import { createOrUpdateOrganization } from "../../../../api/save-dictionaries";

export const useOrganizationPassportBlock = (props: Props) => {
  const [organizationPassport, setOrganizationPassport] =
    useState<OrganizationPassport>(props);

  useEffect(() => {
    setOrganizationPassport(props);
  }, [props]);

  const dispatch = useAppDispatch();
  const [isSaveButtonPressed, setIsSaveButtonPressed] =
    useState<boolean>(false);
  const openedOrganization = useAppSelector(
    (state) => state.openedOrganization,
  );
  const [isCancelEditingConfirmModalOpen, setIsCancelEditingConfirmModalOpen] =
    useState<boolean>(false);
  const dictionaries = useAppSelector((state) => state.dictionaries);
  const [
    isInfoAboutOrganizationLoadedSnackbarOpen,
    setIsInfoAboutOrganizationLoadedSnackbarOpen,
  ] = useState<boolean>(false);
  const { goToOrganizationCardPage } = useURLNavigate();
  const [headquarters, setHeadquarters] = useState<Option | undefined>(
    undefined,
  );
  const [snackbarText, setSnackbarText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOwnershipTypeList, setSelectedOwnershipTypeList] = useState<
    Option[]
  >([]);
  const [selectedOrganizationTypeList, setSelectedOrganizationTypeList] =
    useState<Option[]>([]);

  useEffect(() => {
    setSelectedOwnershipTypeList(
      props.ownershipType ? [props.ownershipType] : [],
    );
  }, [props.ownershipType]);

  useEffect(() => {
    setSelectedOrganizationTypeList(
      props.organizationType ? [props.organizationType] : [],
    );
  }, [props.organizationType]);

  const handleOwnershipTypeOptionListOnChange = (optionList: Option[]) => {
    setSelectedOwnershipTypeList(optionList);
  };

  const handleOrganizationTypeOptionListOnChange = (idList: string[]) => {
    setSelectedOrganizationTypeList(
      idList.map((id) => {
        const currentOption = dictionaries.organizationTypeOptionList.find(
          (opt) => opt.id === id,
        )!;

        return {
          id,
          label: currentOption.label,
        };
      }),
    );
  };

  useEffect(() => {
    setIsSaveButtonPressed(false);
  }, [openedOrganization.organizationPassportBlockMode]);

  const handleOnEdit = () => {
    dispatch(
      setOrganizationPassportBlockMode(OrganizationPassportBlockMode.Editing),
    );
  };

  const handleOnCancel = () => {
    setIsCancelEditingConfirmModalOpen(true);
  };

  const handleCancelEditingConfirmModalOnClose = () => {
    setIsCancelEditingConfirmModalOpen(false);
  };

  const handleCancelEditingConfirmModalOnOk = () => {
    handleCancelEditingConfirmModalOnClose();
    setOrganizationPassport(props);
    dispatch(
      setOrganizationPassportBlockMode(OrganizationPassportBlockMode.Viewing),
    );
  };

  const isEditing = useMemo((): boolean => {
    return (
      openedOrganization.organizationPassportBlockMode ===
        OrganizationPassportBlockMode.Editing ||
      openedOrganization.organizationPassportBlockMode ===
        OrganizationPassportBlockMode.Creating
    );
  }, [openedOrganization]);

  const handleOnSave = async () => {
    setIsSaveButtonPressed(true);
    const isFullNameOk = validateString(organizationPassport.fullName);
    const isShortNameOk = validateString(organizationPassport.shortName);
    const isOwnershipFormOk = validateOwnershipFormField(
      selectedOwnershipTypeList,
    );
    const isOrganizationTypeOk = validateOrganizationTypeField(
      selectedOrganizationTypeList,
    );
    const isHeadquartersOk = organizationPassport.isOrganization
      ? true
      : validateSelectField(headquarters);
    const isHealthcareStructureOk = validateSelectField(
      organizationPassport.healthcareStructure,
    );
    const isOKPOOk = validateOKPOField(organizationPassport.OKPO);
    const isParentOrganizationOk = validateSelectField(
      organizationPassport.parentOrganization,
    );
    const isSubordinationOk = validateSelectField(
      organizationPassport.subordination,
    );
    const isMedicalCareLevelOk = validateSelectField(
      organizationPassport.medicalCareLevel,
    );
    const isRegistrationDateOk = validateDateField(
      organizationPassport.registrationDate,
      true,
    );
    const isMedicalCareConditionsOk = validateSelectField(
      organizationPassport.medicalCareConditions,
    );
    const isMedicalCareTypesOk = validateSelectField(
      organizationPassport.medicalCareTypes,
    );
    const isPostcodeOk = validatePostcodeField(organizationPassport.postcode);
    const isCOATOCodeOk = validateCOATOCodeField(
      organizationPassport.COATOCode,
    );
    const isAddressP1Ok = validateString(organizationPassport.addressP1);
    const isAddressP2Ok = validateString(organizationPassport.addressP2);
    const isInternetAddressOk = validateInternetAddressField(
      organizationPassport.internetAddress,
    );
    const isEmailAddressOk = validateEmailAddressField(
      organizationPassport.emailAddress,
    );
    const isPhoneOk = validatePhoneField(organizationPassport.phone);
    const isFaxOk = validateFaxField(organizationPassport.fax);

    const isValidationOk =
      isFullNameOk &&
      isShortNameOk &&
      isHeadquartersOk &&
      isHealthcareStructureOk &&
      isOKPOOk &&
      isParentOrganizationOk &&
      isSubordinationOk &&
      isMedicalCareLevelOk &&
      isRegistrationDateOk &&
      isMedicalCareConditionsOk &&
      isMedicalCareTypesOk &&
      isPostcodeOk &&
      isCOATOCodeOk &&
      isAddressP1Ok &&
      isAddressP2Ok &&
      isInternetAddressOk &&
      isEmailAddressOk &&
      isPhoneOk &&
      isFaxOk &&
      isOwnershipFormOk &&
      isOrganizationTypeOk;

    if (isValidationOk) {
      if (
        openedOrganization.organizationPassportBlockMode ===
          OrganizationPassportBlockMode.Editing ||
        openedOrganization.organizationPassportBlockMode ===
          OrganizationPassportBlockMode.Creating
      ) {
        try {
          setIsLoading(true);
          await createOrUpdateOrganization(
            dictionaries.gridIdDictionary!,
            {
              ...organizationPassport,
              organizationId:
                openedOrganization.organizationPassportBlockMode ===
                OrganizationPassportBlockMode.Creating
                  ? openedOrganization.id
                  : organizationPassport.organizationId,
              ownershipType: {
                id: selectedOwnershipTypeList[0].id,
                label: selectedOwnershipTypeList[0].label,
              },
              organizationType: {
                id: selectedOrganizationTypeList[0].id,
                label: selectedOrganizationTypeList[0].label,
              },
            },
            openedOrganization.organizationPassportBlockMode ===
              OrganizationPassportBlockMode.Creating,
            openedOrganization.organizationPassportBlockMode ===
              OrganizationPassportBlockMode.Creating
              ? ""
              : organizationPassport.idDictionaryItem,
            organizationPassport.isOrganization ? undefined : headquarters!.id,
          );
          if (
            openedOrganization.organizationPassportBlockMode ===
            OrganizationPassportBlockMode.Creating
          ) {
            goToOrganizationCardPage(openedOrganization.id);
          }
          window.location.reload();
        } catch (ex) {
          alert("Ошибка сохранения");
          setIsLoading(false);
        }
      }
    }
  };

  const handleCallOnSave = useCallback(() => {
    handleOnSave();
  }, [handleOnSave]);

  useEffect(() => {
    document.addEventListener(SAVE_ORGANIZATION_EVENT_NAME, handleCallOnSave);

    return () => {
      document.removeEventListener(
        SAVE_ORGANIZATION_EVENT_NAME,
        handleCallOnSave,
      );
    };
  }, [handleCallOnSave]);

  const fetchHeadquarters = async () => {
    if (!dictionaries.gridIdDictionary) return;
    if (
      openedOrganization.organizationPassportBlockMode ===
      OrganizationPassportBlockMode.Viewing
    ) {
      setHeadquarters({
        id: organizationPassport.parentId,
        label: await getHealthcareOrganizationShortNameByCode(
          organizationPassport.parentId,
          dictionaries.gridIdDictionary,
        ),
      });
    }
  };

  const updateHeadquarters = (headquarters: Option[]) => {
    setHeadquarters(headquarters[0]);
  };

  useEffect(() => {
    fetchHeadquarters();
  }, [
    organizationPassport.parentId,
    openedOrganization.organizationPassportBlockMode,
    dictionaries.gridIdDictionary,
  ]);

  const setFullName = (fullName: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      fullName,
    }));
  };

  const setShortName = (shortName: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      shortName,
    }));
  };

  const setHealthcareStructure = (healthcareStructure: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      healthcareStructure: healthcareStructure[0],
    }));
  };

  const setOKPO = (OKPO: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      OKPO,
    }));
  };

  const setSubordination = (subordination: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      subordination: subordination[0],
    }));
  };

  const setMedicalCareLevel = (medicalCareLevel: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      medicalCareLevel: medicalCareLevel[0],
    }));
  };

  const setRegistrationDate = (registrationDate: Date | undefined) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      registrationDate,
    }));
  };

  const setRegisterRegistrationDate = (
    registerRegistrationDate: Date | undefined,
  ) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      registerRegistrationDate,
    }));
  };

  const setLastUpdateDate = (lastUpdateDate: Date | undefined) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      lastUpdateDate,
    }));
  };

  const setMedicalCareConditions = (medicalCareConditions: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      medicalCareConditions: medicalCareConditions[0],
    }));
  };

  const setMedicalCareTypes = (medicalCareTypes: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      medicalCareTypes: medicalCareTypes[0],
    }));
  };

  const setPostcode = (postcode: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      postcode,
    }));
  };

  const setCOATOCode = (COATOCode: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      COATOCode,
    }));
  };

  const setAddressP1 = (addressP1: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      addressP1,
    }));
  };

  const setAddressP2 = (addressP2: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      addressP2,
    }));
  };

  const setInternetAddress = (internetAddress: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      internetAddress,
    }));
  };

  const setEmailAddress = (emailAddress: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      emailAddress,
    }));
  };

  const setPhone = (phone: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      phone,
    }));
  };

  const setFax = (fax: string) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      fax,
    }));
  };

  const setParentOrganization = (parentOrganization: Option[]) => {
    setOrganizationPassport((prev) => ({
      ...prev,
      parentOrganization: parentOrganization[0],
    }));
  };

  useEffect(() => {
    if (
      openedOrganization.organizationPassportBlockMode ===
      OrganizationPassportBlockMode.Creating
    ) {
      if (openedOrganization.isOrganization) {
        setSnackbarText("Информация об организации загружена успешно");
        setIsInfoAboutOrganizationLoadedSnackbarOpen(true);
        setTimeout(() => {
          setIsInfoAboutOrganizationLoadedSnackbarOpen(false);
        }, SNACKBAR_APPEARANCE_DURATION_MS);
      }
    }
  }, [openedOrganization]);

  const handleHeadquartersOnClick = () => {
    goToOrganizationCardPage(organizationPassport.parentId);
    window.location.reload();
  };

  return {
    organizationPassport,
    setFullName,
    setShortName,
    handleOnEdit,
    handleOnCancel,
    handleOnSave,
    isSaveButtonPressed,
    handleCancelEditingConfirmModalOnClose,
    handleCancelEditingConfirmModalOnOk,
    isCancelEditingConfirmModalOpen,
    setHealthcareStructure,
    setOKPO,
    setSubordination,
    setMedicalCareLevel,
    setRegistrationDate,
    setRegisterRegistrationDate,
    setLastUpdateDate,
    setMedicalCareConditions,
    setMedicalCareTypes,
    setPostcode,
    setCOATOCode,
    setAddressP1,
    setAddressP2,
    setInternetAddress,
    setEmailAddress,
    setPhone,
    setFax,
    dictionaries,
    isInfoAboutOrganizationLoadedSnackbarOpen,
    setParentOrganization,
    isEditing,
    headquarters,
    handleHeadquartersOnClick,
    snackbarText,
    openedOrganization,
    updateHeadquarters,
    isLoading,
    selectedOwnershipTypeList,
    handleOwnershipTypeOptionListOnChange,
    selectedOrganizationTypeList,
    handleOrganizationTypeOptionListOnChange,
  };
};
