import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import styles from "./AllFiltersModal.module.scss";
import { Props } from "./AllFiltersModal.types";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  Checkbox,
  CloseButton,
  Select,
  TreeSelect,
} from "../../../../../../components";
import { useAllFiltersModal } from "./AllFiltersModal.hook";
import { convertFlatOptionListTo2LevelOptionList } from "../../../../../../utils";

export const AllFiltersModal = (props: Props) => {
  const {
    ownershipTypeFilterOptionList,
    healthcareStructureFilterOptionList,
    healthcareOrganizationTypeFilterOptionList,
    medicalCareLevelFilterOptionList,
    medicalCareConditionsFilterOptionList,
    medicalCareTypeFilterOptionList,
    subordinationLevelFilterOptionList,
    parentOrganizationFilterOptionList,
    headquartersFilterOptionList,
  } = props;
  const {
    handleOnClose,
    ownershipTypeFilter,
    setOwnershipTypeFilter,
    handleClearAllFilters,
    isClearAllFiltersButtonDisabled,
    handleOnSave,
    healthcareStructureFilter,
    setHealthcareStructureFilter,
    healthcareOrganizationTypeFilter,
    setHealthcareOrganizationTypeFilter,
    medicalCareLevelFilter,
    setMedicalCareLevelFilter,
    medicalCareConditionsFilter,
    setMedicalCareConditionsFilter,
    medicalCareTypeFilter,
    setMedicalCareTypeFilter,
    subordinationLevelFilter,
    setSubordinationLevelFilter,
    parentOrganizationFilter,
    setParentOrganizationFilter,
    isShowWithBranches,
    setIsShowWithBranches,
    headquartersFilter,
    setHeadquartersFilter,
  } = useAllFiltersModal(props);

  return (
    <Dialog
      onClose={handleOnClose}
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "720px",
          },
        },
      }}
    >
      <DialogTitle className={styles.dialog_title}>Фильтры</DialogTitle>
      <CloseButton onClick={handleOnClose} isOnModal={true} />
      <DialogContent className={styles.dialog_content}>
        <div className={styles.select_list_grid}>
          <div className={styles.select_list_grid__show_branches}>
            <Checkbox
              label="Отобразить с филиалами"
              checked={isShowWithBranches}
              onChange={(_) => {
                setIsShowWithBranches((prev) => !prev);
              }}
            />
          </div>
          {isShowWithBranches ? (
            <Select
              withSelectAll={false}
              isMultiple={false}
              label="Головная организация"
              selectedOptionList={headquartersFilter}
              setSelectedOptionList={setHeadquartersFilter}
              optionList={headquartersFilterOptionList}
            />
          ) : (
            <div></div>
          )}
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Вышестоящая организация"
            selectedOptionList={parentOrganizationFilter}
            setSelectedOptionList={setParentOrganizationFilter}
            optionList={parentOrganizationFilterOptionList}
          />
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Структура здравоохранения"
            selectedOptionList={healthcareStructureFilter}
            setSelectedOptionList={setHealthcareStructureFilter}
            optionList={healthcareStructureFilterOptionList}
          />
          <TreeSelect
            withSelectAll={true}
            isMulti={true}
            selectableParents={false}
            label="Форма собственности"
            value={ownershipTypeFilter}
            onChangeHandlerOption={setOwnershipTypeFilter}
            options={convertFlatOptionListTo2LevelOptionList(
              ownershipTypeFilterOptionList,
            )}
          />
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Уровень подчинённости"
            selectedOptionList={subordinationLevelFilter}
            setSelectedOptionList={setSubordinationLevelFilter}
            optionList={subordinationLevelFilterOptionList}
          />
          <TreeSelect
            withSelectAll={true}
            isMulti={true}
            selectableParents={false}
            label="Тип ОЗ"
            value={healthcareOrganizationTypeFilter}
            onChangeHandlerOption={setHealthcareOrganizationTypeFilter}
            options={convertFlatOptionListTo2LevelOptionList(
              healthcareOrganizationTypeFilterOptionList,
            )}
          />
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Уровень оказания медпомощи"
            selectedOptionList={medicalCareLevelFilter}
            setSelectedOptionList={setMedicalCareLevelFilter}
            optionList={medicalCareLevelFilterOptionList}
          />
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Условия оказания медпомощи"
            selectedOptionList={medicalCareConditionsFilter}
            setSelectedOptionList={setMedicalCareConditionsFilter}
            optionList={medicalCareConditionsFilterOptionList}
          />
          <Select
            withSelectAll={true}
            isMultiple={true}
            label="Вид медпомощи"
            selectedOptionList={medicalCareTypeFilter}
            setSelectedOptionList={setMedicalCareTypeFilter}
            optionList={medicalCareTypeFilterOptionList}
          />
        </div>
      </DialogContent>
      <div className={styles.actions}>
        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Contained}
          size={ButtonSize.L}
          onClick={handleClearAllFilters}
          text="Очистить фильтры"
          disabled={isClearAllFiltersButtonDisabled}
        />
        <Button
          type={ButtonType.Primary}
          style={ButtonStyle.Contained}
          size={ButtonSize.L}
          onClick={handleOnSave}
          text="ОК"
          className={styles.actions__ok}
        />
      </div>
    </Dialog>
  );
};
