import { Props } from "./Input.types";
import * as React from "react";
import { Input as BaseInput, InputProps } from "@mui/base/Input";
import { InputElement, InputRoot } from "./Input.styles";
import styles from "./Input.module.scss";
import cn from "classnames";
import { useInput } from "./Input.hook";

export const Input = React.forwardRef(function CustomInput(
  props: InputProps & Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { slots, ...other } = props;
  const { handleOnChange, handleOnKeyDown } = useInput(props);

  return (
    <div className={cn(styles.container, props.className)}>
      <BaseInput
        onKeyDown={handleOnKeyDown}
        slots={{
          root: InputRoot,
          input: InputElement,
          ...slots,
        }}
        {...other}
        value={props.value}
        onChange={handleOnChange}
        ref={ref}
        className={cn({
          [styles.medium_size]: props.size === "m",
          [styles.input_error]: !!props.errorText,
          [styles.disabled]: props.disabled,
        })}
      />
      {props.errorText && (
        <div className={styles.error_block}>{props.errorText}</div>
      )}
    </div>
  );
});
