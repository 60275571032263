import * as React from "react";
import { Props } from "./Header.types";
import styles from "./Header.module.scss";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  Status,
  StatusType,
} from "../../../../components";
import { useHeader } from "./Header.hook";
import { OrganizationPassportBlockMode } from "../OrganizationPassportBlock/OrganizationPassportBlock.types";
import { formatDateTimeToClassicView } from "../../../../utils";
import { UserRole } from "../../../../consts";

export const Header = (props: Props) => {
  const {
    organizationName,
    status,
    lastModified,
    onCloseCard,
    onSuspendCard,
    onActiveCard,
  } = props;
  const {
    openedOrganization,
    handleCreateButtonOnClick,
    handleCancelOnClick,
    user,
  } = useHeader();

  return (
    <div className={styles.container}>
      <div className={styles.title_block}>
        {openedOrganization.organizationPassportBlockMode ===
        OrganizationPassportBlockMode.Creating ? (
          <div className={styles.title_block__title}>
            Создание{" "}
            {openedOrganization.isOrganization ? "организации" : "филиала"}
          </div>
        ) : (
          <>
            <div className={styles.title_block__title}>
              {organizationName ?? ""}
            </div>
            <div className={styles.title_block__last_modified_line}>
              <div
                className={
                  styles.title_block__last_modified_line__last_modified
                }
              >
                {lastModified
                  ? `Последнее изменение ${formatDateTimeToClassicView(lastModified)}`
                  : ""}
              </div>
              {status && <Status statusType={status} />}
            </div>
          </>
        )}
      </div>
      <div className={styles.buttons}>
        {openedOrganization.organizationPassportBlockMode ===
        OrganizationPassportBlockMode.Creating ? (
          <>
            <Button
              type={ButtonType.Primary}
              style={ButtonStyle.Contained}
              size={ButtonSize.M}
              onClick={handleCreateButtonOnClick}
              text="Создать"
            />
            <Button
              type={ButtonType.Secondary}
              style={ButtonStyle.Contained}
              size={ButtonSize.M}
              onClick={handleCancelOnClick}
              text="Отменить"
            />
          </>
        ) : (
          <>
            {user.role === UserRole.Admin && (
              <>
                {status === StatusType.Active && (
                  <>
                    <Button
                      type={ButtonType.Secondary}
                      style={ButtonStyle.Outlined}
                      size={ButtonSize.M}
                      onClick={onSuspendCard}
                      text="Приостановить"
                    />
                    <Button
                      type={ButtonType.Error}
                      style={ButtonStyle.Outlined}
                      size={ButtonSize.M}
                      onClick={onCloseCard}
                      text="Закрыть карточку"
                    />
                  </>
                )}
                {(status === StatusType.Closed ||
                  status === StatusType.Suspended) && (
                  <>
                    <Button
                      type={ButtonType.Primary}
                      style={ButtonStyle.Contained}
                      size={ButtonSize.M}
                      onClick={onActiveCard}
                      text="Активировать карточку"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
