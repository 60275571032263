import { useEffect, useState } from "react";
import { getOptionsForMainOrganizationSelect } from "../../../../../api";
import { useAppSelector } from "../../../../../store/hooks";
import {
  calcOptionsTreeView,
  convetToOption,
  isEqual,
} from "./StructuralUnitsModal.helpers";
import { TreeSelectOption } from "../../../../../components/TreeSelect/TreeSelect.types";
import { useParams } from "react-router-dom";

export const useModalOptions = ({
  hierarcyIdList,
  showAllOrganizations,
}: {
  isCreating: boolean;
  hierarcyIdList?: string[];
  showAllOrganizations: boolean;
}) => {
  const [mainOrganizationsOptions, setMainOrganizationsOptions] = useState<
    TreeSelectOption[]
  >([]);
  const [lastHierarchyIdList, setLastHierarchyIdList] = useState<
    string[] | undefined
  >(hierarcyIdList);

  const [lastOrgId, setLastOrgId] = useState<string | null>(null);

  const dictionaryId = useAppSelector(
    (state) => state.dictionaries.gridIdDictionary,
  );

  const mainOrganization = useAppSelector(
    (state) => state.dictionaries.parentOrganizationList,
  );
  const typeOptions = useAppSelector(
    (state) => state.dictionaries.structuralUnitsType,
  );

  const { organizationId } = useParams();
  const fetchData = async (id?: number) => {
    if (!id) return;
    try {
      const [data, highLevelOrganization] = (
        await Promise.allSettled([
          getOptionsForMainOrganizationSelect(id, true),
          getOptionsForMainOrganizationSelect(id),
        ])
      ).map((res) => {
        return res.status === "fulfilled" ? res.value : undefined;
      });

      if (Array.isArray(data) && data.length > 0) {
        const currentOrganizationParentId = highLevelOrganization?.find(
          (item) => item[1] === organizationId,
        )[5];
        const highLevelOrganizationOptions =
          highLevelOrganization
            ?.filter((item) =>
              showAllOrganizations
                ? true
                : currentOrganizationParentId
                  ? item[1] === organizationId ||
                    item[1] === currentOrganizationParentId ||
                    item[5] === currentOrganizationParentId
                  : item[1] === organizationId || item[5] === organizationId,
            )
            .map((item) => convetToOption(item)) || [];

        const subLevelOrganizationsOptions = calcOptionsTreeView(
          data,
          hierarcyIdList || [],
        );

        if (
          Array.isArray(hierarcyIdList) &&
          hierarcyIdList.length === 1 &&
          hierarcyIdList[0] === organizationId
        ) {
          setMainOrganizationsOptions(highLevelOrganizationOptions);
        } else {
          setMainOrganizationsOptions(
            subLevelOrganizationsOptions.concat(highLevelOrganizationOptions),
          );
        }
      }
    } catch (error) {
      console.error("Fail to fetch data...", error);
    }
  };

  useEffect(() => {
    if (
      dictionaryId &&
      (!isEqual(hierarcyIdList, lastHierarchyIdList) || !lastHierarchyIdList)
    ) {
      fetchData(dictionaryId);
      setLastHierarchyIdList(hierarcyIdList);
    } else if (
      Array.isArray(hierarcyIdList) &&
      hierarcyIdList.length === 1 &&
      hierarcyIdList[0] === organizationId &&
      mainOrganizationsOptions.length === 0 &&
      lastOrgId !== organizationId
    ) {
      fetchData(dictionaryId);
      setLastOrgId(organizationId);
      setLastHierarchyIdList(hierarcyIdList);
    }
  }, [dictionaryId, hierarcyIdList, showAllOrganizations, organizationId]);

  return {
    organizationOptions: mainOrganization,
    mainOrganizationOptions: mainOrganizationsOptions,
    typeOptions: typeOptions,
  };
};
