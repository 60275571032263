import classes from "./Loader.module.scss";
import { Props } from "./Loader.types";

export const Loader = (props: Props) => {
  const { height = 24, width = 24, strokeWidth = 2 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.loader}
    >
      <path
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M12 3C16.9706 3 21 7.02944 21 12"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
