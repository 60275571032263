import React from "react";
import { Props } from "./StatusTooltipContent.types";
import styles from "../TooltipContent.module.scss";
import { StatusType } from "../../../../../../components";
import { formatDateToClassicView } from "../../../../../../utils";

export const StatusTooltipContent = (props: Props) => {
  const { status, statusReason, statusBasis, statusDate } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>Статус: {status.toString()}</div>
      <div className={styles.field_list}>
        <div>
          Причина {status === StatusType.Suspended ? "остановки" : "закрытия"}:
        </div>
        <div>{statusReason}</div>
        <div>Дата:</div>
        <div>{statusDate ? formatDateToClassicView(statusDate) : "—"}</div>
        <div>Основание:</div>
        <div className={styles.field_list__field__is_status_basis}>
          {statusBasis}
        </div>
      </div>
    </div>
  );
};
