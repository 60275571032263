import { useEffect, useState } from "react";
import { getBranchesList } from "../../../../api";
import { MAXIMUM_PAGE_COUNT } from "../../../../consts";
import { Option, StatusType } from "../../../../components";
import { useAppSelector } from "../../../../store/hooks";
import { Mode, Props } from "./ChangeOrganizationStatusModal.types";
import {
  validateDateField,
  validateSelectField,
  validateString,
} from "../../../../utils";
import { StatusReason } from "./ChangeOrganizationStatusModal.consts";
import { updateOrganizationStatus } from "../../../../api/save-dictionaries";
import { ChangeOrganizationStatusModalMode } from "../../OrganizationCard.types";

export const useChangeOrganizationStatusModal = (props: Props) => {
  const { organization, changeStatusMode } = props;
  const [mode, setMode] = useState<Mode>(Mode.CheckingAbilityToClose);
  const [statusReason, setStatusReason] = useState<Option[]>([]);
  const [statusReasonText, setStatusReasonText] = useState<string>("");
  const [statusBasis, setStatusBasis] = useState<string>("");
  const [statusDate, setStatusDate] = useState<Date | undefined>(undefined);
  const [isSaveButtonPressed, setIsSaveButtonPressed] =
    useState<boolean>(false);
  const dictionaries = useAppSelector((state) => state.dictionaries);

  const openedOrganization = useAppSelector(
    (state) => state.openedOrganization,
  );

  const checkAbilityToCloseOrganization = async () => {
    if (!dictionaries.gridIdDictionary) return;

    setMode(Mode.CheckingAbilityToClose);
    if (openedOrganization.isOrganization) {
      try {
        const response = await getBranchesList(
          {
            organizationId: organization!.id,
            page: 0,
            count: MAXIMUM_PAGE_COUNT,
          },
          dictionaries.gridIdDictionary,
        );

        if (
          response.branchList.find(
            (branch) => branch.status === StatusType.Active,
          )
        ) {
          setMode(Mode.CannotCloseOrganization);
        } else {
          setMode(Mode.CanChangeStatus);
        }
      } catch (ex) {
        setMode(Mode.CannotCloseOrganization);
      }
    }
  };

  useEffect(() => {
    if (changeStatusMode === ChangeOrganizationStatusModalMode.ActiveToClosed) {
      checkAbilityToCloseOrganization();
    } else if (
      changeStatusMode ===
        ChangeOrganizationStatusModalMode.ActiveToSuspended ||
      changeStatusMode === ChangeOrganizationStatusModalMode.ToActive
    ) {
      setMode(Mode.CanChangeStatus);
    }
  }, [dictionaries.gridIdDictionary]);

  const handleStatusChangeConfirmModalOnClose = () => {
    setMode(Mode.CanChangeStatus);
  };

  const handleStatusChangeConfirmModalOnYes = async () => {
    try {
      await updateOrganizationStatus(
        dictionaries.gridIdDictionary!,
        organization.id,
        changeStatusMode === ChangeOrganizationStatusModalMode.ActiveToClosed
          ? StatusType.Closed
          : changeStatusMode ===
              ChangeOrganizationStatusModalMode.ActiveToSuspended
            ? StatusType.Suspended
            : StatusType.Active,
        organization.idDictionaryItem,
        statusDate ?? new Date(),
        statusReason[0].label,
        statusBasis,
      );
      window.location.reload();
    } catch (ex) {
      alert("Ошибка обновления статуса");
    }
  };

  const handleOrganizationOnStatusChange = () => {
    setIsSaveButtonPressed(true);

    const isStatusReasonOk = validateSelectField(statusReason[0]);
    const isStatusReasonTextOk = statusReason[0]
      ? statusReason[0].label === StatusReason.Other
        ? validateString(statusReasonText)
        : true
      : true;
    const isDateOk = validateDateField(statusDate, true, true);
    const isStatusBasisOk = validateString(statusBasis);
    const isValidationOk =
      isStatusReasonOk && isStatusReasonTextOk && isDateOk && isStatusBasisOk;

    if (isValidationOk) {
      setMode(Mode.ChangeStatusConfirmModalOpen);
    }
  };

  return {
    mode,
    statusReason,
    setStatusReason,
    statusReasonText,
    setStatusReasonText,
    statusDate,
    setStatusDate,
    statusBasis,
    setStatusBasis,
    isSaveButtonPressed,
    handleOrganizationOnStatusChange,
    handleStatusChangeConfirmModalOnClose,
    handleStatusChangeConfirmModalOnYes,
  };
};
