import React, { useContext } from "react";
import { useApp } from "./App.hook";
import { RouterProvider } from "react-router-dom";
import styles from "./App.module.scss";
import { authConfig, router } from "./App.consts";
import {
  AuthContext,
  AuthProvider,
  IAuthContext,
} from "react-oauth2-code-pkce";

const SecurityContent = () => {
  const { token, loginInProgress, logIn }: IAuthContext =
    useContext(AuthContext);

  return (
    <>
      {token ? (
        <RouterProvider router={router} />
      ) : !loginInProgress ? (
        logIn()
      ) : null}
    </>
  );
};

function App() {
  useApp();

  return (
    <div className={styles.container}>
      <AuthProvider authConfig={authConfig}>
        <SecurityContent />
      </AuthProvider>
    </div>
  );
}

export default App;
