import React from "react";
import styles from "./PageWrapper.module.scss";
import { Props } from "./PageWrapper.types";
import { Header } from "../../features";

export const PageWrapper = (props: Props) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.page}>{children}</div>
    </div>
  );
};
