import { Option, TreeSelectProps } from "./TreeSelect.types";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, IconButton } from "@mui/material";
import { Select as MUISelect } from "@mui/material";
import styles from "./TreeSelect.module.scss";
import { useTreeSelect } from "./useTreeSelect";
import { SearchInput } from "../SearchInput";
import { checkBoxSX } from "./TreeSelect.consts";
import selectStyles from "../Select/Select.module.scss";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "../Button";
import { Checkbox } from "../Checkbox";
import { useCallback } from "react";

export const TreeSelect = ({ options, ...props }: TreeSelectProps) => {
  const {
    label,
    isError,
    className,
    withSelectAll = true,
    withCounter = true,
    isMulti = false,
    selectableParents = false,
    initValue,
    onChangeHandlerString,
    onChangeHandlerOption,
    onChange,
    value,
  } = props;

  const memoizedOnChangeHandlerOption = useCallback(
    (val: any) => {
      onChangeHandlerOption?.(val);
    },
    [onChangeHandlerOption],
  );
  const memoizedOnChangeHandlerString = useCallback(
    (val: any) => {
      onChangeHandlerString?.(val);
    },
    [onChangeHandlerString],
  );

  const {
    toggleOption,
    expandedOptions,
    selectedOptionList,
    searchTerm,
    toggleSelectAll,
    setSearchTerm,
    filterOptions,
    handleSelect,
    maxCount,
    valueForRender,
    isMenuOpen,
    handleMenuClose,
    handleMenuOpen,
  } = useTreeSelect({
    optionList: options,
    isMulti,
    selectableParents,
    initValue,
    value,
    onChangeHandlerOption: memoizedOnChangeHandlerOption,
    onChangeHandlerString: memoizedOnChangeHandlerString,
    onChangeHandler: onChange,
  });

  const renderOptions = (
    options: Option[],
    isChild: boolean = false,
  ): JSX.Element[] => {
    return options.flatMap((option) => {
      const isExpanded = expandedOptions.includes(option.id);
      const hasChildren = option.children && option.children.length > 0;
      const renderedChildren: JSX.Element[] | null =
        isExpanded && hasChildren
          ? renderOptions(option.children!, true)
          : null;

      return [
        <MenuItem
          onClick={() => {
            handleSelect(option);
          }}
          key={option.id}
          className={`${selectStyles.menu_item} ${
            isChild ? styles.child_item : ""
          }`}
        >
          {hasChildren && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                toggleOption(option.id);
              }}
            >
              {isExpanded ? (
                <ExpandMoreIcon />
              ) : (
                <ExpandMoreIcon className={styles.lessIcon} />
              )}
            </IconButton>
          )}

          <Checkbox
            sx={checkBoxSX}
            checked={
              hasChildren
                ? selectableParents
                  ? option.children!.every((child) =>
                      selectedOptionList.includes(child.id),
                    ) || selectedOptionList.includes(option.id)
                  : option.children!.every((child) =>
                      selectedOptionList.includes(child.id),
                    )
                : selectedOptionList.includes(option.id)
            }
            indeterminate={
              hasChildren &&
              option.children!.some((child) =>
                selectedOptionList.includes(child.id),
              ) &&
              !option.children!.every((child) =>
                selectedOptionList.includes(child.id),
              )
            }
          />
          <div className={selectStyles.menu_item__text}>{option.label}</div>
        </MenuItem>,
        ...(renderedChildren || []),
      ];
    });
  };

  const itemsForRenderCount = renderOptions(
    filterOptions(options, searchTerm),
  ).length;

  return (
    <FormControl size="small" className={className}>
      <InputLabel className={selectStyles.label}>{label}</InputLabel>
      <MUISelect
        open={isMenuOpen}
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
        style={{ height: 32, minWidth: 120 }}
        error={isError}
        multiple
        value={valueForRender}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 32 * 16 + 8,
              width: 400,
            },
          },
        }}
      >
        <SearchInput
          className={selectStyles.search_input}
          placeholder="Поиск"
          value={searchTerm}
          setValue={setSearchTerm}
        />
        {withSelectAll && isMulti && (
          <MenuItem
            className={selectStyles.menu_item}
            onClick={toggleSelectAll}
          >
            <Checkbox
              sx={checkBoxSX}
              checked={selectedOptionList.length === maxCount}
            />
            <div className={selectStyles.menu_item__text}>Выбрать все</div>
          </MenuItem>
        )}

        {itemsForRenderCount > 0 ? (
          renderOptions(filterOptions(options, searchTerm))
        ) : (
          <div className={selectStyles.no_results}>Нет доступных вариантов</div>
        )}
        <Divider />
        <div className={styles.footer}>
          {withCounter ? (
            <div
              className={styles.counter}
            >{`Всего: ${selectedOptionList.length}`}</div>
          ) : (
            <div></div>
          )}

          <Button
            text="OK"
            style={ButtonStyle.Contained}
            type={ButtonType.Primary}
            size={ButtonSize.M}
            className={styles.button}
            onClick={handleMenuClose}
          />
        </div>
      </MUISelect>
    </FormControl>
  );
};
