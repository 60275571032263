import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import modalStyles from "../../../../styles/Modal.module.scss";
import styles from "./ChangeOrganizationStatusModal.module.scss";
import { Mode, Props } from "./ChangeOrganizationStatusModal.types";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseButton,
  DatePicker,
  Input,
  Loader,
  Select,
} from "../../../../components";
import cn from "classnames";
import {
  StatusReason,
  reasonToCloseOptionList,
  reasonToSuspendOptionList,
  reasonToActiveOptionList,
} from "./ChangeOrganizationStatusModal.consts";
import {
  getSelectFieldErrorText,
  getStringFieldErrorText,
  validateDateField,
} from "../../../../utils";
import { useChangeOrganizationStatusModal } from "./ChangeOrganizationStatusModal.hook";
import { ChangeOrganizationStatusModalMode } from "../../OrganizationCard.types";

export const ChangeOrganizationStatusModal = (props: Props) => {
  const { onClose, changeStatusMode } = props;
  const {
    mode,
    statusReason,
    setStatusReason,
    statusReasonText,
    setStatusReasonText,
    statusDate,
    setStatusDate,
    statusBasis,
    setStatusBasis,
    isSaveButtonPressed,
    handleOrganizationOnStatusChange,
    handleStatusChangeConfirmModalOnClose,
    handleStatusChangeConfirmModalOnYes,
  } = useChangeOrganizationStatusModal(props);

  if (mode === Mode.ChangeStatusConfirmModalOpen) {
    return (
      <Dialog
        onClose={onClose}
        open={true}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "460px",
            },
          },
        }}
      >
        <DialogTitle
          className={cn(modalStyles.dialog_title, styles.dialog_title)}
        >
          {changeStatusMode ===
            ChangeOrganizationStatusModalMode.ActiveToClosed &&
            "Закрытие карточки"}
          {changeStatusMode ===
            ChangeOrganizationStatusModalMode.ActiveToSuspended &&
            "Приостановить"}
          {changeStatusMode === ChangeOrganizationStatusModalMode.ToActive &&
            "Активировать карточку"}
        </DialogTitle>
        <CloseButton
          onClick={handleStatusChangeConfirmModalOnClose}
          isOnModal={true}
        />
        <DialogContent className={styles.dialog_content}>
          <div className={styles.explain_block}>
            {changeStatusMode ===
              ChangeOrganizationStatusModalMode.ActiveToClosed &&
              "Вы действительно хотите закрыть карточку?"}
            {changeStatusMode ===
              ChangeOrganizationStatusModalMode.ActiveToSuspended &&
              "Вы действительно хотите сменить статус на Приостановлен?"}
            {changeStatusMode === ChangeOrganizationStatusModalMode.ToActive &&
              "Вы действительно хотите сменить статус на Активный?"}
          </div>
        </DialogContent>
        <div className={styles.actions}>
          <Button
            type={ButtonType.Secondary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={handleStatusChangeConfirmModalOnClose}
            text="Нет"
          />
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={handleStatusChangeConfirmModalOnYes}
            text="Да"
          />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={onClose}
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "578px",
          },
        },
      }}
    >
      <DialogTitle
        className={cn(modalStyles.dialog_title, styles.dialog_title)}
      >
        {changeStatusMode ===
          ChangeOrganizationStatusModalMode.ActiveToClosed &&
          "Закрыть карточку"}
        {changeStatusMode ===
          ChangeOrganizationStatusModalMode.ActiveToSuspended &&
          "Приостановить"}
        {changeStatusMode === ChangeOrganizationStatusModalMode.ToActive &&
          "Активировать карточку"}
      </DialogTitle>
      <CloseButton onClick={onClose} isOnModal={true} />
      {mode === Mode.CheckingAbilityToClose ? (
        <DialogContent
          className={cn(styles.dialog_content, styles.dialog_content__loader)}
        >
          <Loader height={28} width={28} />
        </DialogContent>
      ) : (
        <>
          {mode === Mode.CannotCloseOrganization && (
            <>
              <DialogContent className={styles.dialog_content}>
                <div className={styles.error_block}>
                  Невозможно закрыть организацию. Обнаружены действующие
                  филиалы.
                </div>
                <div className={styles.explain_block}>
                  Необходимо изменить статус или головную организацию каждого
                  действующего филиала.
                </div>
              </DialogContent>
              <div className={styles.actions}>
                <Button
                  type={ButtonType.Primary}
                  style={ButtonStyle.Contained}
                  size={ButtonSize.L}
                  onClick={onClose}
                  text="Ок"
                />
              </div>
            </>
          )}
          {mode === Mode.CanChangeStatus && (
            <>
              <DialogContent className={styles.dialog_content}>
                <div className={styles.field}>
                  <div className={styles.field__text}>Причина</div>
                  <Select
                    className={styles.field__control}
                    withSelectAll={false}
                    isMultiple={false}
                    label="Выберите причину"
                    selectedOptionList={statusReason}
                    setSelectedOptionList={setStatusReason}
                    optionList={
                      changeStatusMode ===
                      ChangeOrganizationStatusModalMode.ActiveToClosed
                        ? reasonToCloseOptionList
                        : changeStatusMode ===
                            ChangeOrganizationStatusModalMode.ActiveToSuspended
                          ? reasonToSuspendOptionList
                          : reasonToActiveOptionList
                    }
                    isError={
                      !!getSelectFieldErrorText(
                        statusReason[0],
                        isSaveButtonPressed,
                      )
                    }
                  />
                </div>

                {statusReason[0]?.label === StatusReason.Other && (
                  <div className={styles.field}>
                    <div className={styles.field__text}></div>
                    <Input
                      className={styles.field__control}
                      value={statusReasonText}
                      setValue={setStatusReasonText}
                      size="s"
                      placeholder="Введите причину"
                      errorText={getStringFieldErrorText(
                        statusReasonText,
                        isSaveButtonPressed,
                      )}
                    />
                  </div>
                )}

                <div className={styles.field}>
                  <div className={styles.field__text}>Дата</div>
                  <DatePicker
                    extraClass={styles.field__control}
                    value={statusDate}
                    setValue={setStatusDate}
                    isError={
                      !validateDateField(statusDate, isSaveButtonPressed, true)
                    }
                  />
                </div>

                <div className={cn(styles.field__basis)}>
                  <div
                    className={cn(
                      styles.field__text,
                      styles.field__text__basis,
                    )}
                  >
                    Основание
                  </div>
                  <Input
                    className={styles.field__control}
                    value={statusBasis}
                    setValue={setStatusBasis}
                    size="s"
                    placeholder="Введите основание"
                    errorText={getStringFieldErrorText(
                      statusBasis,
                      isSaveButtonPressed,
                    )}
                  />
                </div>
              </DialogContent>
              <div className={styles.actions}>
                <Button
                  type={ButtonType.Secondary}
                  style={ButtonStyle.Contained}
                  size={ButtonSize.L}
                  onClick={onClose}
                  text="Отменить"
                />
                <Button
                  type={
                    changeStatusMode ===
                    ChangeOrganizationStatusModalMode.ActiveToClosed
                      ? ButtonType.Error
                      : ButtonType.Primary
                  }
                  style={ButtonStyle.Contained}
                  size={ButtonSize.L}
                  onClick={handleOrganizationOnStatusChange}
                  text={
                    changeStatusMode ===
                    ChangeOrganizationStatusModalMode.ActiveToClosed
                      ? "Закрыть карточку"
                      : changeStatusMode ===
                          ChangeOrganizationStatusModalMode.ActiveToSuspended
                        ? "Приостановить"
                        : "Активировать"
                  }
                />
              </div>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};
