import { Option, StatusType } from "../../components";
import { OrganizationPassportBlockMode } from "./Features/OrganizationPassportBlock/OrganizationPassportBlock.types";

export type OrganizationPassport = {
  idDictionaryItem: string;
  // Полное наименование
  fullName: string;
  // Сокращённое наименование
  shortName: string;
  // Форма собственности
  ownershipType: Option | undefined;
  // УНП
  UNP: string;
  // Идентификатор организации
  organizationId: string;
  // Структура здравоохранения
  healthcareStructure: Option | undefined;
  // Тип ОЗ
  organizationType: Option | undefined;
  // ОКПО
  OKPO: string;
  // Вышестоящая организация
  parentOrganization: Option | undefined;
  // Подчиненность
  subordination: Option | undefined;
  // Уровень оказания медицинской помощи
  medicalCareLevel: Option | undefined;
  // Дата регистрации
  registrationDate: Date | undefined;
  // Дата регистрации в регистре
  registerRegistrationDate: Date | undefined;
  // Дата последней актуализации
  lastUpdateDate: Date | undefined;
  // Условия оказания медицинской помощи
  medicalCareConditions: Option | undefined;
  // Виды медицинской помощи
  medicalCareTypes: Option | undefined;
  // Почтовый индекс
  postcode: string;
  // Код СОАТО
  COATOCode: string;
  // Область, район, сельский совет, город/иной населенный пункт
  addressP1: string;
  // Улица, номер дома, корпус (дополнительный индекс)
  addressP2: string;
  // Адрес в интернете
  internetAddress: string;
  // Адрес электронной почты
  emailAddress: string;
  // Телефон
  phone: string;
  // Факс
  fax: string | undefined;
  status: StatusType;
  // Основание (для статуса)
  statusBasis: string;
  // Дата изменения статуса
  statusDate: Date | undefined;
  // Причина изменения статуса
  statusReason: string;
  isOrganization: boolean;
  parentId: string;
};

export type Branch = {
  id: string;
  // Наименование
  name: string;
  // Статус
  status: StatusType;
  // Тип
  organizationType: string | undefined;
  // Актуализация
  actualization: Date | undefined;
  phone: string;
  address: string;
  statusReason: string;
  statusDate: Date | undefined;
  statusBasis: string;
};

export type ChangesHistoryItemList = {
  // Дата и время
  dateTime: string;
  // Пользователь
  user: string;
};

export type ChangesHistory = ChangesHistoryItemList[];

export type Props = {
  initialMode: OrganizationPassportBlockMode;
  changesHistory: ChangesHistory;
};

export enum ChangeOrganizationStatusModalMode {
  ActiveToClosed,
  ActiveToSuspended,
  ToActive,
}
