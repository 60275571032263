export type Props = {
  handleOnClose: () => void;
};

export enum Organization {
  LegalEntity = "Юридическое лицо",
  Branch = "Филиал",
}

export enum Mode {
  Input,
  DataChecking,
  UNPNotFound,
  FetchError,
}
