import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option } from "../../components";
import { OptionWithParent } from "../../components/Select/Select.types";

export type StateType = {
  gridIdDictionary: number | undefined;
  parentOrganizationList: Option[];
  healthcareStructureOptionList: Option[];
  ownershipTypeOptionList: OptionWithParent[];
  organizationTypeOptionList: OptionWithParent[];
  medicalCareLevelOptionList: Option[];
  medicalCareTypeOptionList: Option[];
  medicalCareConditionsOptionList: Option[];
  subordinationLevelOptionList: Option[];
  allOrganizationsOptionList: Option[];
  structuralUnitsType: Option[];
};

const initialState: StateType = {
  gridIdDictionary: undefined,
  parentOrganizationList: [],
  healthcareStructureOptionList: [],
  ownershipTypeOptionList: [],
  organizationTypeOptionList: [],
  medicalCareLevelOptionList: [],
  medicalCareTypeOptionList: [],
  medicalCareConditionsOptionList: [],
  subordinationLevelOptionList: [],
  allOrganizationsOptionList: [],
  structuralUnitsType: [],
};

export const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState: initialState,
  reducers: {
    setParentOrganizationList: (state, action: PayloadAction<Option[]>) => {
      state.parentOrganizationList = action.payload;
    },
    setHealthcareStructureOptionList: (
      state,
      action: PayloadAction<Option[]>,
    ) => {
      state.healthcareStructureOptionList = action.payload;
    },
    setOwnershipTypeOptionList: (
      state,
      action: PayloadAction<OptionWithParent[]>,
    ) => {
      state.ownershipTypeOptionList = action.payload;
    },
    setOrganizationTypeOptionList: (
      state,
      action: PayloadAction<OptionWithParent[]>,
    ) => {
      state.organizationTypeOptionList = action.payload;
    },
    setMedicalCareLevelOptionList: (state, action: PayloadAction<Option[]>) => {
      state.medicalCareLevelOptionList = action.payload;
    },
    setMedicalCareTypeOptionList: (state, action: PayloadAction<Option[]>) => {
      state.medicalCareTypeOptionList = action.payload;
    },
    setMedicalCareConditionsOptionList: (
      state,
      action: PayloadAction<Option[]>,
    ) => {
      state.medicalCareConditionsOptionList = action.payload;
    },
    setSubordinationLevelOptionList: (
      state,
      action: PayloadAction<Option[]>,
    ) => {
      state.subordinationLevelOptionList = action.payload;
    },
    setAllOrganizationsOptionList: (state, action: PayloadAction<Option[]>) => {
      state.allOrganizationsOptionList = action.payload;
    },
    setStructuralUnitsTypesOptionList: (
      state,
      action: PayloadAction<Option[]>,
    ) => {
      state.structuralUnitsType = action.payload;
    },
    setGridIdDictionary: (state, action: PayloadAction<number>) => {
      state.gridIdDictionary = action.payload;
    },
  },
});

export const {
  setParentOrganizationList,
  setHealthcareStructureOptionList,
  setOwnershipTypeOptionList,
  setOrganizationTypeOptionList,
  setMedicalCareLevelOptionList,
  setMedicalCareTypeOptionList,
  setMedicalCareConditionsOptionList,
  setSubordinationLevelOptionList,
  setAllOrganizationsOptionList,
  setStructuralUnitsTypesOptionList,
  setGridIdDictionary,
} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
