export enum Section {
  OrganizationPassport = "Паспорт организации",
  StructuralUnits = "Структурные подразделения",
  Branches = "Филиалы",
  ChangesHistory = "История изменений",
}

export const sidebarSectionList: Section[] = [
  Section.OrganizationPassport,
  Section.StructuralUnits,
  Section.Branches,
  Section.ChangesHistory,
];
