import styles from "./CreateOrganizationModal.module.scss";
import modalStyles from "../../../../styles/Modal.module.scss";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseButton,
  Input,
  Loader,
  Radio,
} from "../../../../components";
import DialogContent from "@mui/material/DialogContent";
import { Mode, Organization, Props } from "./CreateOrganizationModal.types";
import { FormControl, RadioGroup } from "@mui/material";
import { useCreateOrganizationModal } from "./CreateOrganizationModal.hook";
import { getUNPFieldErrorText } from "../../../../utils";

export const CreateOrganizationModal = (props: Props) => {
  const { handleOnClose } = props;
  const {
    selectedOrganization,
    handleChange,
    UNP,
    setUNP,
    isNextButtonPressed,
    handleNextButtonOnClick,
    mode,
  } = useCreateOrganizationModal();

  return (
    <Dialog
      onClose={handleOnClose}
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "470px",
          },
        },
      }}
    >
      <DialogTitle className={modalStyles.dialog_title}>
        Создать организацию
      </DialogTitle>
      <CloseButton onClick={handleOnClose} isOnModal={true} />
      <DialogContent className={styles.dialog_content}>
        {(mode === Mode.Input || mode === Mode.UNPNotFound) && (
          <>
            {mode === Mode.UNPNotFound && (
              <div className={styles.unp_not_found}>
                Организация с таким УНП не найдена. Введите другой номер
              </div>
            )}

            <div className={styles.radio_group_and_input}>
              <FormControl>
                <RadioGroup
                  row
                  value={selectedOrganization}
                  onChange={handleChange}
                  className={styles.radio_group}
                >
                  <Radio
                    value={Organization.LegalEntity}
                    label={Organization.LegalEntity}
                  />
                  <Radio
                    value={Organization.Branch}
                    label={Organization.Branch}
                  />
                </RadioGroup>
              </FormControl>

              {selectedOrganization === Organization.LegalEntity && (
                <div className={styles.input_block}>
                  <div className={styles.input_block__text}>Введите УНП</div>
                  <Input
                    value={UNP}
                    setValue={setUNP}
                    placeholder="000000000"
                    size="m"
                    errorText={getUNPFieldErrorText(UNP, isNextButtonPressed)}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {mode === Mode.DataChecking && (
          <div className={styles.data_checking}>
            <Loader />
            <div>Подождите, идет проверка данных...</div>
          </div>
        )}
      </DialogContent>
      <div className={modalStyles.actions}>
        <Button
          type={ButtonType.Secondary}
          style={ButtonStyle.Contained}
          size={ButtonSize.L}
          onClick={handleOnClose}
          text="Отменить"
        />

        {mode !== Mode.DataChecking && (
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Contained}
            size={ButtonSize.L}
            onClick={handleNextButtonOnClick}
            text="Далее"
          />
        )}
      </div>
    </Dialog>
  );
};
