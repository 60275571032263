export enum SnackbarState {
  Success,
  Error,
}

export type Props = {
  text: string;
  state: SnackbarState;
  zIndex?: number;
  extraClass?: string;
};
